import { backendApi } from 'modules/Management/redux';
import managementConstant from 'lib/constants/managementConstants';
import { showLoading } from 'react-redux-loading-bar';
import { appNotification } from 'lib/components';
import { urlConstants } from '../../lib/constants/index';

const commonConstants = {
  GLOBAL_LOADING_START: 'GLOBAL_LOADING_START',
  GLOBAL_LOADING_ENDED: 'GLOBAL_LOADING_ENDED',
  FETCH_START: 'FETCH_START',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_ERROR: 'FETCH_ERROR',
  SUCCESSFUL_CODE: '00',
};

const {
  EMPLOYEES_FETCH_SUCCESS,

  SET_OUT_OF_OFFICE_FAILED,
  SET_OUT_OF_OFFICE_START,
  SET_OUT_OF_OFFICE_SUCCESS,
} = managementConstant;

export const fetchEmployees = (searchTerm) => {
  const reduxState = JSON.parse(localStorage.getItem('greenpole_redux_state'));
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${urlConstants.BASEURL}user/find-all?firstName=${searchTerm}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${reduxState?.auth?.token}`,
            'Content-Type': 'application/json',
          },
        },
      );

      const result = await response.json();
      dispatch({
        type: EMPLOYEES_FETCH_SUCCESS,
        payload: result.data,
      });
    } catch (e) {
      throw e;
    }
  };
};

const setOutOfOffice = (data, callback) => {
  return (dispatch) => {
    dispatch(showLoading());

    dispatch({ type: SET_OUT_OF_OFFICE_START });
    const url = '/management/out_of_office';
    const params = JSON.stringify(data, null, 2);

    backendApi
      .post(url, params)
      .then(({ data }) => {
        if (data.status === commonConstants.SUCCESSFUL_CODE) {
          const response = data.data;

          dispatch({
            type: SET_OUT_OF_OFFICE_SUCCESS,
            payload: response,
          });
          return callback(true);
        }
      })
      .catch((error) => {
        appNotification(error.message, 'Something went wrong!', 'error');
        dispatch({ type: SET_OUT_OF_OFFICE_FAILED });
        return callback(false);
      });
  };
};

const managementActions = {
  fetchEmployees,
  setOutOfOffice,
};

export default managementActions;
