import { appNotification } from '../lib/components';

export const handleError = (e, message) => {
  const { response } = e;
  appNotification(
    message ||
      response?.data?.message ||
      response?.data?.error ||
      response?.data?.statusMessage ||
      'Oops, we could not fetch this data',
    'Error',
    'error',
  );
};
