import React from 'react';
import { Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

const GenericButton = ({
  text,
  onClick,
  iconAfterText,
  buttonType,
  btnStyle,
  iconBeforeText,
  disabled,
}) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled || false}
      style={{
        border: '1px solid #299F9E',
        borderRadius: '5px',
        height: '40px',
        color: '#309191',
        minWidth: '188px',
        textAlign: 'center',
        opacity: 1,
        ...btnStyle,
      }}
      htmlType={buttonType || 'submit'}>
      {iconBeforeText}
      <span>{text}</span>
      {iconAfterText === null ? <ArrowRightOutlined /> : iconAfterText}
    </Button>
  );
};

export default GenericButton;
