import React from 'react';
import ErrorFallbackAvatar from 'lib/assets/images/error_avatar.svg';
import './style.scss';

export const ErrorFallBack = ({
  error,
  componentStack,
  resetErrorBoundary,
}) => {
  const goBack = () => {
    window.location.reload();
  };

  return (
    <main className="error-fallback-view">
      <div className="content">
        <img src={ErrorFallbackAvatar} alt="Error fallback image" />
        <h1>OOPS!!!</h1>
        <p>Seems you encountered an error!</p>
        <button onClick={goBack}>Refresh</button>
      </div>
    </main>
  );
};
