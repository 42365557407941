import {
  STORE_QUALIFIED_HOLDER,
  STORE_SHARES_INFORMATION,
} from '../actions/offerMunaulApplicationActions';

const initialState = {
  holder: null,
  shares: null,
};

const qualifiedHolderInfo = (state = initialState, action) => {
  switch (action.type) {
    case STORE_QUALIFIED_HOLDER:
      return {
        ...state,
        holder: action.holder,
      };
    case STORE_SHARES_INFORMATION:
      return {
        ...state,
        shares: action.shares,
      };
    default:
      return state;
  }
};

export default qualifiedHolderInfo;
