const managementConstants = {
  // GET employees
  EMPLOYEES_FETCH_START: 'EMPLOYEES_FETCH_START',
  EMPLOYEES_FETCH_SUCCESS: 'EMPLOYEES_FETCH_SUCCESS',
  EMPLOYEES_FETCH_FAILED: 'EMPLOYEES_FETCH_FAILED',

  // POST OUT OF OFFICE
  SET_OUT_OF_OFFICE_START: 'SET_OUT_OF_OFFICE_START',
  SET_OUT_OF_OFFICE_SUCCESS: 'SET_OUT_OF_OFFICE_SUCCESS',
  SET_OUT_OF_OFFICE_FAILED: 'SET_OUT_OF_OFFICE_FAILED',
};

export default managementConstants;
