import React from 'react';

import { useSelector } from 'react-redux';

import { Button, Modal, Form, Input, Switch, DatePicker } from 'antd';
import { Calendar } from 'react-modern-calendar-datepicker';

const { TextArea } = Input;

const ReminderModal = ({
  isModalVisible,
  handleOk,
  handleCancel,
  selectedDay,
  setSelectedDay,
  onFinishFailed,
  onFinish,
  form,
}) => {
  const format = 'HH:mm';

  const setReminderLoader = useSelector(
    (state) => state?.dashboardState?.reminder?.setReminderLoader,
  );

  const onChange = () => {
    return null;
  };

  return (
    <Modal
      title="Set Reminder"
      className="reminder-form-modal"
      visible={isModalVisible}
      width={380}
      footer={null}
      onOk={handleOk}
      onCancel={handleCancel}>
      {/* <div>
        <Calendar
          colorPrimary="#4DAFAE"
          calendarClassName="reminder-calender"
          value={selectedDay}
          onChange={setSelectedDay}
        />
      </div> */}
      <div className="form-container">
        <Form
          layout="vertical"
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}>
          <Form.Item
            label="Reminder Name"
            name="title"
            rules={[
              {
                required: true,
                message: 'Please input reminder name',
              },
            ]}>
            <Input />
          </Form.Item>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Form.Item
              label="Time"
              name="reminderDate"
              rules={[
                {
                  required: true,
                  message: 'Please input your reminder date',
                },
              ]}>
              <DatePicker showTime onChange={onChange} size="large" />
            </Form.Item>

            <Form.Item label="Repeat" name="repeat">
              <Switch />
            </Form.Item>
          </div>

          <Form.Item
            label="Reminder Note"
            name="description"
            rules={[
              {
                required: true,
                message: 'Please input your reminder description!',
              },
            ]}>
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button className="cancel-button" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                className="save-button"
                loading={setReminderLoader}
                htmlType="submit">
                Save
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default ReminderModal;
