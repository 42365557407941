import offerConstants from 'lib/constants/offerConstants';

const {
  SET_OPEN_SUBMENU_KEYS,
  SET_OFFER_TYPE_LOADER,
  GET_OFFER_TYPES,
  SET_OFFER_TYPE_DETAILS,
} = offerConstants;

const initialState = {
  openSubmenuKeys: [],
  isOfferTypeLoader: false,
  offerTypes: [],
  currentlySelectedOffer: {},
};

function offerReducer(state = initialState, action) {
  switch (action.type) {
    case SET_OPEN_SUBMENU_KEYS:
      return {
        ...state,
        openSubmenuKeys: action.payload,
      };
    case SET_OFFER_TYPE_LOADER:
      return {
        ...state,
        isOfferTypeLoader: action.payload,
      };
    case GET_OFFER_TYPES:
      return {
        ...state,
        offerTypes: action.payload,
      };
    case SET_OFFER_TYPE_DETAILS:
      return {
        ...state,
        currentlySelectedOffer: action.payload,
      };
    default:
      return state;
  }
}

export default offerReducer;
