import { userConstants } from '../../lib/constants/userConstants';

const changePasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case userConstants.CHANGE_PASSWORD_FAILURE:
      return {
        status: action.status,
        message: action.message,
      };

    case userConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        status: action.status,
        message: action.message,
      };

    default:
      return state;
  }
};

export default changePasswordReducer;
