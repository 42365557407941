import { userConstants } from '../../lib/constants/userConstants';

const initialState = {
  profile: {},
};

const updateProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.UPDATE_PROFILE_SUCCESS:
      return {
        status: action.status,
        message: action.message,
      };
    case userConstants.UPDATE_PROFILE_FAILURE:
      return {
        status: action.status,
        message: action.message,
      };
    case 'UPDATE_USER_PROFILE':
      return {
        profile: { ...state.profile, ...action.profile },
        ...state,
      };
    default:
      return state;
  }
};

export default updateProfileReducer;
