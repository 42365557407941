const initialState = {
  approvedTransaction: [],
  currentAnalyzedTransaction: {},
  processedTransaction: [],
  queriedTransactions: [],
  suspendedTransactions: [],
  cancelledTransactions: [],
  holderAccountTransactions: [],
  searchCriteria: [],
  transactionReconciliation: {},
};

const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'APPROVED_TRANSACTIONS':
      return {
        ...state,
        approvedTransaction: action?.data,
      };
    case 'ANALYZED_TRANSACTION_DETAILS':
      return {
        ...state,
        currentAnalyzedTransaction: action?.data,
      };
    case 'PROCESSED_TRANSACTIONS':
      return {
        ...state,
        processedTransaction: action?.data,
      };
    case 'QUERY_TRANSACTIONS':
      return {
        ...state,
        queriedTransactions: action?.data,
      };
    case 'SUSPENDED_TRANSACTIONS':
      return {
        ...state,
        suspendedTransactions: action?.data,
      };
    case 'CANCELLED_TRANSACTIONS':
      return {
        ...state,
        cancelledTransactions: action?.data,
      };
    case 'HOLDER_ACCOUNT_TRANSACTIONS':
      return {
        ...state,
        holderAccountTransactions: action?.data,
      };
    case 'SEARCH_CRITERIA':
      return {
        ...state,
        searchCriteria: action?.data,
      };
    case 'RECONCILIATION_ERROR':
      return {
        ...state,
        transactionReconciliation: action?.data,
      };
    default:
      return state;
  }
};

export default transactionReducer;
