import 'antd/dist/antd.css';
import React from 'react';
import ReactDOM from 'react-dom';
import AppWrapper from './App';
import './index.css';
import './lib/assets/styles/styles.scss';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  // <React.StrictMode>

  <AppWrapper />,
  // {/* </React.StrictMode>, */}
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
