import React from 'react';
import PropTypes from 'prop-types';
import t from 'prop-types';
import './style.scss';

export const Button = ({
  children,
  htmlType,
  btnType,
  otherClass,
  onClick,
  disabled,
  ...rest
}) => {
  return (
    <button
      type={htmlType}
      onClick={onClick}
      disabled={disabled}
      className={`greenpole-button ${
        btnType === 'secondary' ? 'btn-secondary' : 'btn-primary'
      } ${otherClass}`}
      {...rest}>
      {children}
    </button>
  );
};

export const GPButton = ({
  iconRight,
  iconLeft,
  text,
  onClick,
  btnType,
  float = 'none',
  ...rest
}) => {
  return (
    <GreenPoleButton
      btnType={btnType}
      onClick={onClick}
      {...rest}
      style={{ height: 50, float }}>
      {iconLeft && iconLeft}
      <span
        style={{
          float: 'left',
          marginRight: iconRight ? '1rem' : '0',
          lineHeight: '50px',
        }}>
        {text}
      </span>
      {iconRight && iconRight}
    </GreenPoleButton>
  );
};

export const GreenPoleButton = ({ children, btnType, ...rest }) => {
  return (
    <button
      type="button"
      className={`greenpole-button ${
        btnType === 'secondary' ? 'btn-secondary' : 'btn-primary'
      }`}
      {...rest}>
      {children}
    </button>
  );
};

Button.defaultProps = {
  btnType: 'primary',
  htmlType: 'button',
};

Button.propTypes = {
  children: PropTypes.any.isRequired,
  btnType: t.oneOf(['primary', 'secondary']).isRequired,
};
