const initialState = {
  loadingState: 'loaded',
  module: 'client',
  currentType: 'received',
  pendingRequests: [],
  acceptedRequests: [],
  rejectedRequests: [],
  currentNotification: {
    loading: false,
  },
  currentRequest: {
    show: false,
    requestId: null,
    status: null,
  },
  unread: [],
  itemsForApproval: [],
  showRejectModal: false,
  requestFailed: false,
};

const notificationReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case 'SET_CURRENT_MODULE':
      return {
        ...state,
        module: action?.module,
      };
    case 'PENDING_NOTIFICATIONS':
      return {
        ...state,
        module: action?.module,
        currentType: action?.currentType,
        pendingRequests: action?.payload,
      };
    case 'ACCEPTED_NOTIFICATIONS':
      return {
        ...state,
        module: action?.module,
        currentType: action?.currentType,
        acceptedRequests: action?.payload,
      };
    case 'REJECTED_NOTIFICATIONS':
      return {
        ...state,
        module: action?.module,
        currentType: action?.currentType,
        rejectedRequests: action?.payload,
      };
    case 'SINGLE_NOTIFICATION':
      return {
        ...state,
        currentNotification: {
          ...action?.payload,
          loading: false,
        },
      };
    case 'SET_REQUEST_FAILED_STATUS':
      return {
        ...state,
        requestFailed: action?.status,
      };
    case 'LOAD_REQUEST':
      return {
        ...state,
        currentNotification: {
          loading: true,
        },
      };
    case 'SWITCH_LOADING':
      return {
        ...state,
        currentNotification: {
          loading: !state.currentNotification.loading,
        },
      };
    case 'TOGGLE_LOADING':
      return {
        ...state,
        loadingState: action?.loading,
      };
    case 'TOGGLE_REQUEST_MODAL':
      return {
        ...state,
        currentRequest: {
          show: action?.show,
          requestId: action?.requestId,
          status: action?.status,
        },
      };
    case 'SET_APPROVAL_ITEMS':
      return {
        ...state,
        itemsForApproval: action.items,
      };
    case 'TOGGLE_REJECT_MODAL':
      return {
        ...state,
        showRejectModal: action.type,
      };

    case 'RESET_NOTIFICATION':
      return { ...initialState };

    default:
      return state;
  }
};

export default notificationReducer;
