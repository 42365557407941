import React from 'react';

import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import SummaryCard from 'lib/components/SummaryCard/SummaryCard';
import BuildingIcon from 'lib/assets/images/build.svg';
import UserIcon from 'lib/assets/images/user.svg';
import TrendIcon from 'lib/assets/images/trending.svg';

const UserNotificationSummary = () => {
  const notificationsCount = useSelector(
    (state) => state?.dashboardState?.notificationsCount,
  );

  const notificationsCountLoader = useSelector(
    (state) => state?.dashboardState?.notificationsCountLoading,
  );

  return (
    <Row gutter={[16, 16]} style={{ marginTop: '1rem' }}>
      <Col span={8}>
        <SummaryCard
          units={notificationsCount?.sent}
          description="Total Notification Sent"
          imgSrc={BuildingIcon}
          imgAlt="total notification sent"
          loader={notificationsCountLoader}
        />
      </Col>
      <Col span={8}>
        <SummaryCard
          units={notificationsCount?.approved}
          description="Total Notification Approved"
          imgSrc={UserIcon}
          imgAlt="total notification approved"
          loader={notificationsCountLoader}
        />
      </Col>
      <Col span={8}>
        <SummaryCard
          units={notificationsCount?.rejected}
          description="Total Notification Rejected"
          imgSrc={TrendIcon}
          imgAlt="total notification rejected"
          loader={notificationsCountLoader}
        />
      </Col>
      <Col span={8}>
        <SummaryCard
          units={notificationsCount?.declined}
          description="Total Notification Declined"
          imgSrc={BuildingIcon}
          imgAlt="total notification declined"
          loader={notificationsCountLoader}
        />
      </Col>
      <Col span={8}>
        <SummaryCard
          units={notificationsCount?.pending}
          description="Total Notification Pending"
          imgSrc={UserIcon}
          imgAlt="total notification pending"
          loader={notificationsCountLoader}
        />
      </Col>
    </Row>
  );
};

export default UserNotificationSummary;
