import mutualFundConstants from 'lib/constants/mutualFundConstants';

const initialState = {
  bankList: [],
  mutualFundList: [],
  countries: [],
  statesOfSelectedCountry: [],
  lgaOfSelectedLGAs: [],
  queryTransactionResult: [],
  createMutualFundHolder: {},
  mutualFundTransactions: [],
};

const mutualFundReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'BANK_LIST_SUCCESS':
      return {
        ...state,
        bankList: action?.payload,
      };

    case mutualFundConstants.MUTUAL_FUND_LIST_SUCCESS:
      return {
        ...state,
        mutualFundList: action?.payload,
      };

    case 'QUERY_RESULT':
      return {
        ...state,
        queryTransactionResult: action?.payload,
      };

    case mutualFundConstants.LOAD_MUTUAL_FUND_TRANSACTIONS:
      return {
        ...state,
        mutualFundTransactions: action?.payload,
      };

    case mutualFundConstants.CLEAR_MUTUAL_FUND_LIST:
      return {
        ...state,
        mutualFundTransactions: [],
      };

    case mutualFundConstants.UPDATE_MUTUAL_FUND_HOLDER:
      return {
        ...state,
        createMutualFundHolder: {
          ...state.createMutualFundHolder,
          ...action?.payload,
        },
      };

    case mutualFundConstants.CLEAR_MUTUAL_FUND_HOLDER:
      return {
        ...state,
        createMutualFundHolder: {},
      };

    default:
      return state;
  }
};

export default mutualFundReducer;
