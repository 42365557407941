export const userConstants = {
  LOGIN_FAILURE: 'USER_LOGIN_FAILURE',
  LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',

  RESET_PASSWORD_SUCCESS: 'USER_RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'USER_RESET_PASSWORD_FAILURE',

  SET_NEW_PASSWORD_SUCCESS: 'SET_NEW_PASSWORD_SUCCESS',
  SET_NEW_PASSWORD_FAILURE: 'SET_NEW_PASSWORD_FAILURE',
  RESET_TO_INITIAL_STATE: 'RESET_TO_INITIAL_STATE',

  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

  RENEW_PASSWORD_SUCCESS: 'RENEW_PASSWORD_SUCCESS',
  RENEW_PASSWORD_FAILURE: 'RENEW_PASSWORD_FAILURE',

  UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILURE: 'UPDATE_PROFILE_FAILURE',

  USER_NAME: 'USER_NAME',

  LOGOUT: 'LOGOUT',

  TWO_FA_AUTHENTICATION: 'TWO_FA_AUTHENTICATION',
};
