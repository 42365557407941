import adminSettingsConstants from 'lib/constants/adminSettingsConstants';

const {
  GET_ACCOUNT_POLICY,
  GET_ACCOUNT_POLICY_LOADER,
  GET_MODULES_LOADER,
  GET_MODULES_FAILURE,
  GET_MODULES_SUCCESS,
  GET_WORKFLOW_MODULE_DETAILS,
  SET_ACTIVE_SUB_MENU,
  SET_IS_LOGGED_IN,
  SET_OPEN_SUBMENU_KEYS,
} = adminSettingsConstants;

const initialState = {
  getAccountPolicyLoader: false,
  accountPolicySettings: null,
  getModulesLoader: false,
  modules: [],
  modulesMessage: '',
  isModuleLoaded: false,
  worfklowModuleId: null,
  worfklowModuleName: null,
  activeSubmenuName: null,
  isLoggedInSuccessfully: false,
  openSubmenuKeys: [],
};

function adminSettingsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_OPEN_SUBMENU_KEYS:
      return {
        ...state,
        openSubmenuKeys: action.payload,
      };
    case GET_ACCOUNT_POLICY:
      return { ...state, accountPolicySettings: action.payload };
    case GET_ACCOUNT_POLICY_LOADER:
      return { ...state, getAccountPolicyLoader: action.payload };
    case GET_MODULES_LOADER:
      return { ...state, getModulesLoader: action.payload };
    case GET_MODULES_SUCCESS:
      return {
        ...state,
        modules: action.payload,
        modulesMessage: action.message,
        isModuleLoaded: action.isModuleLoaded,
      };
    case GET_MODULES_FAILURE:
      return {
        ...state,
        modulesMessage: action.message,
        isModuleLoaded: action.isModuleLoaded,
      };
    case GET_WORKFLOW_MODULE_DETAILS:
      return {
        ...state,
        worfklowModuleId: action.payload?.moduleId,
        worfklowModuleName: action.payload?.moduleName,
      };
    case SET_ACTIVE_SUB_MENU:
      return {
        ...state,
        activeSubmenuName: action.payload,
      };
    case SET_IS_LOGGED_IN:
      return {
        ...state,
        isLoggedInSuccessfully: action.payload,
      };
    default:
      return state;
  }
}

export default adminSettingsReducer;
