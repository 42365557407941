import React from 'react';

import TableAndPagination from 'lib/components/Table/TableAndPagination';

const DashBoardActivityLog = ({
  columns,
  data,
  limit,
  hasMore,
  pageChangeLoader,
  pageChangeHandler,
  isLoading,
}) => {
  return (
    <TableAndPagination
      columns={columns}
      dataSource={data}
      pageSize={limit}
      hasMore={false}
      paginationLoading={pageChangeLoader}
      onChange={pageChangeHandler}
      loading={isLoading}
    />
  );
};

export default DashBoardActivityLog;
