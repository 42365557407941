import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './search.scss';
import SearchIcon from 'lib/assets/icons/search.svg';
import { DownOutlined } from '@ant-design/icons';
import { Select, Modal, Row, Col } from 'antd';
import { searchClientCompanies } from '../../../redux/actions/dashboardActions';
import SummaryCard from 'lib/components/SummaryCard/SummaryCard';
import BuildingIcon from '../../assets/images/build.svg';
import UserIcon from '../../assets/images/user.svg';
import TrendIcon from '../../assets/images/trending.svg';

const { Option } = Select;

export const GreenPoleTableSearchForm = () => {
  return (
    <div className="search-form">
      <input type="text" placeholder="Search Client Companies" />
      <button type="button">
        <img src={SearchIcon} alt="Search Icon" />
      </button>
    </div>
  );
};

export const GreenPoleSearchForm = () => {
  const [currentValue, setCurrentValue] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [clientCompanyName, setClientCompanyName] = useState('');
  const [
    clientCompanyShareHolderCount,
    setClientCompanyShareHolderCount,
  ] = useState('');
  const [clientCompanyShareCapital, setClientCompanyShareCapital] = useState(
    '',
  );
  const [
    clientCompanyShareValuation,
    setClientCompanyShareValuation,
  ] = useState('');
  const [clientCompanyBondHolders, setClientCompanyBondHolders] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentValue !== '' && currentValue !== null) {
      const data = {
        searchParam: currentValue,
      };
      dispatch(searchClientCompanies(data));
    }
  }, [currentValue]);

  const clientCompanies = useSelector(
    (state) => state?.dashboardState?.clientCompanies,
  );

  const handleSearch = (value) => {
    return setCurrentValue(value);
  };

  const handleSelect = (name) => {
    const option = document.getElementById(`${name}`);
    const {
      shareHolders,
      shareCapital,
      shareValuation,
      bondholders,
    } = option.dataset;
    setClientCompanyName(name);
    setClientCompanyShareHolderCount(shareHolders);
    setClientCompanyShareCapital(shareCapital);
    setClientCompanyShareValuation(shareValuation);
    setClientCompanyBondHolders(bondholders);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <Select
        showSearch
        style={{ width: 250, height: 29 }}
        placeholder="Search Client Companies"
        optionFilterProp="children"
        allowClear
        onSelect={handleSelect}
        onSearch={handleSearch}>
        {clientCompanies?.map((clientCompany) => (
          <Option
            id={clientCompany.registerName}
            key={clientCompany.id}
            value={clientCompany.registerName}
            data-share-holders={clientCompany.shareHolderCount}
            data-share-capital={clientCompany.shareCapitalCount}
            data-bond-holders={clientCompany.bondHolderCount}
            data-share-valuation={0}>
            {clientCompany.registerName}
          </Option>
        ))}
      </Select>

      <SearchModal
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        companyName={clientCompanyName}
        clientCompanyShareCapital={clientCompanyShareCapital}
        clientCompanyShareHolderCount={clientCompanyShareHolderCount}
        clientCompanyShareValuation={clientCompanyShareValuation}
        clientCompanyBondHolders={clientCompanyBondHolders}
      />
    </>
  );
};

const SearchModal = ({
  isModalVisible,
  handleOk,
  handleCancel,
  companyName,
  clientCompanyShareValuation,
  clientCompanyShareHolderCount,
  clientCompanyShareCapital,
  clientCompanyBondHolders,
}) => {
  return (
    <Modal
      title={companyName}
      className="search-modal-container"
      visible={isModalVisible}
      width={800}
      footer={null}
      onOk={handleOk}
      onCancel={handleCancel}>
      <Row gutter={[16, 16]} style={{ marginTop: '1rem' }}>
        <Col span={8}>
          <SummaryCard
            units={clientCompanyShareHolderCount + clientCompanyBondHolders}
            description="Total Share Holders & Bond Holders"
            imgSrc={BuildingIcon}
            imgAlt="total share holders and bond holders"
          />
        </Col>
        <Col span={8}>
          <SummaryCard
            units={clientCompanyShareCapital}
            description="Total Share Capital"
            imgSrc={UserIcon}
            imgAlt="total share capital"
          />
        </Col>
        <Col span={8}>
          <SummaryCard
            units={clientCompanyShareValuation}
            description="Total Share Valuation"
            imgSrc={TrendIcon}
            imgAlt="total share valuation"
          />
        </Col>
      </Row>
    </Modal>
  );
};

export const ModuleSearch = ({
  withDropdown,
  children,
  disabled,
  inputValue,
}) => {
  const [dropDown, showDropDown] = useState(false);

  return (
    <div className="module-search">
      <div className="form-control">
        {withDropdown && (
          <div className="dropdown" onClick={() => showDropDown(!dropDown)}>
            <span>Search Criteria</span>
            <DownOutlined
              style={{
                marginTop: '1px',
                fontSize: '14px',
                fontWeight: 'bold',
              }}
            />
          </div>
        )}

        <div className="input">
          <input
            type="text"
            placeholder="Search"
            disabled={disabled}
            value={inputValue}
          />
          <div className="notification__filters-search--circle">
            <img src={SearchIcon} alt="search icon" />
          </div>
        </div>
      </div>

      {dropDown && <div className="dropdown-content">{children}</div>}
    </div>
  );
};
