const paymentAndDividendConstants = {
  // GET CLIENT COMPANY
  CLIENT_COMPANY_LIST_START: 'CLIENT_COMPANY_LIST_START',
  CLIENT_COMPANY_LIST_SUCCESS: 'CLIENT_COMPANY_LIST_SUCCESS',
  CLIENT_COMPANY_LIST_FAILED: 'CLIENT_COMPANY_LIST_FAILED',

  // GET DIVIDEND TYPE LIST
  DIVIDEND_TYPE_LIST_START: 'DIVIDEND_TYPE_LIST_START',
  DIVIDEND_TYPE_LIST_SUCCESS: 'DIVIDEND_TYPE_LIST_SUCCESS',
  DIVIDEND_TYPE_LIST_FAILED: 'DIVIDEND_TYPE_LIST_FAILED',

  // GET COUPON TYPE LIST
  COUPON_TYPE_LIST_START: 'COUPON_TYPE_LIST_START',
  COUPON_TYPE_LIST_SUCCESS: 'COUPON_TYPE_LIST_SUCCESS',
  COUPON_TYPE_LIST_FAILED: 'COUPON_TYPE_LIST_FAILED',

  // GET BANKS
  BANK_LIST_START: 'BANK_LIST_START',
  BANK_LIST_SUCCESS: 'BANK_LIST_SUCCESS',
  BANK_LIST_FAILED: 'BANK_LIST_FAILED',

  // GET CLIENT COMPANY LAST DIVIDEND DECLARED PAYOUT
  CLIENT_COMPANY_LAST_DIVIDEND_DECLARED_PAYOUT_START:
    'CLIENT_COMPANY_LAST_DIVIDEND_DECLARED_PAYOUT_START',
  CLIENT_COMPANY_LAST_DIVIDEND_DECLARED_PAYOUT_SUCCESS:
    'CLIENT_COMPANY_LAST_DIVIDEND_DECLARED_PAYOUT_SUCCESS',
  CLIENT_COMPANY_LAST_DIVIDEND_DECLARED_PAYOUT_FAILED:
    'CLIENT_COMPANY_LAST_DIVIDEND_DECLARED_PAYOUT_FAILED',

  // GET GLOBAL CLIENT COMPANY DIVIDEND PAYOUT
  GLOBAL_CLIENT_COMPANY_DIVIDEND_PAYOUT_START:
    'GLOBAL_CLIENT_COMPANY_DIVIDEND_PAYOUT_START',
  GLOBAL_CLIENT_COMPANY_DIVIDEND_PAYOUT_SUCCESS:
    'GLOBAL_CLIENT_COMPANY_DIVIDEND_PAYOUT_SUCCESS',
  GLOBAL_CLIENT_COMPANY_DIVIDEND_PAYOUT_FAILED:
    'GLOBAL_CLIENT_COMPANY_DIVIDEND_PAYOUT_FAILED',

  // GET GLOBAL CLIENT COMPANY DIVIDEND PAYOUT ID
  GLOBAL_CLIENT_COMPANY_DIVIDEND_PAYOUT_ID_START:
    'GLOBAL_CLIENT_COMPANY_DIVIDEND_PAYOUT_ID_START',
  GLOBAL_CLIENT_COMPANY_DIVIDEND_PAYOUT_ID_SUCCESS:
    'GLOBAL_CLIENT_COMPANY_DIVIDEND_PAYOUT_ID_SUCCESS',
  GLOBAL_CLIENT_COMPANY_DIVIDEND_PAYOUT_ID_FAILED:
    'GLOBAL_CLIENT_COMPANY_DIVIDEND_PAYOUT_ID_FAILED',

  // GET DIVIDEND PAYOUT
  DIVIDEND_PAYOUT_START: 'DIVIDEND_PAYOUT_START',
  DIVIDEND_PAYOUT_SUCCESS: 'DIVIDEND_PAYOUT_SUCCESS',
  DIVIDEND_PAYOUT_FAILED: 'DIVIDEND_PAYOUT_FAILED',
};

export default paymentAndDividendConstants;
