import React from 'react';

import { Modal, Button } from 'antd';
import { useDispatch } from 'react-redux';
import { ClockCircleOutlined, FormOutlined } from '@ant-design/icons';
import { popStyle } from './customPopUpStyle';
import moment from 'moment';

import './Modal.scss';
import { deleteReminder } from 'redux/actions/dashboardActions';

const ReminderPopUp = ({ reminder, isModalVisible, handleCancel }) => {
  const dispatch = useDispatch();

  const handleDeleteReminderNotification = () => {
    const reminderId = reminder?.id;
    dispatch(deleteReminder(reminderId));
    handleCancel();
  };

  // useEffect(() => {
  //   let style = document.createElement('style');
  //   document.head.appendChild(style);

  //   style.innerHTML = popStyle;

  //   return () => {
  //     style.innerHTML = '';
  //   };
  // }, []);

  return (
    <Modal
      title="The title"
      className="reminderpopup-modal"
      visible={isModalVisible}
      width={380}
      footer={null}
      onCancel={handleCancel}>
      <div className="time-container">
        <div className="timeIcon">
          <ClockCircleOutlined className="icon" />
        </div>

        <div className="time">
          <p>
            {moment(reminder?.dateReceived).format('MMMM Do YYYY, h:mm:ss a')}
            <br />
            {/* <small>{reminder === null ? '' : reminder[0]?.due_time}</small> */}
          </p>
        </div>
      </div>
      <div className="time-container notes">
        <div className="timeIcon">
          <FormOutlined className="icon" />
        </div>

        <div className="time">
          <p>
            {reminder?.title}
            <br />
            <small>{reminder?.description}</small>
          </p>
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          className="popup-save-button"
          onClick={handleDeleteReminderNotification}>
          Okay
        </Button>
      </div>
    </Modal>
  );
};

export default ReminderPopUp;
