import { ErrorFallBack, Loader, NotFound } from './lib/components';
import IdleTimer from './modules/AdminSettings/AccountPolicySettings/Utils/IdleTimer';
import ReminderPopUp from './modules/Dashboard/components/Modals/PopUp';
import React, { Suspense, useEffect, lazy } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider, useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
// import { closeReminderPopUp } from 'redux/actions/dashboardActions';
import { closeReminderPopUp } from './redux/actions/dashboardActions';
import { decodeToken } from './utils/token';
import { isLoggedIn } from './utils/token';
import { PrivateRoute } from './lib/components/Routes/PrivateRoute';
import './App.css';
import store from './store';
const Login = lazy(() => import('./modules/authentication/Login/Login'));
const Pin = lazy(() => import('./modules/authentication/2FA/pin'));
const RenewPassword = lazy(() =>
  import('./modules/authentication/RenewPassword/RenewPassword'),
);
const RightsVFD = lazy(() =>
  import('./modules/Equity/Offer/RightsExternal/index'),
);
const ForgotPassword = lazy(() =>
  import('./modules/authentication/ForgotPassword/ForgotPassword'),
);
const PasswordReset = lazy(() =>
  import('./modules/authentication/PasswordReset/PasswordReset'),
);

const ChangePassword = lazy(() =>
  import('./modules/authentication/ChangePassword/ChangePassword'),
);
const ChangeNewPassword = lazy(() =>
  import('./modules/authentication/ChangeNewPassword/ChangeNewPassword'),
);

function saveToLocalStorage(state) {
  const newState = { ...state };
  delete newState.reconciliation;
  try {
    const serializedState = JSON.stringify(newState);
    localStorage.setItem('greenpole_redux_state', serializedState);
  } catch (e) {}
}

store.subscribe(() => saveToLocalStorage(store.getState()));

function App() {
  const dispatch = useDispatch();

  const isLoggedInSuccessfully = useSelector(
    (state) => state?.adminSettings?.isLoggedInSuccessfully,
  );

  const showPopUp = useSelector(
    (state) => state?.dashboardState?.reminder?.showPopUp,
  );

  const reminder = useSelector(
    (state) => state?.dashboardState?.reminder?.realTimeReminder,
  );

  const cancelReminderPopUp = () => {
    dispatch(closeReminderPopUp());
  };

  useEffect(() => {
    if (isLoggedInSuccessfully) {
      const user = decodeToken();

      const timer = new IdleTimer({
        timeout: user?.idle_time * 60,
        onTimeout: () => {
          localStorage.removeItem('_expiredTime');
          localStorage.removeItem('greenpole_redux_state');
          if (process.env.NODE_ENV === 'production') {
            window.location.replace(
              `${process.env.REACT_APP_FRONTENDURL}/login`,
            );
          } else {
            window.location.replace('http://localhost:3000/login');
          }
        },
      });

      return () => {
        timer.cleanUp();
      };
    }
  }, [isLoggedInSuccessfully]);

  const handleWebsocket = () => {
    const socket = new WebSocket(
      'ws://13.93.41.49:36057/notification-service-websocket',
    );

    ws.connect(
      {},
      function (frame) {
        ws.subscribe('/topic/notification-request', function (message) {});
      },
      function (error) {
        alert('STOMP error ' + error);
      },
    );
  };

  // useEffect(() => {
  //   handleWebsocket();
  // }, [handleWebsocket]);

  const handleRedirectToLogin = (routeProps) => {
    if (isLoggedIn()) {
      return <Redirect to="/modules/home" />;
    } else {
      return <Login {...routeProps} />;
    }
  };

  return (
    <Router>
      <Suspense fallback={<Loader fullscreen />}>
        <ErrorBoundary FallbackComponent={ErrorFallBack}>
          <Switch>
            <Route
              exact
              path="/"
              component={
                isLoggedIn() ? () => <Redirect to="/modules/home" /> : Login
              }
            />
            <Route exact path="/login" render={handleRedirectToLogin} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/rights-vfd" component={RightsVFD} />
            <Route path="/password-reset" component={PasswordReset} />
            <Route path="/change-password" component={ChangePassword} />
            <Route path="/change-new-password" component={ChangeNewPassword} />
            <Route path="/renew-password" component={RenewPassword} />
            <Route path="/login-pin" component={Pin} />
            {/* <Route exact path="/modules" component={PrivateRoute} /> */}
            <Route path="/modules" component={PrivateRoute} />

            <Route component={handleRedirectToLogin} />
          </Switch>
          {showPopUp && (
            <ReminderPopUp
              reminder={reminder}
              handleCancel={cancelReminderPopUp}
              isModalVisible={showPopUp}
            />
          )}
        </ErrorBoundary>
      </Suspense>
    </Router>
  );
}

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

export default AppWrapper;
