import paymentAndDividendConstants from '../../lib/constants/paymentAndDividendConstants';

const {
  CLIENT_COMPANY_LIST_SUCCESS,
  DIVIDEND_TYPE_LIST_SUCCESS,
  COUPON_TYPE_LIST_SUCCESS,
  BANK_LIST_SUCCESS,
  CLIENT_COMPANY_LAST_DIVIDEND_DECLARED_PAYOUT_SUCCESS,
  GLOBAL_CLIENT_COMPANY_DIVIDEND_PAYOUT_SUCCESS,
  GLOBAL_CLIENT_COMPANY_DIVIDEND_PAYOUT_ID_SUCCESS,
  DIVIDEND_PAYOUT_SUCCESS,
} = paymentAndDividendConstants;

const initialState = {
  clientCompanyList: [],
  dividendType: [],
  couponType: [],
  bankList: [],
  clientCompanyLastDividendDeclaredPayout: null,
  globalClientCompanyDividendPayout: 0,
  globalClientCompanyDividendPayoutId: 0,
  dividendPayout: [],
};

const paymentAndDividendReducer = (state = initialState, action) => {
  switch (action.type) {
    case BANK_LIST_SUCCESS:
      return {
        ...state,
        bankList: action?.payload,
      };
    case CLIENT_COMPANY_LIST_SUCCESS:
      return {
        ...state,
        clientCompanyList: action?.payload,
      };

    case DIVIDEND_TYPE_LIST_SUCCESS:
      return {
        ...state,
        dividendType: action?.payload,
      };

    case COUPON_TYPE_LIST_SUCCESS:
      return {
        ...state,
        couponType: action?.payload,
      };

    case CLIENT_COMPANY_LAST_DIVIDEND_DECLARED_PAYOUT_SUCCESS:
      return {
        ...state,
        clientCompanyLastDividendDeclaredPayout: action?.payload,
      };

    case GLOBAL_CLIENT_COMPANY_DIVIDEND_PAYOUT_SUCCESS:
      return {
        ...state,
        globalClientCompanyDividendPayout: action?.payload,
      };

    case GLOBAL_CLIENT_COMPANY_DIVIDEND_PAYOUT_ID_SUCCESS:
      return {
        ...state,
        globalClientCompanyDividendPayoutId: action?.payload,
      };

    case DIVIDEND_PAYOUT_SUCCESS:
      return {
        ...state,
        dividendPayout: action?.payload,
      };

    default:
      return state;
  }
};

export default paymentAndDividendReducer;
