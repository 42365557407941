import { userConstants } from '../../lib/constants/userConstants';

const userNameReducer = (state = {}, action) => {
  switch (action.type) {
    case userConstants.USER_NAME:
      return {
        status: action.status,
        message: action.message,
        username: action.username,
      };

    default:
      return state;
  }
};

export default userNameReducer;
