import React from 'react';
import { Menu } from 'antd';
import { FlagFilled } from '@ant-design/icons';

// TODO: Wrap component in error boundary because
// defaultProps does not work. Antd is not recognising this
// as component is passed as props to "Dropdown" component

const NotificationOverlay = (props) => (
  <Menu>
    <Menu.Item key="1">
      <FlagFilled style={{ color: '#D90B2C', margin: '10px 15px' }} />
      {props.high} High Priority
    </Menu.Item>
    <Menu.Item key="2">
      <FlagFilled style={{ color: '#F07922', margin: '10px 15px' }} />
      {props.medium} Medium
    </Menu.Item>
    <Menu.Item key="3">
      <FlagFilled style={{ color: '#299f9e', margin: '10px 15px' }} />
      {props.low} Low Priority
    </Menu.Item>
  </Menu>
);

NotificationOverlay.defaultProps = {
  high: 0,
  medium: 0,
  low: 0,
};

export default NotificationOverlay;
