const dashboardConstants = {
  // getclient_company
  CLIENT_COMPANY_FETCH_START: 'CLIENT_COMPANY_FETCH_START',
  CLIENT_COMPANY_FETCH_SUCCESS: 'CLIENT_COMPANY_FETCH_SUCCESS',
  CLIENT_COMPANY_FETCH_FAILED: ' CLIENT_COMPANY_FETCH_FAILED',
  SEARCH_CLIENT_COMPANIES: 'SEARCH_CLIENT_COMPANIES',

  // GET search_client_company
  SEARCH_CLIENT_COMPANY_FETCH_START: 'SEARCH_CLIENT_COMPANY_FETCH_START',
  SEARCH_CLIENT_COMPANY_FETCH_SUCCESS: 'SEARCH_CLIENT_COMPANY_FETCH_SUCCESS',
  SEARCH_CLIENT_COMPANY_FETCH_FAILED: ' SEARCH_CLIENT_COMPANY_FETCH_FAILED',

  // GET notifications_count
  NOTIFICATIONS_COUNT_FETCH_START: 'NOTIFICATIONS_COUNT_FETCH_START',
  NOTIFICATIONS_COUNT_FETCH_SUCCESS: 'NOTIFICATIONS_COUNT_FETCH_SUCCESS',
  NOTIFICATIONS_COUNT_LOADER: 'NOTIFICATIONS_COUNT_LOADER',
  NOTIFICATIONS_COUNT_FETCH_ERROR: 'NOTIFICATIONS_COUNT_FETCH_ERROR',
  GET_USER_NOTIFICATIONS_BY_MODULE_SUCCESS:
    'GET_USER_NOTIFICATIONS_BY_MODULE_SUCCESS',
  GET_USER_NOTIFICATIONS_BY_MODULE_ERROR:
    'GET_USER_NOTIFICATIONS_BY_MODULE_ERROR',
  SET_NOTIFICATIONS_BY_MODULE_LOADER: 'SET_NOTIFICATIONS_BY_MODULE_LOADER',

  ADD_SOCKET_NOTIFICATION: 'ADD_SOCKET_NOTIFICATION',
  REMINDER_SOCKET_NOTIFICATION: 'REMINDER_SOCKET_NOTIFICATION',

  // GET last_notifications
  LAST_NOTIFICATIONS_FETCH_SUCCESS: 'LAST_NOTIFICATIONS_FETCH_SUCCESS',
  LAST_NOTIFICATIONS_FETCH_ERROR: 'LAST_NOTIFICATIONS_FETCH_ERROR',
  LINE_MANAGER_LAST_NOTIFICATION_FETCH_SUCCESS:
    'LINE_MANAGER_LAST_NOTIFICATION_FETCH_SUCCESS',
  LINE_MANAGER_NOTIFICATION_APPROVED_REJECTED:
    'LINE_MANAGER_NOTIFICATION_APPROVED_REJECTED',
  LINE_MANAGER_NOTIFICATION_RECEIVED: 'LINE_MANAGER_NOTIFICATION_RECEIVED',
  LINE_MANAGER_MOST_RECENT_NOTIFICATIONS:
    'LINE_MANAGER_MOST_RECENT_NOTIFICATIONS',
  USER_MOST_RECENT_NOTIFICATIONS: 'USER_MOST_RECENT_NOTIFICATIONS',
  GET_LINE_MANAGER_NOTIFICATION_SUMMARY:
    'GET_LINE_MANAGER_NOTIFICATION_SUMMARY',
  GET_USER_NOTIFICATION_SUMMARY: 'GET_USER_NOTIFICATION_SUMMARY',

  // REMINDER
  SET_REMINDER_SUCCESS: 'SET_REMINDER_SUCCESS',
  SET_REMINDER_LOADER: 'SET_REMINDER_LOADER',
  GET_REMINDER: 'GET_REMINDER',
  GET_REMINDER_LOADER: 'GET_REMINDER_LOADER',
  GET_REMINDER_SUCCESS: 'GET_REMINDER_SUCCESS',
  SET_REMINDER_FAILURE: 'SET_REMINDER_FAILURE',
  UPDATE_REMINDER_FAILURE: 'UPDATE_REMINDER_FAILURE',
  UPDATE_REMINDER_SUCCESS: 'UPDATE_REMINDER_SUCCESS',
  GET_SETREMINDER_ERROR_MESSAGE: 'GET_SETREMINDER_ERROR_MESSAGE',
  CLEAR_USER_REMINDER: 'CLEAR_USER_REMINDER',
  CLOSE_POP_UP: 'CLOSE_POP_UP',
  DELETE_REMINDER_ERROR_MESSAGE: 'DELETE_REMINDER_ERROR_MESSAGE',
  DELETE_REMINDER_SUCCESS: 'DELETE_REMINDER_SUCCESS',
  DELETE_REMINDER_FAILURE: 'DELETE_REMINDER_FAILURE',
  DELETE_REMINDER_LOADER: 'DELETE_REMINDER_LOADER',

  // GET notification graph data
  GET_NOTIFICATIONS_GRAPH_DATA: 'GET_NOTIFICATIONS_GRAPH_DATA',
  GET_NOTIFICATIONS_GRAPH_DATA_RECEIVED:
    'GET_NOTIFICATIONS_GRAPH_DATA_RECEIVED',
  SET_NOTIFICATIONS_GRAPH_DATA_LOADER: 'SET_NOTIFICATIONS_GRAPH_DATA_LOADER',
  GET_NOTIFICATIONS_GRAPH_DATA_ERROR: 'GET_NOTIFICATIONS_GRAPH_DATA_ERROR',

  // Activity Log
  SET_ACTIVITY_LOG_LOADER: 'SET_ACTIVITY_LOG_LOADER',
  GET_USER_ACTIVITIES: 'GET_USER_ACTIVITIES',
  GET_USER_ACTIVITIES_ERROR: 'GET_USER_ACTIVITIES_ERROR',

  // GET task_get_for_date
  TODAY_TASK_FETCH_START: 'TODAY_TASK_FETCH_START',
  TODAY_TASK_FETCH_SUCCESS: 'TODAY_TASK_FETCH_SUCCESS',
  TODAY_TASK_FETCH_FAILED: 'TODAY_TASK_FETCH_FAILED',

  // GET event_get_for_date
  TODAY_EVENT_FETCH_START: 'TODAY_EVENT_FETCH_START',
  TODAY_EVENT_FETCH_SUCCESS: 'TODAY_EVENT_FETCH_SUCCESS',
  TODAY_EVENT_FETCH_FAILED: 'TODAY_EVENT_FETCH_FAILED',

  // GET task_get_for_date
  TASK_RANGE_FETCH_START: 'TASK_RANGE_FETCH_START',
  TASK_RANGE_FETCH_SUCCESS: 'TASK_RANGE_FETCH_SUCCESS',
  TASK_RANGE_FETCH_FAILED: 'TASK_RANGE_FETCH_FAILED',

  // GET user_profile
  USER_PROFILE_FETCH_START: 'USER_PROFILE_FETCH_START',
  USER_PROFILE_FETCH_SUCCESS: ' USER_PROFILE_FETCH_SUCCESS',
  USER_PROFILE_FETCH_FAILED: 'USER_PROFILE_FETCH_FAILED',

  // GET recent_changes
  RECENT_CHANGES_FETCH_START: 'RECENT_CHANGES_FETCH_START',
  RECENT_CHANGES_FETCH_SUCCESS: ' RECENT_CHANGES_FETCH_SUCCESS',
  RECENT_CHANGES_FETCH_FAILED: 'RECENT_CHANGES_FETCH_FAILED',

  // GET recent_changes
  RECENT_NOTIFICATIONS_FETCH_START: 'RECENT_NOTIFICATIONS_FETCH_START',
  RECENT_NOTIFICATIONS_FETCH_SUCCESS: ' RECENT_NOTIFICATIONS_FETCH_SUCCESS',
  RECENT_NOTIFICATIONS_FETCH_FAILED: 'RECENT_NOTIFICATIONS_FETCH_FAILED',

  // POST create event
  CREATE_EVENT_START: 'CREATE_EVENT_START',
  CREATE_EVENT_SUCCESS: 'CREATE_EVENT_SUCCESS',
  CREATE_EVENT_FAILED: 'CREATE_EVENT_FAILED',

  // POST create task
  CREATE_TASK_START: 'CREATE_TASK_START',
  CREATE_TASK_SUCCESS: 'CREATE_TASK_SUCCESS',
  CREATE_TASK_FAILED: 'CREATE_TASK_FAILED',

  // SWITCH DASHBOARD
  SWITCH_DASHBOARD_LINEMANAGER: 'SWITCH_DASHBOARD_LINEMANAGER',
  SWITCH_DASHBOARD_GUEST: 'SWITCH_DASHBOARD_GUEST',

  REMOVE_REMINDER_SUCCESS_MESSAGE: 'REMOVE_REMINDER_SUCCESS_MESSAGE',

  // Approval Status
  APPROVED: 3,
  PENDING: 1,
  REJECTED: 2,
};

export default dashboardConstants;
