import axios from 'axios';
import { urlConstants } from 'lib/constants/urlConstants';
import { LS } from 'lib';

const Http = axios.create({
  baseURL: `${urlConstants.PAYMENT_AND_DIVIDEND_BASE_URL}api`,
  // headers,
});

Http.interceptors.request.use(
  function (config) {
    const getToken = LS.get('greenpole_redux_state');
    config.headers.Authorization =
      getToken && getToken.auth ? `Bearer ${getToken.auth.token}` : '';
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export default Http;
