export const removeUnderScores = (text) => {
  const str = text ? text.replaceAll('_', ' ') : '';
  const finalString = str.charAt(0).toUpperCase() + str.slice(1);
  return finalString;
};

export const removeUDot = (text) => {
  const str = text ? text.replaceAll('.', ' ') : '';
  const finalString = str.charAt(0).toUpperCase() + str.slice(1);
  return finalString;
};
