import adminSettingsAxiosInstance from '../../axios/adminSettings.axios';
import { NotificationsApi } from '../../Api/Notifications.api';

export const getPaymentAndDividendNotification = async (query) => {
  return adminSettingsAxiosInstance.get(
    `${NotificationsApi.getAdminNotifications}${query}`,
    {
      'content-type': 'application/json',
    },
  );
};

export const getAdminNotifications = async (data, status, page, size) => {
  let queryString = null;
  if (data !== undefined) {
    queryString = Object.keys(data)
      .filter((item) => data[item] !== null && data[item] !== '')
      .map((key) => key + '=' + (data[key] == undefined ? '' : data[key]))
      .join('&');
  }

  return adminSettingsAxiosInstance.get(
    `${
      NotificationsApi.getAdminNotifications
    }?status=${status}&${queryString}&page=${page}&size=${size}&paged=${true}&order=DESC`,
    {
      'content-type': 'application/json',
    },
  );
};

export const updateNotificationStatus = async (
  notificationId,
  approvalStatus,
  remark,
) => {
  return adminSettingsAxiosInstance.post(
    `${NotificationsApi.approveAdminNotifications}`,
    {
      notificationId,
      approvalStatus,
      remark,
    },
    {
      'content-type': 'application/json',
    },
  );
};

export const markNotificationAsRead = async (notificationIds) => {
  return adminSettingsAxiosInstance.put(
    `${NotificationsApi.markNotificationAsRead}`,
    {
      notificationIds,
    },
    {
      'content-type': 'application/json',
    },
  );
};
