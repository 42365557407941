import { certificateConstant } from '../../lib/constants/certificateConstants';

const initialState = {
  clientCompanies: [],
  accountDetails: [],
  queryResult: [],
  queryHolderResult: [],
  loading: false,
  updatedChnResult: {},
  selectedId: [],
  selectedCertificate: [],
  irregularities: [],
  irregularityId: [],
  lodgedIds: [],
  lodgements: [],
  exportedLodgements: [],
  exportedLodgement: {},
  previewLodgement: '',
  previewedSupplementaryWithRin: {},
  previewedSupplementaryWithoutRin: {},
  exportedSupplementaryWithRin: {},
  exportedSupplementaryWithoutRin: {},
  certificateToBePrinted: [],
  selectedHolderIdToRemove: [],
  selectedHolderIdToRemoveWithoutRin: [],
  rightsIssue: [],
  ipo: [],
  sharebonus: [],
  loadingSharebonus: false,
  privatePlacement: [],
  lodgementsList: [],
};

const certificateReducer = (state = initialState, action) => {
  switch (action.type) {
    case certificateConstant.COMPANY_FETCH_PROGRESS:
      return {
        clientCompanies: action.payload,
        status: action.status,
        type: action.type,
        message: action.message,
        loading: true,
      };
    case certificateConstant.COMPANY_FETCH_SUCCESS:
      return {
        ...state,
        clientCompanies: action.payload,
        status: action.status,
        type: action.type,
        message: action.message,
        loading: false,
      };
    case certificateConstant.COMPANY_FETCH_FAILURE:
      return {
        ...state,
        clientCompanies: action.payload,
        status: action.status,
        type: action.type,
        message: action.message,
        loading: false,
      };
    case certificateConstant.TRANSFER_CERTIFICATE_PROGRESS:
      return {
        status: action.status,
        message: action.message,
        loading: true,
      };
    case certificateConstant.TRANSFER_CERTIFICATE_SUCCESS:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.TRANSFER_CERTIFICATE_FAILURE:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.LODGE_CERTIFICATE_PROGRESS:
      return {
        status: action.status,
        message: action.message,
        loading: true,
      };
    case certificateConstant.LODGE_CERTIFICATE_SUCCESS:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.LODGE_CERTIFICATE_FAILURE:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.SPLIT_CERTIFICATE_PROGRESS:
      return {
        status: action.status,
        message: action.message,
        loading: true,
      };
    case certificateConstant.SPLIT_CERTIFICATE_SUCCESS:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.SPLIT_CERTIFICATE_FAILURE:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.REQUEST_CERTIFICATE_UNCLAIM_PROGRESS:
      return {
        status: action.status,
        message: action.message,
        loading: true,
      };
    case certificateConstant.REQUEST_CERTIFICATE_UNCLAIM_SUCCESS:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.REQUEST_CERTIFICATE_UNCLAIM_FAILURE:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.MERGE_CERTIFICATE_PROGRESS:
      return {
        status: action.status,
        message: action.message,
        loading: true,
      };
    case certificateConstant.MERGE_CERTIFICATE_SUCCESS:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.MERGE_CERTIFICATE_FAILURE:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.ADD_HOLDER_WITH_RIN_PROGRESS:
      return {
        status: action.status,
        message: action.message,
        loading: true,
      };
    case certificateConstant.ADD_HOLDER_WITH_RIN_SUCCESS:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.ADD_HOLDER_WITH_RIN_FAILURE:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.REPLACE_RIN_PROGRESS:
      return {
        status: action.status,
        message: action.message,
        loading: true,
      };
    case certificateConstant.REPLACE_RIN_SUCCESS:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.REPLACE_RIN_FAILURE:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.WITHDRAW_RIN_PROGRESS:
      return {
        status: action.status,
        message: action.message,
        loading: true,
      };
    case certificateConstant.WITHDRAW_RIN_SUCCESS:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.WITHDRAW_RIN_FAILURE:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.CLAIM_CERTIFICATE_PROGRESS:
      return {
        status: action.status,
        message: action.message,
        loading: true,
      };
    case certificateConstant.CLAIM_CERTIFICATE_SUCCESS:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.CLAIM_CERTIFICATE_FAILURE:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.ADD_HOLDER_WITHOUT_RIN_PROGRESS:
      return {
        status: action.status,
        message: action.message,
        loading: true,
      };
    case certificateConstant.ADD_HOLDER_WITHOUT_RIN_SUCCESS:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.ADD_HOLDER_WITHOUT_RIN_FAILURE:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.UPLOAD_HOLDER_PROGRESS:
      return {
        status: action.status,
        message: action.message,
        loading: true,
      };
    case certificateConstant.UPLOAD_HOLDER_SUCCESS:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.UPLOAD_HOLDER_FAILURE:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.UPLOAD_SUPPLEMENTARY_WITHOUT_RIN_PROGRESS:
      return {
        status: action.status,
        message: action.message,
        loading: true,
      };
    case certificateConstant.UPLOAD_SUPPLEMENTARY_WITHOUT_RIN_SUCCESS:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.UPLOAD_SUPPLEMENTARY_WITHOUT_RIN_FAILURE:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.CREATE_IRREGULARITIES_PROGRESS:
      return {
        status: action.status,
        message: action.message,
        loading: true,
      };
    case certificateConstant.CREATE_IRREGULARITIES_SUCCESS:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.CREATE_IRREGULARITIES_FAILURE:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.UPDATE_IRREGULARITIES_PROGRESS:
      return {
        status: action.status,
        message: action.message,
        loading: true,
      };
    case certificateConstant.UPDATE_IRREGULARITIES_SUCCESS:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.UPDATE_IRREGULARITIES_FAILURE:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.UPDATE_VERIFICATION_PROGRESS:
      return {
        status: action.status,
        message: action.message,
        loading: true,
      };
    case certificateConstant.UPDATE_VERIFICATION_SUCCESS:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.UPDATE_VERIFICATION_FAILURE:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.UPLOAD_SUPPLEMENTARY_WITH_RIN_PROGRESS:
      return {
        status: action.status,
        message: action.message,
        loading: true,
      };
    case certificateConstant.UPLOAD_SUPPLEMENTARY_WITH_RIN_SUCCESS:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.UPLOAD_SUPPLEMENTARY_WITH_RIN_FAILURE:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.UPDATE_CHN_AND_AGENT_PROGRESS:
      return {
        status: action.status,
        message: action.message,
        loading: true,
      };
    case certificateConstant.UPDATE_CHN_AND_AGENT_SUCCESS:
      return {
        ...state,
        updatedChnResult: action.payload,
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.UPDATE_CHN_AND_AGENT_FAILURE:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.DISPLAY_ACCOUNT_DETAILS_PROGRESS:
      return {
        accountDetails: action.payload,
        status: action.status,
        message: action.message,
        loading: true,
      };
    case certificateConstant.DISPLAY_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        accountDetails: action.payload,
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.DISPLAY_ACCOUNT_DETAILS_FAILURE:
      return {
        ...state,
        accountDetails: action.payload,
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.QUERY_CERTIFICATE_PROGRESS:
      return {
        status: action.status,
        message: action.message,
        loading: true,
      };
    case certificateConstant.QUERY_CERTIFICATE_SUCCESS:
      return {
        ...state,
        queryResult: action?.payload?.map((item, i) => {
          return {
            actualId: item.id,
            id: i,
            certificateNo: item.certificateNumber,
            oldCertificateNo: item.oldCertificateNumber,
            accountNo: item.holderArpAccountNumber,
            oldAccountNo: item.oldHolderArpAccountNumber,
            shareHolderName: item.holderName,
            holderAddress: item.holderAddress,
            clientCompany: item.issuingCompanyName,
            shareVolume: item.volumeOfBonds,
            CHN: item.chn,
            agent: item.agent,
            verification: item.verification,
            claimed: item.claimed,
            status: item.status,
            holderEmailAddress: item.holderEmailAddress,
            holderPhoneNumber: item.holderPhoneNumber,
            oldHolderCscsAccountNumber: item.oldHolderCscsAccountNumber,
            holderCscsAccountNumber: item.holderCscsAccountNumber,
            dateOfCertificationIssue: item.dateOfCertificationIssue,
            certificateNarration: item.certificateNarration,
            nameOfDirector: item.nameOfDirector,
            nameOfSecretary: item.nameOfSecretary,
            controlNumber: item.controlNumber,
            rin: item.rin,
            isSelected: false,
            isChecked: false,
          };
        }),
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.QUERY_CERTIFICATE_FAILURE:
      return {
        ...state,
        queryResult: action.payload,
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.QUERY_HOLDER_PROGRESS:
      return {
        status: action.status,
        message: action.message,
        loading: true,
      };
    case certificateConstant.QUERY_HOLDER_SUCCESS:
      return {
        ...state,
        queryHolderResult: action.payload,
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.QUERY_HOLDER_FAILURE:
      return {
        ...state,
        queryHolderResult: action.payload,
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.QUERY_IRREGULARITIES_PROGRESS:
      return {
        status: action.status,
        message: action.message,
        loading: true,
      };
    case certificateConstant.QUERY_IRREGULARITIES_SUCCESS:
      return {
        ...state,
        irregularities: action?.payload?.map((item) => {
          return {
            ...item,
            isSelected: false,
          };
        }),
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.QUERY_IRREGULARITIES_FAILURE:
      return {
        ...state,
        irregularities: action.payload,
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.TOGGLE_LODGED:
      return {
        ...state,
        queryResult: state?.queryResult?.map((item) => {
          if (item.id === action.payload) {
            item.isChecked = !item.isChecked;
          }
          return item;
        }),
        lodgedIds: state?.queryResult
          ?.map((item) => {
            let id = '';
            if (item.isChecked === true) {
              id = item.actualId;
            }
            return id;
          })
          .filter((item) => {
            return item !== '';
          }),

        lodgements: state?.lodgements?.map((item) => {
          if (item.id === action.payload) {
            item.isSelected = !item.isSelected;
          } else {
            item.isSelected = false;
          }
          return item;
        }),
        exportedLodgements: state?.lodgements
          ?.map((item) => {
            let id = '';
            if (item.isSelected === true) {
              id = item.id;
            }
            return id;
          })
          .filter((item) => {
            return item !== '';
          }),
      };
    case certificateConstant.TOGGLE_SELECTED:
      return {
        ...state,
        queryResult: state?.queryResult?.map((item) => {
          if (item.actualId === action.payload) {
            item.isSelected = !item.isSelected;
          } else {
            item.isSelected = false;
          }

          return item;
        }),
        irregularities: state?.irregularities?.map((item) => {
          if (item.id === action.payload) {
            item.isSelected = !item.isSelected;
          } else {
            item.isSelected = false;
          }
          return item;
        }),
        selectedId: state?.queryResult
          ?.map((item) => {
            let id = '';
            if (item.isSelected === true) {
              id = item.actualId;
            }
            return id;
          })
          .filter((item) => {
            return item !== '';
          }),
        selectedCertificate: state?.queryResult
          ?.map((item) => {
            let certificate = '';
            if (item.isSelected === true) {
              certificate = item.certificateNo;
            }
            return certificate;
          })
          .filter((item) => {
            return item !== '';
          }),
        irregularityId: state?.irregularities
          ?.map((item) => {
            let id = '';
            if (item.isSelected === true) {
              id = item.id;
            }
            return id;
          })
          .filter((item) => {
            return item !== '';
          }),
      };
    case certificateConstant.TOGGLE_SELECT_TO_PRINT:
      return {
        ...state,
        queryResult: state?.queryResult?.map((item) => {
          if (item.id === action.payload) {
            item.isChecked = !item.isChecked;
          } else {
            item.isChecked = false;
          }
          return item;
        }),
        certificateToBePrinted: state?.queryResult
          ?.map((item) => {
            let id = '';
            if (item.isChecked === true) {
              id = item.actualId;
            }
            return id;
          })
          .filter((item) => {
            return item !== '';
          }),
      };
    case certificateConstant.QUERY_LODGEMENT_PROGRESS:
      return {
        status: action.status,
        message: action.message,
        loading: true,
      };
    case certificateConstant.QUERY_LODGEMENT_SUCCESS:
      return {
        ...state,
        lodgements: action?.payload?.map((item) => {
          return {
            ...item,
            isSelected: false,
          };
        }),
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.QUERY_LODGEMENT_FAILURE:
      return {
        ...state,
        lodgements: action.payload,
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.REVERSE_LODGEMENT_PROGRESS:
      return {
        status: action.status,
        message: action.message,
        loading: true,
      };
    case certificateConstant.REVERSE_LODGEMENT_SUCCESS:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.REVERSE_LODGEMENT_FAILURE:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.EXPORT_LODGEMENT_PROGRESS:
      return {
        status: action.status,
        message: action.message,
        loading: true,
      };
    case certificateConstant.EXPORT_LODGEMENT_SUCCESS:
      return {
        ...state,
        exportedLodgement: action.payload,
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.EXPORT_LODGEMENT_FAILURE:
      return {
        ...state,
        exportedLodgement: action.payload,
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.PREVIEW_CERTIFICATE_PROGRESS:
      return {
        status: action.status,
        message: action.message,
        loading: true,
      };
    case certificateConstant.PREVIEW_CERTIFICATE_SUCCESS:
      return {
        ...state,
        previewLodgement: action.payload,
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.PREVIEW_LODGEMENT_FAILURE:
      return {
        ...state,
        previewLodgement: action.payload,
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.EXPORT_SUPPLEMENTARY_WITHOUT_RIN_PROGRESS:
      return {
        status: action.status,
        message: action.message,
        loading: true,
      };
    case certificateConstant.EXPORT_SUPPLEMENTARY_WITHOUT_RIN_SUCCESS:
      return {
        ...state,
        exportedSupplementaryWithoutRin: action.payload,
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.EXPORT_SUPPLEMENTARY_WITHOUT_RIN_FAILURE:
      return {
        ...state,
        exportedSupplementaryWithoutRin: action.payload,
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.EXPORT_SUPPLEMENTARY_WITH_RIN_PROGRESS:
      return {
        status: action.status,
        message: action.message,
        loading: true,
      };
    case certificateConstant.EXPORT_SUPPLEMENTARY_WITH_RIN_SUCCESS:
      return {
        ...state,
        exportedSupplementaryWithRin: action.payload,
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.EXPORT_SUPPLEMENTARY_WITH_RIN_FAILURE:
      return {
        ...state,
        exportedSupplementaryWithRin: action.payload,
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.PREVIEW_SUPPLEMENTARY_WITHOUT_RIN_PROGRESS:
      return {
        status: action.status,
        message: action.message,
        loading: true,
      };
    case certificateConstant.PREVIEW_SUPPLEMENTARY_WITHOUT_RIN_SUCCESS:
      return {
        ...state,
        // previewedSupplementaryWithoutRin: action.payload,
        previewedSupplementaryWithoutRin: {
          ...action.payload,
          shareHolders: action?.payload?.shareHolders?.map((item) => {
            return {
              ...item,
              isChecked: false,
            };
          }),
        },
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.PREVIEW_SUPPLEMENTARY_WITHOUT_RIN_FAILURE:
      return {
        ...state,
        previewedSupplementaryWithoutRin: action.payload,
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.PREVIEW_SUPPLEMENTARY_WITH_RIN_PROGRESS:
      return {
        status: action.status,
        message: action.message,
        loading: true,
      };
    case certificateConstant.PREVIEW_SUPPLEMENTARY_WITH_RIN_SUCCESS:
      return {
        ...state,
        previewedSupplementaryWithRin: {
          ...action.payload,
          shareHolders: action?.payload?.shareHolders?.map((item) => {
            return {
              ...item,
              isChecked: false,
            };
          }),
        },
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.PREVIEW_SUPPLEMENTARY_WITH_RIN_FAILURE:
      return {
        ...state,
        previewedSupplementaryWithRin: action.payload,
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.REMOVE_HOLDER_FROM_SUPPLEMENTARY:
      return {
        ...state,
        previewedSupplementaryWithRin: {
          ...state?.previewedSupplementaryWithRin,
          shareHolders: state?.previewedSupplementaryWithRin?.shareHolders?.map(
            (item) => {
              if (item.id === action.payload) {
                item.isChecked = !item.isChecked;
              }

              return item;
            },
          ),
        },
        previewedSupplementaryWithoutRin: {
          ...state?.previewedSupplementaryWithoutRin,
          shareHolders:
            state?.previewedSupplementaryWithoutRin?.shareHolders?.map(
              (item) => {
                if (item.id === action.payload) {
                  item.isChecked = !item.isChecked;
                }

                return item;
              },
            ),
        },
        selectedHolderIdToRemove:
          state?.previewedSupplementaryWithRin?.shareHolders
            ?.map((item) => {
              let id = '';
              if (item.isChecked === true) {
                id = item.id;
              }
              return id;
            })
            .filter((item) => {
              return item !== '';
            }),
        selectedHolderIdToRemoveWithoutRin:
          state?.previewedSupplementaryWithoutRin?.shareHolders
            ?.map((item) => {
              let id = '';
              if (item.isChecked === true) {
                id = item.id;
              }
              return id;
            })
            .filter((item) => {
              return item !== '';
            }),
      };
    case certificateConstant.FETCH_RIGHTS_ISSUE_PROGRESS:
      return {
        rightsIssue: action.payload,
        status: action.status,
        type: action.type,
        message: action.message,
        loading: true,
      };
    case certificateConstant.FETCH_RIGHTS_ISSUE_SUCCESS:
      return {
        ...state,
        rightsIssue: action.payload,
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.FETCH_RIGHTS_ISSUE_FAILURE:
      return {
        ...state,
        rightsIssue: action.payload,
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.FETCH_PRIVATE_PLACEMENT_PROGRESS:
      return {
        privatePlacement: action.payload,
        status: action.status,
        type: action.type,
        message: action.message,
        loading: true,
      };
    case certificateConstant.FETCH_PRIVATE_PLACEMENT_SUCCESS:
      return {
        ...state,
        privatePlacement: action.payload,
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.FETCH_PRIVATE_PLACEMENT_FAILURE:
      return {
        ...state,
        privatePlacement: action.payload,
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.FETCH_IPO_PROGRESS:
      return {
        ipo: action.payload,
        status: action.status,
        message: action.message,
        loading: true,
      };
    case certificateConstant.FETCH_IPO_SUCCESS:
      return {
        ...state,
        ipo: action.payload,
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.FETCH_IPO_FAILURE:
      return {
        ...state,
        ipo: action.payload,
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.FETCH_SHARE_BONUS_PROGRESS:
      return {
        sharebonus: action.payload,
        status: action.status,
        message: action.message,
        loadingSharebonus: true,
      };
    case certificateConstant.FETCH_SHARE_BONUS_SUCCESS:
      return {
        ...state,
        sharebonus: action.payload,
        status: action.status,
        message: action.message,
        loadingSharebonus: false,
      };
    case certificateConstant.FETCH_SHARE_BONUS_FAILURE:
      return {
        ...state,
        sharebonus: action.payload,
        status: action.status,
        message: action.message,
        loadingSharebonus: false,
      };
    case certificateConstant.CONVERT_PHYSICAL_TO_ELECTRONIC_PROGRESS:
      return {
        status: action.status,
        message: action.message,
        loading: true,
      };
    case certificateConstant.CONVERT_PHYSICAL_TO_ELECTRONIC_SUCCESS:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.CONVERT_PHYSICAL_TO_ELECTRONIC_FAILURE:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.CONVERT_ELECTRONIC_TO_PHYSICAL_PROGRESS:
      return {
        status: action.status,
        message: action.message,
        loading: true,
      };
    case certificateConstant.CONVERT_ELECTRONIC_TO_PHYSICAL_SUCCESS:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.CONVERT_ELECTRONIC_TO_PHYSICAL_FAILURE:
      return {
        status: action.status,
        message: action.message,
        loading: false,
      };
    case certificateConstant.LODGEMENT_CERTIFICATE_DETAILS:
      return {
        ...state,
        lodgementDetails: action.details,
      };
    default:
      return state;
  }
};

export default certificateReducer;
