import { appNotification } from 'lib/components';
import dashboardConstants from 'lib/constants/dashboardConstants';
import stringify from 'qs-stringify';
import { urlConstants } from '../../lib/constants/index';

const commonConstants = {
  GLOBAL_LOADING_START: 'GLOBAL_LOADING_START',
  GLOBAL_LOADING_ENDED: 'GLOBAL_LOADING_ENDED',
  FETCH_START: 'FETCH_START',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_ERROR: 'FETCH_ERROR',
  SUCCESSFUL_CODE: '00',
};

const {
  USER_PROFILE_FETCH_SUCCESS,
  CLIENT_COMPANY_FETCH_SUCCESS,
  RECENT_CHANGES_FETCH_SUCCESS,
  NOTIFICATIONS_COUNT_FETCH_SUCCESS,
  NOTIFICATIONS_COUNT_FETCH_ERROR,
  NOTIFICATIONS_COUNT_LOADER,
  GET_USER_NOTIFICATIONS_BY_MODULE_SUCCESS,
  GET_USER_NOTIFICATIONS_BY_MODULE_ERROR,
  SET_NOTIFICATIONS_BY_MODULE_LOADER,
  SWITCH_DASHBOARD_LINEMANAGER,
  SWITCH_DASHBOARD_GUEST,
  SEARCH_CLIENT_COMPANIES,
  LAST_NOTIFICATIONS_FETCH_SUCCESS,
  GET_LINE_MANAGER_NOTIFICATION_SUMMARY,
  GET_USER_NOTIFICATION_SUMMARY,
  GET_NOTIFICATIONS_GRAPH_DATA,
  GET_NOTIFICATIONS_GRAPH_DATA_ERROR,
  SET_NOTIFICATIONS_GRAPH_DATA_LOADER,
  SET_ACTIVITY_LOG_LOADER,
  GET_USER_ACTIVITIES,
  GET_USER_ACTIVITIES_ERROR,
  SET_REMINDER_LOADER,
  SET_REMINDER_SUCCESS,
  GET_REMINDER_LOADER,
  GET_REMINDER_FAILURE,
  GET_REMINDER_SUCCESS,
  REMOVE_REMINDER_SUCCESS_MESSAGE,
  SET_REMINDER_FAILURE,
  USER_MOST_RECENT_NOTIFICATIONS,
  GET_SETREMINDER_ERROR_MESSAGE,
  CLOSE_POP_UP,
  DELETE_REMINDER_ERROR_MESSAGE,
  DELETE_REMINDER_SUCCESS,
  DELETE_REMINDER_FAILURE,
  DELETE_REMINDER_LOADER,
} = dashboardConstants;

export const fetchUserProfile = () => {
  const reduxState = JSON.parse(localStorage.getItem('greenpole_redux_state'));
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${urlConstants.APPLICATIONMANAGEMENT}/users/profile`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${reduxState?.auth?.token}`,
            'Content-Type': 'application/json',
          },
        },
      );

      const result = await response.json();
      dispatch({ type: USER_PROFILE_FETCH_SUCCESS, payload: result.data });
    } catch (e) {
      throw e;
    }
  };
};

export const fetchUserDept = (id) => {
  const reduxState = JSON.parse(localStorage.getItem('greenpole_redux_state'));
  return async () => {
    try {
      const response = await fetch(
        `${urlConstants.DEFAULT_BASE_URL}/department/${id}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${reduxState?.auth?.token}`,
            'Content-Type': 'application/json',
          },
        },
      );

      const result = await response.json();
      return result.data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
};

export const fetchUserUnit = (id) => {
  const reduxState = JSON.parse(localStorage.getItem('greenpole_redux_state'));
  return async () => {
    try {
      const response = await fetch(
        `${urlConstants.DEFAULT_BASE_URL}/unit/${id}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${reduxState?.auth?.token}`,
            'Content-Type': 'application/json',
          },
        },
      );

      const result = await response.json();
      return result.data;
    } catch (e) {
      return null;
    }
  };
};

export const fetchUserPosition = (id) => {
  const reduxState = JSON.parse(localStorage.getItem('greenpole_redux_state'));
  return async () => {
    try {
      const response = await fetch(
        `${urlConstants.DEFAULT_BASE_URL}/level/${id}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${reduxState?.auth?.token}`,
            'Content-Type': 'application/json',
          },
        },
      );

      const result = await response.json();
      return result.data;
    } catch (e) {
      return null;
    }
  };
};

export const fetchClientCompanyDashboardData = () => {
  const reduxState = JSON.parse(localStorage.getItem('greenpole_redux_state'));
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${urlConstants.DASHBOARD_BASE_URL}dashboard/client_company`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${reduxState?.auth?.token}`,
            'Content-Type': 'application/json',
          },
        },
      );

      const result = await response.json();
      dispatch({ type: CLIENT_COMPANY_FETCH_SUCCESS, payload: result.data });
    } catch (e) {
      throw e;
    }
  };
};

export const fetchRecentChanges = () => {
  const reduxState = JSON.parse(localStorage.getItem('greenpole_redux_state'));
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${urlConstants.DASHBOARD_BASE_URL}dashboard/recent_changes`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${reduxState?.auth?.token}`,
            'Content-Type': 'application/json',
          },
        },
      );

      const result = await response.json();
      dispatch({
        type: RECENT_CHANGES_FETCH_SUCCESS,
        payload: result.data,
      });
    } catch (e) {
      throw e;
    }
  };
};

export const fetchUserNotificationsCount = () => {
  const reduxState = JSON.parse(localStorage.getItem('greenpole_redux_state'));
  return async (dispatch, getState) => {
    try {
      if (getState().dashboardState.notificationsCount.hasOwnProperty('sent')) {
        dispatch({
          type: NOTIFICATIONS_COUNT_LOADER,
          payload: true,
        });
      }
      const response = await fetch(
        `${urlConstants.APPLICATIONMANAGEMENT}/notifications/stats/status`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${reduxState?.auth?.token}`,
            'Content-Type': 'application/json',
          },
        },
      );

      const result = await response.json();
      if (result.code === '00') {
        dispatch({
          type: NOTIFICATIONS_COUNT_FETCH_SUCCESS,
          payload: result.data,
          message: result?.message,
        });
      } else {
        dispatch({
          type: NOTIFICATIONS_COUNT_FETCH_ERROR,
          message: result?.message,
        });
      }
    } catch (e) {
      dispatch({
        type: NOTIFICATIONS_COUNT_FETCH_ERROR,
        message: e.response?.message,
      });
    }

    dispatch({
      type: NOTIFICATIONS_COUNT_LOADER,
      payload: false,
    });
  };
};

export const fetchUserNotificationsByModule = () => {
  const reduxState = JSON.parse(localStorage.getItem('greenpole_redux_state'));
  return async (dispatch, getState) => {
    try {
      if (getState().dashboardState.notificationsByModule.length < 1) {
        dispatch({
          type: SET_NOTIFICATIONS_BY_MODULE_LOADER,
          payload: true,
        });
      }
      const response = await fetch(
        `${urlConstants.APPLICATIONMANAGEMENT}/notifications/stats/module`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${reduxState?.auth?.token}`,
            'Content-Type': 'application/json',
          },
        },
      );

      const result = await response.json();
      if (result.code === '00') {
        dispatch({
          type: GET_USER_NOTIFICATIONS_BY_MODULE_SUCCESS,
          payload: result.data,
          message: result?.message,
        });
      } else {
        dispatch({
          type: GET_USER_NOTIFICATIONS_BY_MODULE_ERROR,
          message: result?.message,
        });
      }
    } catch (e) {
      dispatch({
        type: GET_USER_NOTIFICATIONS_BY_MODULE_ERROR,
        message: e.response?.message,
      });
    }

    dispatch({
      type: SET_NOTIFICATIONS_BY_MODULE_LOADER,
      payload: false,
    });
  };
};

export const fetchGraphOfNotificationData = (requestParam) => {
  const reduxState = JSON.parse(localStorage.getItem('greenpole_redux_state'));
  return async (dispatch) => {
    dispatch({ type: SET_NOTIFICATIONS_GRAPH_DATA_LOADER, payload: true });
    try {
      const params = stringify(requestParam);
      const response = await fetch(
        `${urlConstants.APPLICATIONMANAGEMENT}/notifications/stats/date-range?${params}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${reduxState?.auth?.token}`,
            'Content-Type': 'application/json',
          },
        },
      );

      const result = await response.json();
      if (result.code === '00') {
        dispatch({
          type: GET_NOTIFICATIONS_GRAPH_DATA,
          payload: result?.data,
          message: result?.message,
        });
      } else {
        dispatch({
          type: GET_NOTIFICATIONS_GRAPH_DATA_ERROR,
          message: result?.message,
        });
      }
    } catch (e) {
      dispatch({
        type: GET_NOTIFICATIONS_GRAPH_DATA_ERROR,
        message: e.response?.message,
      });
    }
    dispatch({ type: SET_NOTIFICATIONS_GRAPH_DATA_LOADER, payload: false });
  };
};

export const switchToLineManager = () => {
  return async (dispatch) => {
    dispatch({ type: SWITCH_DASHBOARD_LINEMANAGER });
  };
};

export const switchToGuest = () => {
  return async (dispatch) => {
    dispatch({ type: SWITCH_DASHBOARD_GUEST });
  };
};

export const searchClientCompanies = (requestParam) => {
  const reduxState = JSON.parse(localStorage.getItem('greenpole_redux_state'));
  return async (dispatch) => {
    try {
      const params = stringify(requestParam);
      const response = await fetch(
        `${urlConstants.DASHBOARD_BASE_URL}dashboard/search_client_company?${params}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${reduxState?.auth?.token}`,
            'Content-Type': 'application/json',
          },
        },
      );

      const data = await response.json();
      dispatch({ type: SEARCH_CLIENT_COMPANIES, payload: data.data });
    } catch (e) {
      throw e;
    }
  };
};

export const lastNotifications = () => {
  const reduxState = JSON.parse(localStorage.getItem('greenpole_redux_state'));

  return async (dispatch) => {
    try {
      const response = await fetch(
        `${
          urlConstants.BASEURL
        }api/v2/notifications/unread?paged=${true}&order=DESC`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${reduxState?.auth?.token}`,
            'Content-Type': 'application/json',
          },
        },
      );

      const result = await response.json();
      dispatch({
        type: LAST_NOTIFICATIONS_FETCH_SUCCESS,
        payload: result?.data?.content,
      });
    } catch (e) {
      throw e;
    }
  };
};

export const userMostRecentNotifications = () => {
  const reduxState = JSON.parse(localStorage.getItem('greenpole_redux_state'));
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${urlConstants.DASHBOARD_BASE_URL}dashboard/most-recent-notifications`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${reduxState?.auth?.token}`,
            'Content-Type': 'application/json',
          },
        },
      );

      const result = await response.json();
      dispatch({
        type: USER_MOST_RECENT_NOTIFICATIONS,
        payload: result.data,
      });
    } catch (e) {
      throw e;
    }
  };
};

export const setReminder = (data, setIsModalVisible) => {
  const reduxState = JSON.parse(localStorage.getItem('greenpole_redux_state'));
  return async (dispatch) => {
    dispatch({
      type: SET_REMINDER_LOADER,
      payload: true,
    });
    try {
      const response = await fetch(
        `${urlConstants.APPLICATIONMANAGEMENT}/reminder-notifications`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${reduxState?.auth?.token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        },
      );

      const result = await response.json();

      if (!response.ok) {
        dispatch({
          type: GET_SETREMINDER_ERROR_MESSAGE,
          payload: result.statusMessage,
        });
        appNotification(result.statusMessage, 'Error', 'error');
        throw new Error('something went wrong');
      }

      if (result.code === commonConstants.SUCCESSFUL_CODE) {
        dispatch({
          type: SET_REMINDER_SUCCESS,
          payload: result.data,
        });
        dispatch({
          type: SET_REMINDER_LOADER,
          payload: false,
        });
        appNotification('Reminder was set successfully!');
        setIsModalVisible(false);
      } else {
        dispatch({
          type: SET_REMINDER_LOADER,
          payload: false,
        });
        appNotification(`${result.message}`);
      }
    } catch (error) {
      dispatch({
        type: SET_REMINDER_LOADER,
        payload: false,
      });
      dispatch({
        type: SET_REMINDER_FAILURE,
        payload: true,
      });
      return error;
    }
  };
};

export const deleteReminder = (reminderId) => {
  const reduxState = JSON.parse(localStorage.getItem('greenpole_redux_state'));
  return async (dispatch) => {
    dispatch({
      type: DELETE_REMINDER_LOADER,
      payload: true,
    });
    try {
      const response = await fetch(
        `${urlConstants.APPLICATIONMANAGEMENT}/reminder-notifications/remove/${reminderId}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${reduxState?.auth?.token}`,
            'Content-Type': 'application/json',
          },
        },
      );

      const result = await response.json();

      if (!response.ok) {
        dispatch({
          type: DELETE_REMINDER_ERROR_MESSAGE,
          payload: result?.statusMessage,
        });
        appNotification(result?.statusMessage, 'Error', 'error');
        throw new Error('something went wrong');
      }

      if (result.code === commonConstants.SUCCESSFUL_CODE) {
        dispatch({
          type: DELETE_REMINDER_SUCCESS,
          payload: result.data,
        });
        dispatch({
          type: DELETE_REMINDER_LOADER,
          payload: false,
        });
        closeReminderPopUp();
      } else {
        dispatch({
          type: DELETE_REMINDER_LOADER,
          payload: false,
        });
        appNotification(`${result.message}`);
      }
    } catch (error) {
      dispatch({
        type: DELETE_REMINDER_LOADER,
        payload: false,
      });
      dispatch({
        type: DELETE_REMINDER_FAILURE,
        payload: true,
      });
      return error;
    }
  };
};

export const getReminders = () => {
  const reduxState = JSON.parse(localStorage.getItem('greenpole_redux_state'));
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${urlConstants.APPLICATIONMANAGEMENT}/reminder-notifications/fetch`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${reduxState?.auth?.token}`,
            'Content-Type': 'application/json',
          },
        },
      );

      const result = await response.json();
      if (result.code === commonConstants.SUCCESSFUL_CODE) {
        dispatch({
          type: GET_REMINDER_SUCCESS,
          payload: result.data.content,
        });
        dispatch({
          type: GET_REMINDER_LOADER,
          payload: false,
        });
      } else {
        dispatch({
          type: GET_REMINDER_LOADER,
          payload: false,
        });
        appNotification(`${result.message}`);
      }
    } catch (e) {
      dispatch({
        type: GET_REMINDER_LOADER,
        payload: false,
      });
      dispatch({
        type: GET_REMINDER_FAILURE,
        payload: true,
      });
    }
  };
};

export const userNotificationSummary = (requestParam) => {
  const reduxState = JSON.parse(localStorage.getItem('greenpole_redux_state'));
  return async (dispatch) => {
    try {
      const params = stringify(requestParam);
      const response = await fetch(
        `${urlConstants.DASHBOARD_BASE_URL}dashboard/notifications-summary?${params}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${reduxState?.auth?.token}`,
            'Content-Type': 'application/json',
          },
        },
      );

      const result = await response.json();
      return result.data;
    } catch (e) {
      throw e;
    }
  };
};

export const lineNotificationNotificationSummary = (requestParam) => {
  const reduxState = JSON.parse(localStorage.getItem('greenpole_redux_state'));
  return async (dispatch) => {
    try {
      const params = stringify(requestParam);
      const response = await fetch(
        `${urlConstants.DASHBOARD_BASE_URL}dashboard/line-manager/notifications-summary?${params}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${reduxState?.auth?.token}`,
            'Content-Type': 'application/json',
          },
        },
      );

      const result = await response.json();
      return result.data;
    } catch (e) {
      throw e;
    }
  };
};

export const getLineNotificationSummary = (payload) => {
  return async (dispatch) => {
    dispatch({ type: GET_LINE_MANAGER_NOTIFICATION_SUMMARY, payload });
  };
};

export const getUserNotificationSummary = (payload) => {
  return async (dispatch) => {
    dispatch({ type: GET_USER_NOTIFICATION_SUMMARY, payload });
  };
};

export const removeSuccessMessage = () => {
  return async (dispatch) => {
    dispatch({ type: REMOVE_REMINDER_SUCCESS_MESSAGE });
  };
};

export const removeErrorMessage = () => {
  return async (dispatch) => {
    dispatch({ type: SET_REMINDER_FAILURE, payload: false });
  };
};

export const closeReminderPopUp = () => {
  return async (dispatch) => {
    dispatch({ type: CLOSE_POP_UP, payload: false });
  };
};
