import dashboardConstants from 'lib/constants/dashboardConstants';

const {
  ADD_SOCKET_NOTIFICATION,
  REMINDER_SOCKET_NOTIFICATION,
  USER_PROFILE_FETCH_SUCCESS,
  CLIENT_COMPANY_FETCH_SUCCESS,
  RECENT_CHANGES_FETCH_SUCCESS,
  NOTIFICATIONS_COUNT_FETCH_SUCCESS,
  NOTIFICATIONS_COUNT_LOADER,
  NOTIFICATIONS_COUNT_FETCH_ERROR,
  GET_USER_NOTIFICATIONS_BY_MODULE_SUCCESS,
  GET_USER_NOTIFICATIONS_BY_MODULE_ERROR,
  SET_NOTIFICATIONS_BY_MODULE_LOADER,
  SEARCH_CLIENT_COMPANIES,
  LAST_NOTIFICATIONS_FETCH_SUCCESS,
  GET_NOTIFICATIONS_GRAPH_DATA,
  SET_NOTIFICATIONS_GRAPH_DATA_LOADER,
  GET_NOTIFICATIONS_GRAPH_DATA_ERROR,
  SET_ACTIVITY_LOG_LOADER,
  GET_USER_ACTIVITIES,
  GET_USER_ACTIVITIES_ERROR,
  GET_USER_NOTIFICATION_SUMMARY,
  SET_REMINDER_LOADER,
  SET_REMINDER_SUCCESS,
  GET_REMINDER_SUCCESS,
  REMOVE_REMINDER_SUCCESS_MESSAGE,
  GET_NOTIFICATIONS_GRAPH_DATA_RECEIVED,
  SET_REMINDER_FAILURE,
  USER_MOST_RECENT_NOTIFICATIONS,
  GET_SETREMINDER_ERROR_MESSAGE,
  LINE_MANAGER_NOTIFICATIONS_COUNT_FETCH_SUCCESS,
  CLEAR_USER_REMINDER,
  CLOSE_POP_UP,
  DELETE_REMINDER_LOADER,
} = dashboardConstants;

const initialState = {
  userProfile: {
    address: null,
    city: null,
    country: null,
    dob: null,
    email: '',
    firstName: '',
    gender: null,
    id: 24,
    jobDescription: null,
    lastChangePasswordDate: null,
    lastName: '',
    lgaOfOrigin: null,
    maritalStatus: null,
    middleName: null,
    occupation: null,
    permissions: [],
    phone: null,
    postalCode: null,
    positionId: undefined,
    roles: [],
    stateOfOrigin: null,
  },

  reminder: {
    setReminderErrorMessage: '',
    setReminderLoader: false,
    successMessage: false,
    setReminderFailure: false,
    userReminder: [],
    showPopUp: false,
  },

  lastNotifications: [],
  lineManagerMostRecentNotifications: [],
  userMostRecentNotifications: [],
  lineManagerLastNotifications: [],
  lineManagerNotificationReceivedCount: [],
  lineManagerApprovedRejectedCount: [],
  lineManagerNotificationSummary: [],
  userNotificationSummary: [],
  notificationGraphData: [],
  notificationGrapDataLoader: false,
  graphLoader: false,
  notificationGraphErrorMessage: null,
  notificationGraphDataReceived: [],
  notificationGraphDataReceivedLoader: false,
  lineManagerNotificationCount: {},
  notificationsCount: {},
  notificationsByModule: [],
  notificationsByModuleLoader: false,
  notificationsByModuleErrorMessage: null,
  isActivityLogLoader: false,
  userActivities: [],
  userActivityErrorMessage: null,
  clientCompany: {
    clientCompanyCount: 0,
    shareHolderCount: 0,
    bondHolderCount: 0,
    shareCapitalCount: 0,
  },
  recentChanges: [],
  recentNotificationsSent: [],
  todayTasks: [],
  todayEvents: [],

  userProfileLoading: false,
  notificationsCountLoading: false,
  clientCompanyLoading: false,
  recentChangesLoading: false,
  recentNotificationsSentLoading: false,
  todayEventsLoading: false,
  todayTasksLoading: false,

  date: new Date(),
  loading: false,
  error: null,

  switchdashboard: 'guest',
  clientCompanies: [],
};

function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case USER_PROFILE_FETCH_SUCCESS:
      return {
        ...state,
        userProfileLoading: false,
        userProfile: action.payload,
      };
    case CLIENT_COMPANY_FETCH_SUCCESS:
      return {
        ...state,
        clientCompanyLoading: false,
        clientCompany: action?.payload,
      };

    case RECENT_CHANGES_FETCH_SUCCESS:
      return {
        ...state,
        recentChangesLoading: false,
        recentChanges: action.payload,
      };

    // Notifications Count
    case NOTIFICATIONS_COUNT_FETCH_SUCCESS:
      return {
        ...state,
        notificationsCountLoading: false,
        notificationsCount: action.payload,
      };
    case NOTIFICATIONS_COUNT_FETCH_ERROR:
      return {
        ...state,
        notificationsCountLoading: false,
      };
    case NOTIFICATIONS_COUNT_LOADER:
      return {
        ...state,
        notificationsCountLoading: action.payload,
      };

    // Notifications by Module
    case GET_USER_NOTIFICATIONS_BY_MODULE_SUCCESS:
      return {
        ...state,
        notificationsByModule: action.payload,
      };
    case GET_USER_NOTIFICATIONS_BY_MODULE_ERROR:
      return {
        ...state,
        notificationsByModuleErrorMessage: action.message,
      };
    case SET_NOTIFICATIONS_BY_MODULE_LOADER:
      return {
        ...state,
        notificationsByModuleLoader: action.payload,
      };
    case SEARCH_CLIENT_COMPANIES:
      return {
        ...state,
        clientCompanies: action.payload,
      };
    case LAST_NOTIFICATIONS_FETCH_SUCCESS:
      return {
        ...state,
        lastNotifications: action.payload,
      };
    case ADD_SOCKET_NOTIFICATION:
      return {
        ...state,
        lastNotifications: [action.payload, ...state.lastNotifications],
      };

    // Notifications Graph Data
    case GET_NOTIFICATIONS_GRAPH_DATA:
      return {
        ...state,
        notificationGraphData: [...action.payload],
      };
    case GET_NOTIFICATIONS_GRAPH_DATA_ERROR:
      return {
        ...state,
        notificationGraphErrorMessage: action.message,
      };
    case SET_NOTIFICATIONS_GRAPH_DATA_LOADER:
      return {
        ...state,
        graphLoader: action.payload,
      };
    case GET_NOTIFICATIONS_GRAPH_DATA_RECEIVED:
      return {
        ...state,
        notificationGraphDataReceived: [...action.payload],
      };

    // Activity Log
    case SET_ACTIVITY_LOG_LOADER:
      return {
        ...state,
        isActivityLogLoader: action.payload,
      };

    case GET_USER_ACTIVITIES:
      return {
        ...state,
        userActivities: [...action.payload],
        totalElements: action.totalElements,
      };

    case GET_USER_ACTIVITIES_ERROR:
      return {
        ...state,
        userActivityErrorMessage: action.message,
      };

    case GET_USER_NOTIFICATION_SUMMARY:
      return {
        ...state,
        userNotificationSummary: action.payload,
      };
    case SET_REMINDER_LOADER:
      return {
        ...state,
        reminder: {
          ...state.reminder,
          setReminderLoader: action.payload,
        },
      };
    case SET_REMINDER_SUCCESS:
      return {
        ...state,
        reminder: {
          ...state.reminder,
          successMessage: true,
        },
      };

    case REMOVE_REMINDER_SUCCESS_MESSAGE:
      return {
        ...state,
        reminder: {
          ...state.reminder,
          successMessage: false,
          setReminderLoader: false,
        },
      };
    case SET_REMINDER_FAILURE:
      return {
        ...state,
        reminder: {
          ...state.reminder,
          setReminderFailure: action.payload,
        },
      };
    case USER_MOST_RECENT_NOTIFICATIONS:
      return {
        ...state,
        userMostRecentNotifications: action.payload,
      };
    case GET_REMINDER_SUCCESS:
      return {
        ...state,
        reminder: {
          ...state.reminder,
          userReminder: action.payload,
        },
      };
    case REMINDER_SOCKET_NOTIFICATION:
      return {
        ...state,
        reminder: {
          ...state.reminder,
          realTimeReminder: action.payload,
          showPopUp: action.isShowPopUp,
        },
      };
    case DELETE_REMINDER_LOADER:
      return {
        ...state,
        reminder: {
          ...state.reminder,
          isShowDeleteReminderLoader: action.payload,
        },
      };
    case GET_SETREMINDER_ERROR_MESSAGE:
      return {
        ...state,
        reminder: {
          ...state.reminder,
          setReminderErrorMessage: action.payload,
        },
      };
    case LINE_MANAGER_NOTIFICATIONS_COUNT_FETCH_SUCCESS:
      return {
        ...state,
        lineManagerNotificationCount: action.payload,
      };
    case CLEAR_USER_REMINDER:
      return {
        ...state,
        reminder: {
          ...state.reminder,
          userReminder: action.payload,
        },
      };
    case CLOSE_POP_UP:
      return {
        ...state,
        reminder: {
          ...state.reminder,
          showPopUp: action.payload,
        },
      };
    default:
      return state;
  }
}

export default dashboardReducer;
