import React, { lazy } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Layout } from 'lib/components';
import { isLoggedIn } from 'utils/token';
import Routes from 'routes';
const Dashboard = lazy(() => import('modules/Dashboard/Dashboard'));

export const PrivateRoute = ({
  component: PrivateComponent,
  ...otherProps
}) => {
  // Get & set user profile
  const userPayload = useSelector((state) => state.auth.user);
  const user = {
    name: `${userPayload?.firstName} ${userPayload?.lastName}`,
    pic:
      'https://www.sunjs.com/staticfile/ace_admin_v1.4.0/assets/avatars/profile-pic.jpg',
  };
  const bgType = 'primary';

  return (
    <Layout withSearch user={user} bgType={bgType}>
      {isLoggedIn() ? (
        <Routes />
      ) : (
        // <Routes />
        <Redirect to={{ pathname: '/login', state: { from: '' } }} />
      )}
    </Layout>
  );
};
