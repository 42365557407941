import React from 'react';

export const iconPaths = {
  User: () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20.778"
        height="24.832"
        viewBox="0 0 20.778 24.832">
        <circle
          fill="transparent"
          cx="5"
          cy="5"
          r="5"
          transform="translate(5.013 0.941)"
        />
        <g transform="translate(4.308)">
          <path
            d="M136.693,0a6.081,6.081,0,1,0,6.081,6.081A6.088,6.088,0,0,0,136.693,0Zm0,10.642a4.561,4.561,0,1,1,4.561-4.561A4.566,4.566,0,0,1,136.693,10.642Z"
            transform="translate(-130.612)"
          />
        </g>
        <g transform="translate(0 13.683)">
          <path
            d="M52.185,282.122A10.4,10.4,0,0,0,41.8,292.511v.76H62.574v-.76A10.4,10.4,0,0,0,52.185,282.122Zm-8.836,9.629a8.869,8.869,0,0,1,17.673,0Z"
            transform="translate(-41.796 -282.122)"
          />
        </g>
      </svg>
    );
  },
  Notification: () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="22.206"
        viewBox="0 0 20 22.206">
        <defs />
        <path
          className="a"
          d="M4.333,8.887a6.667,6.667,0,0,1,4.478-6.3,2.222,2.222,0,1,1,4.389,0,6.667,6.667,0,0,1,4.467,6.3v6.667L21,17.776v1.111H1V17.776l3.333-2.222ZM13.222,20a2.222,2.222,0,1,1-4.444,0Z"
          transform="translate(-1 -0.015)"
        />
      </svg>
    );
  },
  Dashboard: () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25.286"
        height="20.101"
        viewBox="0 0 25.286 20.101">
        <defs />
        <path
          className="a"
          d="M207.838,123.787a.988.988,0,0,0-1.255-.118c-1.478,1.007-8.891,6.073-9.688,6.87a2.963,2.963,0,0,0,4.191,4.191c.8-.8,5.862-8.21,6.87-9.688A.988.988,0,0,0,207.838,123.787Zm-8.149,9.546a.988.988,0,0,1-1.4-1.4c.31-.306,2.414-1.807,5-3.607C201.5,130.919,199.995,133.024,199.689,133.333Z"
          transform="translate(-186.347 -119.992)"
        />
        <path
          className="a"
          d="M4.555,69.736l-1.219.595a10.618,10.618,0,0,1-.666-8.976l1.4.67a.988.988,0,1,0,.854-1.781l-1.379-.662a10.681,10.681,0,0,1,8.113-5.062v1.486a.988.988,0,1,0,1.975,0V54.519a10.546,10.546,0,0,1,3.4.895.988.988,0,1,0,.813-1.8,12.633,12.633,0,0,0-15.7,18.547A.988.988,0,0,0,3.4,72.5l2.025-.988a.988.988,0,1,0-.866-1.776Z"
          transform="translate(0 -52.499)"
        />
        <path
          className="a"
          d="M394.906,192.076a.988.988,0,1,0-1.8.813,10.633,10.633,0,0,1-.425,9.581l-1.273-.606a.988.988,0,1,0-.849,1.784l2.074.988a.988.988,0,0,0,1.246-.343,12.613,12.613,0,0,0,1.028-12.216Z"
          transform="translate(-370.734 -184.629)"
        />
      </svg>
    );
  },
  List: () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23.319"
        height="19.041"
        viewBox="0 0 23.319 19.041">
        <defs />
        <g transform="translate(-996.873 -730)">
          <g transform="translate(1003.886 730)">
            <g transform="translate(0)">
              <path
                className="a"
                d="M134.434,61H121.872a2.027,2.027,0,0,0,0,4.043h12.562a2.027,2.027,0,0,0,0-4.043Z"
                transform="translate(-120 -61)"
              />
            </g>
          </g>
          <g transform="translate(1003.886 737.576)">
            <g transform="translate(0 -0.001)">
              <path
                className="a"
                d="M130.432,211h-9.079c-.746,0-1.353.906-1.353,2.02s.607,2.02,1.353,2.02h9.079c.746,0,1.353-.906,1.353-2.02S131.178,211,130.432,211Z"
                transform="translate(-120 -211)"
              />
            </g>
          </g>
          <g transform="translate(1003.886 745.001)">
            <g transform="translate(0 0)">
              <path
                className="a"
                d="M127.217,361h-6.281c-.516,0-.936.906-.936,2.02s.42,2.02.936,2.02h6.281c.516,0,.936-.906.936-2.02S127.733,361,127.217,361Z"
                transform="translate(-120 -361)"
              />
            </g>
          </g>
          <g transform="translate(996.873 730)">
            <path
              className="a"
              d="M2.021,61a2.021,2.021,0,1,0,2.021,2.021A2.024,2.024,0,0,0,2.021,61Z"
              transform="translate(0 -61)"
            />
          </g>
          <g transform="translate(996.873 737.576)">
            <g transform="translate(0 -0.001)">
              <path
                className="a"
                d="M2.02,211a2.02,2.02,0,1,0,2.02,2.02A2.022,2.022,0,0,0,2.02,211Z"
                transform="translate(0 -211)"
              />
            </g>
          </g>
          <g transform="translate(996.873 745)">
            <g transform="translate(0 0)">
              <path
                className="a"
                d="M2.02,361a2.02,2.02,0,1,0,2.02,2.02A2.022,2.022,0,0,0,2.02,361Z"
                transform="translate(0 -361)"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  },
};
