import {
  CREATE_ACCOUNT,
  RESTART_REGISTRATION,
  SUBMIT_ACCOUNT,
} from './constant';

const initialState = {
  accountCreationDetails: {},
};
export default function holders(state = initialState, action) {
  switch (action.type) {
    case CREATE_ACCOUNT:
      return {
        ...state,
        accountCreationDetails: {
          ...state.accountCreationDetails,
          ...action.payload,
        },
      };
    case SUBMIT_ACCOUNT:
      return {
        ...state,
        accountCreationDetails: {},
      };
    case RESTART_REGISTRATION:
      return {
        ...state,
        accountCreationDetails: {},
      };
    default:
      return state;
  }
}
