import React, { useState, useEffect } from 'react';
import ArrowLeft from './../../../lib/assets/icons/arrow-left.svg';
import ArrowRight from './../../../lib/assets/icons/arrow-right.svg';
import DoubleArrowLeft from './../../../lib/assets/icons/double-arrow-left.svg';
import DoubleArrowRight from './../../../lib/assets/icons/double-arrow-right.svg';
import PropTypes from 'prop-types';
import './Pagination.scss';

export const GreenPolePagination = ({
  total,
  perPage,
  returnCurrentPage,
  currentPage,
}) => {
  const pages = parseInt(total) / parseInt(perPage);
  const pagesArr = new Array(Math.ceil(pages)).fill();

  const truncatedStartPage = () => {
    if (pagesArr.length <= 5) {
      return 0;
    } else {
      return pagesArr.length - 5;
    }
  };

  return (
    <div className="greenpole-pagination">
      <span className="counter">
        {currentPage} of {Math.ceil(pages)}
      </span>

      <ul>
        {/* First page button */}
        {pages > 5 && (
          <li>
            <button type="button" onClick={() => returnCurrentPage(1)}>
              <img src={DoubleArrowLeft} alt="DoubleArrowLeft Icon" />
            </button>
          </li>
        )}

        {/* Previous page button */}
        {pages > 1 && (
          <li>
            <button
              type="button"
              onClick={() => returnCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}>
              <img src={ArrowLeft} alt="ArrowLeft Icon" />
            </button>
          </li>
        )}

        {/* Pages list */}
        {pagesArr.slice(truncatedStartPage(), 10).map((page, index) => (
          <li key={index}>
            <button
              type="button"
              className={currentPage === index + 1 ? 'active' : ''}
              onClick={() => returnCurrentPage(index + 1)}>
              {index + 1}
            </button>
          </li>
        ))}

        {/* Next page button */}
        {pages > 1 && (
          <li>
            <button
              type="button"
              onClick={() => returnCurrentPage(currentPage + 1)}
              disabled={currentPage === pagesArr.length}>
              <img src={ArrowRight} alt="ArrowRight Icon" />
            </button>
          </li>
        )}

        {/* Last page button */}
        {pages > 5 && (
          <li>
            <button
              type="button"
              onClick={() => returnCurrentPage(pagesArr.length)}>
              <img src={DoubleArrowRight} alt="DoubleArrowRight Icon" />
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

GreenPolePagination.propTypes = {
  total: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  returnCurrentPage: PropTypes.func.isRequired,
};
