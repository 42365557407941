import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLoggedIn, isUserRole } from 'utils/token';
import userRoles from 'utils/userRoles';

export const EquityRoute = ({
  component: PrivateComponent,
  bgType,
  ...otherProps
}) => {
  return (
    <Route
      {...otherProps}
      render={(props) => {
        return isLoggedIn() &&
          (isUserRole(userRoles.SUPER_ADMIN) ||
            isUserRole(userRoles.CERTIFICATE_ADMIN) ||
            isUserRole(userRoles.CLIENT_COMPANY_ADMIN) ||
            isUserRole(userRoles.DIVIDEND_ADMIN) ||
            isUserRole(userRoles.HOLDER_ADMIN) ||
            isUserRole(userRoles.TRANSACTION_ADMIN)) ? (
          <PrivateComponent {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        );
      }}
    />
  );
};
