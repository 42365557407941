import React from 'react';
import { Card, Row, Col, Spin } from 'antd';
import NumberFormat from 'react-number-format';
import './SummaryCard.scss';
import { readableAmount } from 'utils/formatter';

const SummaryCard = ({
  imgSrc,
  imgAlt,
  units,
  description,
  loader = false,
  isReadableAmount = true,
}) => {
  return (
    <Card className="summary-card">
      <div style={{ display: 'flex' }}>
        <div className="icon-container">
          <div className="icon-wrapper">
            <img src={imgSrc} className="icon-img" alt={imgAlt} />
          </div>
        </div>
        <div className="stats-text-group">
          <h4 className="stats-units">
            {loader ? (
              <Spin />
            ) : isReadableAmount ? (
              readableAmount(units)
            ) : (
              <NumberFormat
                value={units}
                displayType="text"
                thousandSeparator
              />
            )}
          </h4>
          <div className="stats-text">{description}</div>
        </div>
      </div>
    </Card>
  );
};

SummaryCard.defaultProps = {
  imgSrc: '',
  units: 0,
  description: 'none',
  imgAlt: 'empty',
};

export default SummaryCard;
