const clientCompanyConstants = {
  GET_CLIENT_COMPANY_LOOKUP_LOADER: 'GET_CLIENT_COMPANY_LOOKUP_LOADER',
  GET_CLIENT_COMPANY_LOOKUP_SUCCESS: 'GET_CLIENT_COMPANY_LOOKUP_SUCCESS',
  GET_CLIENT_COMPANY_LOOKUP_FAILURE: 'GET_CLIENT_COMPANY_LOOKUP_FAILURE',
  GET_CLIENT_COMPANY_DASHBOARD_STATS: 'GET_CLIENT_COMPANY_DASHBOARD_STATS',
  GET_CLIENT_COMPANY_STATS_LOADER: 'GET_CLIENT_COMPANY_STATS_LOADER',
  GET_CLIENT_COMPANY_DASHBOARD_STATS_FAILURE:
    'GET_CLIENT_COMPANY_DASHBOARD_STATS_FAILURE',
  GET_CLIENT_COMPANY_DASHBOARD_STATS_SUCCESS:
    'GET_CLIENT_COMPANY_DASHBOARD_STATS_SUCCESS',
  GET_CLIENT_COMPANIES_LOADER: 'GET_CLIENT_COMPANIES_LOADER',
  GET_CLIENT_COMPANIES_SUCCESS: 'GET_CLIENT_COMPANIES_SUCCESS',
  GET_CLIENT_COMPANIES_FAILURE: 'GET_CLIENT_COMPANIES_FAILURE',
  GET_DEPOSITORY_LOADER: 'GET_DEPOSITORY_LOADER',
  GET_DEPOSITORY_SUCCESS: 'GET_DEPOSITORY_SUCCESS',
  GET_DEPOSITORY_FAILURE: 'GET_DEPOSITORY_FAILURE',
  GET_SECURITY_TYPE_LOADER: 'GET_SECURITY_TYPE_LOADER',
  GET_SECURITY_TYPE_SUCCESS: 'GET_SECURITY_TYPE_SUCCESS',
  GET_SECURITY_TYPE_FAILURE: 'GET_SECURITY_TYPE_FAILURE',
  GET_EXCHANGE_LOADER: 'GET_EXCHANGE_LOADER',
  GET_EXCHANGE_SUCCESS: 'GET_EXCHANGE_SUCCESS',
  GET_EXCHANGE_FAILURE: 'GET_EXCHANGE_FAILURE',
  GET_NSE_LOADER: 'GET_NSE_LOADER',
  GET_NSE_SUCCESS: 'GET_NSE_SUCCESS',
  GET_NSE_FAILURE: 'GET_NSE_FAILURE',
};

export default clientCompanyConstants;
