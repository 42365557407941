import managementConstant from 'lib/constants/managementConstants';

const {
  EMPLOYEES_FETCH_FAILED,
  EMPLOYEES_FETCH_START,
  EMPLOYEES_FETCH_SUCCESS,
  SET_OUT_OF_OFFICE_FAILED,
  SET_OUT_OF_OFFICE_START,
  SET_OUT_OF_OFFICE_SUCCESS,
} = managementConstant;

const initialState = {
  employeesLoading: false,
  employees: [],
  outOfOfficeLoading: false,
};

function managementReducer(state = initialState, action) {
  switch (action.type) {
    case EMPLOYEES_FETCH_START:
      return { ...state, employeesLoading: true };

    case EMPLOYEES_FETCH_SUCCESS:
      return { ...state, employeesLoading: false, employees: action.payload };

    case EMPLOYEES_FETCH_FAILED:
      return { ...state, employeesLoading: false };

    case SET_OUT_OF_OFFICE_START:
      return { ...state, outOfOfficeLoading: true };
    case SET_OUT_OF_OFFICE_SUCCESS:
      return { ...state, outOfOfficeLoading: false };
    case SET_OUT_OF_OFFICE_FAILED:
      return { ...state, outOfOfficeLoading: false };

    default:
      return state;
  }
}

export default managementReducer;
