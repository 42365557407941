import Http from './httpClient';

import queryString from 'query-string';

export const createClientCompanies = (data) => {
  return Http.post('/client-company', data);
};

export const getClientCompanyLookup = () => {
  return Http.get(`/lookups/filter-search?lookUpType=NSE_SECTOR`);
};

export const getDepository = () => {
  return Http.get('/depository');
};

export const getNseSector = () => {
  return Http.get('/nse-sector');
};

export const getSecurityTypes = () => {
  return Http.get('/security-types/all');
};

export const getExchange = () => {
  return Http.get('/exchange/filter-search');
};

export const queryClientCompanies = (params) => {
  params = queryString.stringify(params);
  const query = `/client-company/filter-search?${params}&order=DESC`;
  return Http.get(query);
};

export const getClientCompanyById = (id, status = false) => {
  return Http.get(`/client-company/fetch/${id}?refresh=${status}`);
};

export const editClientCompany = (data) => {
  return Http.patch('/client-company', data);
};

export const getClientCompanyDashboardStats = () => {
  return Http.get('/client-company/dashboard-stats');
};

export const mergeClientCompanies = (data) => {
  return Http.post('/client-company/merge', data);
};

export const getClientCompanyDirectors = (clientCompanyId) => {
  return Http.get(`/client-company/directors/all/${clientCompanyId}`);
};

export const validateInvalidateService = (data) => {
  return Http.post('/client-company/invalidate', data);
};

export const createByIntroduction = (formData, clientCompanyId) => {
  return Http.post(`/holder/upload/${clientCompanyId}`, formData);
};

export const applyStockSplit = (data) => {
  return Http.post('/client-company/apply-stock-split', data);
};

export const getShareReconstructions = (params) => {
  params = queryString.stringify(params);
  return Http.get(`/client-company/reconstructions?${params}`);
};

export const applyReverseStockSplit = (data) => {
  return Http.post('/client-company/reverse-apply-stock-split', data);
};

export const getShareReconstructionById = (id) => {
  return Http.get(`/client-company/reconstructions/${id}`);
};

export const manualShareCapitalChecklist = (data) => {
  return Http.post('/client-company/share-capital-checklist', data);
};

export const getDirectorsById = (id) => {
  return Http.get(`/client-company/directors/${id}`);
};

export const addDirector = (data) => {
  return Http.post('/client-company/directors', data);
};

export const updateDirector = (data) => {
  return Http.patch('/client-company/directors', data);
};

export const getSecretaryByClientCompany = (clientCompanyId) => {
  return Http.get(`/client-company/secretary/all/${clientCompanyId}`);
};

export const updateClientCompanySecretary = (data) => {
  return Http.patch('/client-company/secretary', data);
};

export const createClientCompanySecretary = (data) => {
  return Http.post('/client-company/secretary', data);
};

export const getAllClientCompany = (clientCompanyIds) => {
  return Http.get(
    `/client-company/fetch-all?clientCompanyIds=${clientCompanyIds}`,
  );
};

export const updateClientCompanyConfig = (data) => {
  return Http.patch('/client-company/configuration', data);
};

export const getClientCompantConfigById = (id) => {
  return Http.get(`/client-company/configuration/${id}`);
};

export const getClientCompanyActivites = (companyId, params) => {
  params = queryString.stringify(params);
  return Http.get(
    `/activity-record?entityId=${companyId}&${params}&order=DESC`,
  );
};

export const getClientCompanyDividendDetails = (clientCompanyId, params) => {
  params = queryString.stringify(params);
  return Http.get(`/dividend?clientCompanyId=${clientCompanyId}&${params}`);
};
