import React, { useState, useRef } from 'react';
import DOC from 'lib/assets/icons/document-add.svg';
import './Uploader.scss';
import appNotification from '../appNotification';

export const Uploader = (props) => {
  const {
    name,
    accept,
    disabled,
    label,
    required,
    error,
    onChange,
    onBlur,
    width = '22rem',
    height = '7rem',
    displayName = true,
    ...rest
  } = props;
  const [highlight, setHighlight] = useState(false);
  const [file, setFile] = useState(null);
  const fileInput = useRef(null);
  const [fileDropError, setFileDropError] = useState({
    error: false,
    message: '',
  });

  const handleOnDragOver = (e) => {
    e.preventDefault();
    if (disabled) return;
    setHighlight(true);
  };

  const handleOnDrop = (e) => {
    e.preventDefault();
    if (disabled) return;
    const file = e.dataTransfer.files[0];
    const filetype = file.type;
    if (filetype.split('/')[0] !== 'image') {
      setFileDropError({
        error: true,
        message: 'You can only upload an image',
      });
      appNotification('You can only upload an image', undefined, 'error');
    } else {
      setFileDropError({ error: false, message: '' });
      setFile(file);
      onChange(name, file);
      setHighlight(false);
    }
  };

  const handleOnClick = () => {
    if (disabled) return;
    const el = fileInput.current;
    if (!el) {
      return;
    }
    fileInput.current.click();
  };

  const handleOnChange = (e) => {
    if (disabled) return;
    const file = e.target.files[0];
    setFile(file);
    onChange(name, file);
  };

  const handleOnDragLeave = (e) => {
    e.preventDefault();
    if (disabled) return;
    setHighlight(false);
  };

  return (
    <>
      <div>
        {label && (
          <label htmlFor={name} className={`${required ? 'required' : ''}`}>
            {label}
          </label>
        )}
        <div
          onDrop={handleOnDrop}
          onDragOver={handleOnDragOver}
          onDragLeave={handleOnDragLeave}
          onClick={handleOnClick}
          className={`file-input ${highlight ? 'highlight' : ''}`}
          style={{
            height: height,
            width: width,
          }}>
          <input
            type="file"
            name={name}
            ref={fileInput}
            accept={accept}
            onChange={handleOnChange}
            disabled={disabled}
            {...rest}
          />
          <span className="inst">
            <img src={DOC} alt="" />
            Drag file here or <span className="browse">Browse</span>
          </span>
        </div>
        {file !== null && displayName && <span>{file?.name}</span>}
        {/* {fileDropError.error && <em>{fileDropError.message}</em>} */}
        {error && <em>{error}</em>}
      </div>
    </>
  );
};
