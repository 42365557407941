import React from 'react';

import moment from 'moment';
import { Row, Col, Badge } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import './styles/NotificationCard.scss';
import { useHistory } from 'react-router';
import { lastNotifications } from 'redux/actions/dashboardActions';
import { markNotificationAsRead } from '../../AdminSettings/Network/Services/Notifications/Notifications';
import { removeUnderScores } from 'helpers/removeUnderscores';

const NotificationCard = ({ notification }) => {
  const formatAction = () => {
    const action = 'SET_RELIEF_OFFICER';
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const modules = useSelector((state) => state?.adminSettings?.modules);

  const buttonStyle = (priority) => {
    const currentModule = modules.find((m) => m.id == priority);

    return { status: currentModule?.name, style: currentModule?.code };
  };

  const notificationStatus = (status) => {
    if (status === 'PENDING') {
      return 'pending-status';
    } else if (status === 'COMPLETED_SUCCESSFULLY') {
      return 'successful-status';
    } else {
      return 'failed-status';
    }
  };

  const moduleNavigation = async (notification) => {
    let moduleId = '';
    if (notification?.actionName?.includes('shareholder')) {
      moduleId = 'shareholder';
    }
    if (
      notification?.actionName?.includes('dividend') ||
      notification?.actionName?.includes('payment')
    ) {
      moduleId = 'dividend';
    }
    if (notification?.actionName?.includes('certificate')) {
      moduleId = 'certificate';
    }
    if (notification?.actionName?.includes('client_company')) {
      moduleId = 'clientcompany';
    }
    if (notification?.actionName?.includes('transaction')) {
      moduleId = 'transactions';
    }
    if (notification?.actionName?.includes('mutual_fund')) {
      moduleId = 'mutualfund';
    }

    if (moduleId === 'mutual_fund') {
      history.push(`/mutualfund/notifications?module=${moduleId}`);
    } else {
      history.push(`/modules/Equity/notifications?module=${moduleId}`);
    }
  };

  const markAsRead = async (e, id) => {
    e.stopPropagation();
    await markNotificationAsRead([id]);
    dispatch(lastNotifications());
  };

  return (
    <div
      className="notification-card"
      onClick={() => moduleNavigation(notification)}>
      <Row justify="end">
        <Badge
          className="priority-container"
          count={'Mark as read'}
          size="big"
          style={{
            backgroundColor: '#429191',
            margin: '4px',
          }}
          onClick={(e) => markAsRead(e, notification.id)}
        />
      </Row>

      <Row>
        <Col span={18}>
          <h5>Request to approve</h5>
          <h6 className="subject">
            {removeUnderScores(notification?.actionName)}
          </h6>
        </Col>
        <Col span={6} className="time-container">
          <span className="time">
            {moment(notification?.dateCreated)?.fromNow()}
            {/* {moment(notification?.created_on).format('lll')} */}
          </span>
        </Col>
      </Row>
      <Row justify="space-between" style={{ fontSize: '0.75em' }}>
        <div>
          <p>by {notification?.requestedByName}</p>
        </div>
        {/* <div className="priority-container">
          <span className="mutual_fund">
            {buttonStyle(notification?.moduleId).status}
          </span>
        </div> */}
      </Row>
      <Row justify="space-between" style={{ fontSize: '0.75em' }}>
        <div>
          <p>Status:</p>
        </div>
        <div className="priority-container">
          <span
            className={`${notificationStatus(
              notification?.processorStatus || notification?.status,
            )}`}>
            {notification?.processorStatus || notification?.status}
          </span>
        </div>
      </Row>
      <Row justify="space-between" style={{ fontSize: '0.75em' }}>
        <div>
          <p>Remark:</p>
        </div>
        <div className="priority-container">
          <p>{notification?.processorRemark}</p>
        </div>
      </Row>
    </div>
  );
};

export default NotificationCard;
