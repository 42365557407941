export const StockBrokerConstant = {
  // create a stockbroker
  CREATE_STOCKBROKER: 'CREATE_STOCKBROKER',
  CREATE_STOCKBROKER_SUCCESS: 'CREATE_STOCKBROKER_SUCCESS',
  CREATE_STOCKBROKER_FAILURE: ' CREATE_STOCKBROKER_FAILURE',
  // Fetch a stockbroker
  FETCH_STOCKBROKER_BY_ID: 'FETCH_STOCKBROKER_BY_ID',
  FETCH_STOCKBROKER_BY_ID_SUCCESS: 'FETCH_STOCKBROKER_BY_ID_SUCCESS',
  FETCH_STOCKBROKER_BY_ID_FAILURE: ' FETCH_STOCKBROKER_BY_ID_FAILURE',
  // Fetch up stockbroker
  FETCH_STOCKBROKER: 'FETCH_STOCKBROKER',
  FETCH_STOCKBROKER_SUCCESS: 'FETCH_STOCKBROKER_SUCCESS',
  FETCH_STOCKBROKER_FAILURE: ' FETCH_STOCKBROKER_FAILURE',
  // FETCH BONDHOLDER
  FETCH_BONDHOLDER: 'FETCH_BONDHOLDER',
  FETCH_BONDHOLDER_SUCCESS: 'FETCH_BONDHOLDER_SUCCESS',
  FETCH_BONDHOLDER_FAILURE: ' FETCH_BONDHOLDER_FAILURE',
  //  FETCH SHAREHOLDER
  FETCH_SHAREHOLDER: 'FETCH_SHAREHOLDER',
  FETCH_SHAREHOLDER_SUCCESS: 'FETCH_SHAREHOLDER_SUCCESS',
  FETCH_SHAREHOLDER_FAILURE: ' FETCH_SHAREHOLDER_FAILURE',
  // upload a stockbroker signature
  UPLOAD_STOCKBROKER_SIGNATURE: 'UPLOAD_STOCKBROKER_SIGNATURE',
  UPLOAD_STOCKBROKER_SIGNATURE_SUCCESS: 'UPLOAD_STOCKBROKER_SIGNATURE_SUCCESS',
  UPLOAD_STOCKBROKER_SIGNATURE_FAILURE: ' UPLOAD_STOCKBROKER_SIGNATURE_FAILURE',
};
