import { Button, Col, Row, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { Calendar } from 'react-modern-calendar-datepicker';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActivityLog,
  ClientCompanySummary,
  MeetingReminder,
  NotificatioSummaryDonutChart,
  RecentNotifications,
  UserInfo,
  UserNotificationSummary,
} from '.';
import { setReminder } from '../../../redux/actions/dashboardActions';
import '../Dashboard.scss';
import ReminderModal from './Modals/ReminderModal';
import NotificationAreaGraph from './Notifications';

const { TabPane } = Tabs;

const UserDashboard = ({
  setIsModalVisible,
  isModalVisible,
  handleOk,
  handleCancel,
  selectedDay,
  setSelectedDay,
  handleClick,
  handleMenuClick,
  userNotificationRangeName,
  form,
}) => {
  const dispatch = useDispatch();

  const [reminderDate, setReminderDate] = useState();
  const [notifications, setNotifications] = useState([]);

  const lastNotifications = useSelector(
    (state) => state?.dashboardState?.lastNotifications,
  );

  const notificationSummary = useSelector(
    (state) => state?.dashboardState?.userNotificationSummary,
  );

  const notificationGraphData = useSelector(
    (state) => state?.dashboardState?.notificationGraphData,
  );

  const userMostRecentNotifications = useSelector(
    (state) => state?.dashboardState?.userMostRecentNotifications,
  );

  const userReminder = useSelector(
    (state) => state?.dashboardState?.reminder?.userReminder,
  );

  useEffect(() => {
    setNotifications(lastNotifications);
    // displayReminderDates(userReminder);
  }, [lastNotifications]);

  const displayReminderDates = (datesInQuestion) => {
    if (datesInQuestion.length > 0) {
      const result = datesInQuestion.map((item) => {
        const preselected = item.reminderDate.split('T')[0].split('-');
        return {
          year: preselected[0],
          month: preselected[1],
          day: preselected[2],
        };
      });

      return result;
    } else {
      return null;
    }
  };
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  const onFinish = (values) => {
    const reminderDate = values.reminderDate._d;
    const payload = {
      ...values,
      reminderDate: reminderDate.toISOString(),
    };

    dispatch(setReminder(payload, setIsModalVisible));
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <div>
      <Row gutter={[0, 0]}>
        <Col
          style={{ paddingRight: '3rem' }}
          md={{ span: 18 }}
          xl={{ span: 18 }}
          xxl={{ span: 18 }}>
          <UserInfo />

          <MeetingReminder />

          <ClientCompanySummary />

          <NotificationAreaGraph
            graphData={notificationGraphData}
            handleMenuClick={handleMenuClick}
            selectedRangeName={userNotificationRangeName}
          />

          <UserNotificationSummary />

          {/* <LastNotification lastNotifications={notifications} /> */}

          <NotificatioSummaryDonutChart summary={notificationSummary} />

          <ActivityLog />
        </Col>

        <Col
          className="dashboard-col-2"
          md={{ span: 6 }}
          xl={{ span: 6 }}
          xxl={{ span: 6 }}>
          <Tabs
            className="dashboard-tabs"
            type="card"
            size="large"
            tabBarGutter={0}>
            <TabPane
              tab={<h4 className="tabs-title">Notification</h4>}
              key="Notification">
              <RecentNotifications recentNotifications={lastNotifications} />
            </TabPane>
            <TabPane
              tab={<h4 className="tabs-title">Calendar</h4>}
              key="Calendar">
              <div className="set-reminder-container">
                <Button onClick={handleClick} className="set-reminder-button">
                  Set Reminder
                </Button>
              </div>
              <Calendar
                colorPrimary="#4DAFAE"
                calendarClassName="custom-calender"
                value={selectedDay}
                onChange={setSelectedDay}
              />
            </TabPane>
          </Tabs>
        </Col>

        <ReminderModal
          isModalVisible={isModalVisible}
          handleOk={handleOk}
          handleCancel={handleCancel}
          selectedDay={reminderDate}
          setSelectedDay={setReminderDate}
          onFinishFailed={onFinishFailed}
          tailLayout={tailLayout}
          onFinish={onFinish}
          layout={layout}
          form={form}
        />
      </Row>
    </div>
  );
};

export default UserDashboard;
