const userRoles = {
  SUPER_ADMIN: 'super_admin',
  HOLDER_ADMIN: 'holder_admin',
  MUTUAL_FUND_ADMIN: 'mutual_fund_admin',
  CLIENT_COMPANY_ADMIN: 'client_company_admin',
  TRANSACTION_ADMIN: 'transaction_admin',
  DIVIDEND_ADMIN: 'dividend_admin',
  CERTIFICATE_ADMIN: 'certificate_admin',
  OFFER_ADMIN: 'offer_admin',
  AGENT_ADMIN: 'agent_admin',
};

export default userRoles;
