import React from 'react';
import { useSelector } from 'react-redux';
import { GreenPoleAvatarXL } from 'lib/components';
import { Row, Col } from 'antd';

const UserInfo = () => {
  const userProfile = useSelector(
    (state) => state?.dashboardState?.userProfile,
  );

  return (
    <Row gutter={[16, 8]}>
      <Col>
        <GreenPoleAvatarXL src="https://placeimg.com/60/60/any" />
      </Col>
      <Col>
        <>
          <p>Welcome</p>
          <h3 style={{ textTransform: 'capitalize' }}>
            {`${userProfile?.firstName} ${userProfile?.lastName}`}
          </h3>
        </>
      </Col>
    </Row>
  );
};

export default UserInfo;
