export const certificateConstant = {
  COMPANY_FETCH_SUCCESS: 'CLIENT_COMPANY_FETCH_SUCCESS',
  COMPANY_FETCH_FAILURE: 'CLIENT_COMPANY_FETCH_FAILURE',
  COMPANY_FETCH_PROGRESS: 'COMPANY_FETCH_PROGRESS',
  TRANSFER_CERTIFICATE_SUCCESS: 'TRANSFER_CERTIFICATE_SUCCESS',
  TRANSFER_CERTIFICATE_FAILURE: 'TRANSFER_CERTIFICATE_FAILURE',
  TRANSFER_CERTIFICATE_PROGRESS: 'TRANSFER_CERTIFICATE_PROGRESS',
  SPLIT_CERTIFICATE_SUCCESS: 'SPLIT_CERTIFICATE_SUCCESS',
  SPLIT_CERTIFICATE_FAILURE: 'SPLIT_CERTIFICATE_FAILURE',
  SPLIT_CERTIFICATE_PROGRESS: 'SPLIT_CERTIFICATE_PROGRESS',
  MERGE_CERTIFICATE_PROGRESS: 'MERGE_CERTIFICATE_PROGRESS',
  MERGE_CERTIFICATE_SUCCESS: 'MERGE_CERTIFICATE_SUCCESS',
  MERGE_CERTIFICATE_FAILURE: 'MERGE_CERTIFICATE_FAILURE',
  DISPLAY_ACCOUNT_DETAILS_SUCCESS: 'DISPLAY_ACCOUNT_DETAILS_SUCCESS',
  DISPLAY_ACCOUNT_DETAILS_FAILURE: 'DISPLAY_ACCOUNT_DETAILS_FAILURE',
  DISPLAY_ACCOUNT_DETAILS_PROGRESS: 'DISPLAY_ACCOUNT_DETAILS_PROGRESS',
  QUERY_CERTIFICATE_SUCCESS: 'QUERY_CERTIFICATE_SUCCESS',
  QUERY_CERTIFICATE_FAILURE: 'QUERY_CERTIFICATE_FAILURE',
  QUERY_CERTIFICATE_PROGRESS: 'QUERY_CERTIFICATE_PROGRESS',
  TOGGLE_SELECTED: 'TOGGLE_SELECTED',
  TOGGLE_LODGED: 'TOGGLE_LODGED',
  TOGGLE_SELECT_TO_PRINT: 'TOGGLE_SELECT_TO_PRINT',
  LODGE_CERTIFICATE_PROGRESS: 'LODGE_CERTIFICATE_PROGRESS',
  LODGE_CERTIFICATE_SUCCESS: 'LODGE_CERTIFICATE_SUCCESS',
  LODGE_CERTIFICATE_FAILURE: 'LODGE_CERTIFICATE_FAILURE',
  QUERY_HOLDER_SUCCESS: 'QUERY_HOLDER_SUCCESS',
  QUERY_HOLDER_FAILURE: 'QUERY_HOLDER_FAILURE',
  QUERY_HOLDER_PROGRESS: 'QUERY_HOLDER_PROGRESS',
  ADD_HOLDER_WITH_RIN_SUCCESS: 'ADD_HOLDER_WITH_RIN_SUCCESS',
  ADD_HOLDER_WITH_RIN_FAILURE: 'ADD_HOLDER_WITH_RIN_FAILURE',
  ADD_HOLDER_WITH_RIN_PROGRESS: 'ADD_HOLDER_WITH_RIN_PROGRESS',
  ADD_HOLDER_WITHOUT_RIN_SUCCESS: 'ADD_HOLDER_WITHOUT_RIN_SUCCESS',
  ADD_HOLDER_WITHOUT_RIN_FAILURE: 'ADD_HOLDER_WITHOUT_RIN_FAILURE',
  ADD_HOLDER_WITHOUT_RIN_PROGRESS: 'ADD_HOLDER_WITHOUT_RIN_PROGRESS',
  REPLACE_RIN_PROGRESS: 'REPLACE_RIN_PROGRESS',
  REPLACE_RIN_SUCCESS: 'REPLACE_RIN_SUCCESS',
  REPLACE_RIN_FAILURE: 'REPLACE_RIN_FAILURE',
  WITHDRAW_RIN_PROGRESS: 'WITHDRAW_RIN_PROGRESS',
  WITHDRAW_RIN_SUCCESS: 'WITHDRAW_RIN_SUCCESS',
  WITHDRAW_RIN_FAILURE: 'WITHDRAW_RIN_FAILURE',
  UPDATE_CHN_AND_AGENT_PROGRESS: 'UPDATE_CHN_AND_AGENT_PROGRESS',
  UPDATE_CHN_AND_AGENT_SUCCESS: 'UPDATE_CHN_AND_AGENT_SUCCESS',
  UPDATE_CHN_AND_AGENT_FAILURE: 'UPDATE_CHN_AND_AGENT_FAILURE',
  CLAIM_CERTIFICATE_PROGRESS: 'CLAIM_CERTIFICATE_PROGRESS',
  CLAIM_CERTIFICATE_SUCCESS: 'CLAIM_CERTIFICATE_SUCCESS',
  CLAIM_CERTIFICATE_FAILURE: 'CLAIM_CERTIFICATE_FAILURE',
  UPDATE_VERIFICATION_PROGRESS: 'UPDATE_VERIFICATION_PROGRESS',
  UPDATE_VERIFICATION_SUCCESS: 'UPDATE_VERIFICATION_SUCCESS',
  UPDATE_VERIFICATION_FAILURE: 'UPDATE_VERIFICATION_FAILURE',
  UPLOAD_HOLDER_PROGRESS: 'UPLOAD_HOLDER_PROGRESS',
  UPLOAD_HOLDER_SUCCESS: 'UPLOAD_HOLDER_SUCCESS',
  UPLOAD_HOLDER_FAILURE: 'UPLOAD_HOLDER_FAILURE',
  QUERY_IRREGULARITIES_PROGRESS: 'QUERY_IRREGULARITIES_PROGRESS',
  QUERY_IRREGULARITIES_SUCCESS: 'QUERY_IRREGULARITIES_SUCCESS',
  QUERY_IRREGULARITIES_FAILURE: 'QUERY_IRREGULARITIES_FAILURE',
  UPDATE_IRREGULARITIES_PROGRESS: 'UPDATE_IRREGULARITIES_PROGRESS',
  UPDATE_IRREGULARITIES_SUCCESS: 'UPDATE_IRREGULARITIES_SUCCESS',
  UPDATE_IRREGULARITIES_FAILURE: 'UPDATE_IRREGULARITIES_FAILURE',
  CREATE_IRREGULARITIES_PROGRESS: 'CREATE_IRREGULARITIES_PROGRESS',
  CREATE_IRREGULARITIES_SUCCESS: 'CREATE_IRREGULARITIES_SUCCESS',
  CREATE_IRREGULARITIES_FAILURE: 'CREATE_IRREGULARITIES_FAILURE',
  UPLOAD_SUPPLEMENTARY_WITH_RIN_PROGRESS:
    'UPLOAD_SUPPLEMENTARY_WITH_RIN_PROGRESS',
  UPLOAD_SUPPLEMENTARY_WITH_RIN_SUCCESS:
    'UPLOAD_SUPPLEMENTARY_WITH_RIN_SUCCESS',
  UPLOAD_SUPPLEMENTARY_WITH_RIN_FAILURE:
    'UPLOAD_SUPPLEMENTARY_WITH_RIN_FAILURE',
  UPLOAD_SUPPLEMENTARY_WITHOUT_RIN_PROGRESS:
    'UPLOAD_SUPPLEMENTARY_WITHOUT_RIN_PROGRESS',
  UPLOAD_SUPPLEMENTARY_WITHOUT_RIN_SUCCESS:
    'UPLOAD_SUPPLEMENTARY_WITHOUT_RIN_SUCCESS',
  UPLOAD_SUPPLEMENTARY_WITHOUT_RIN_FAILURE:
    'UPLOAD_SUPPLEMENTARY_WITHOUT_RIN_FAILURE',
  QUERY_LODGEMENT_PROGRESS: 'QUERY_LODGEMENT_PROGRESS',
  QUERY_LODGEMENT_SUCCESS: 'QUERY_LODGEMENT_SUCCESS',
  QUERY_LODGEMENT_FAILURE: 'QUERY_LODGEMENT_FAILURE',
  EXPORT_LODGEMENT_PROGRESS: 'EXPORT_LODGEMENT_PROGRESS',
  EXPORT_LODGEMENT_SUCCESS: 'EXPORT_LODGEMENT_SUCCESS',
  EXPORT_LODGEMENT_FAILURE: 'EXPORT_LODGEMENT_FAILURE',
  PREVIEW_LODGEMENT_PROGRESS: 'PREVIEW_LODGEMENT_PROGRESS',
  PREVIEW_LODGEMENT_SUCCESS: 'PREVIEW_LODGEMENT_SUCCESS',
  PREVIEW_LODGEMENT_FAILURE: 'PREVIEW_LODGEMENT_FAILURE',
  REVERSE_LODGEMENT_PROGRESS: 'REVERSE_LODGEMENT_PROGRESS',
  REVERSE_LODGEMENT_SUCCESS: 'REVERSE_LODGEMENT_SUCCESS',
  REVERSE_LODGEMENT_FAILURE: 'REVERSE_LODGEMENT_FAILURE',
  PRINT_CERTIFICATE_PROGRESS: 'PRINT_CERTIFICATE_PROGRESS',
  PRINT_CERTIFICATE_SUCCESS: 'PRINT_CERTIFICATE_SUCCESS',
  PRINT_CERTIFICATE_FAILURE: 'PRINT_CERTIFICATE_FAILURE',
  PREVIEW_CERTIFICATE_PROGRESS: 'PREVIEW_CERTIFICATE_PROGRESS',
  PREVIEW_CERTIFICATE_SUCCESS: 'PREVIEW_CERTIFICATE_SUCCESS',
  PREVIEW_CERTIFICATE_FAILURE: 'PREVIEW_CERTIFICATE_FAILURE',
  EXPORT_SUPPLEMENTARY_WITHOUT_RIN_PROGRESS:
    'EXPORT_SUPPLEMENTARY_WITHOUT_RIN_PROGRESS',
  EXPORT_SUPPLEMENTARY_WITHOUT_RIN_SUCCESS:
    'EXPORT_SUPPLEMENTARY_WITHOUT_RIN_SUCCESS',
  EXPORT_SUPPLEMENTARY_WITHOUT_RIN_FAILURE:
    'EXPORT_SUPPLEMENTARY_WITHOUT_RIN_FAILURE',
  PREVIEW_SUPPLEMENTARY_WITHOUT_RIN_PROGRESS:
    'PREVIEW_SUPPLEMENTARY_WITHOUT_RIN_PROGRESS',
  PREVIEW_SUPPLEMENTARY_WITHOUT_RIN_SUCCESS:
    'PREVIEW_SUPPLEMENTARY_WITHOUT_RIN_SUCCESS',
  PREVIEW_SUPPLEMENTARY_WITHOUT_RIN_FAILURE:
    'PREVIEW_SUPPLEMENTARY_WITHOUT_RIN_FAILURE',
  EXPORT_SUPPLEMENTARY_WITH_RIN_PROGRESS:
    'EXPORT_SUPPLEMENTARY_WITH_RIN_PROGRESS',
  EXPORT_SUPPLEMENTARY_WITH_RIN_SUCCESS:
    'EXPORT_SUPPLEMENTARY_WITH_RIN_SUCCESS',
  EXPORT_SUPPLEMENTARY_WITH_RIN_FAILURE:
    'EXPORT_SUPPLEMENTARY_WITH_RIN_FAILURE',
  PREVIEW_SUPPLEMENTARY_WITH_RIN_PROGRESS:
    'PREVIEW_SUPPLEMENTARY_WITH_RIN_PROGRESS',
  PREVIEW_SUPPLEMENTARY_WITH_RIN_SUCCESS:
    'PREVIEW_SUPPLEMENTARY_WITH_RIN_SUCCESS',
  PREVIEW_SUPPLEMENTARY_WITH_RIN_FAILURE:
    'PREVIEW_SUPPLEMENTARY_WITH_RIN_FAILURE',
  REMOVE_HOLDER_FROM_SUPPLEMENTARY: 'REMOVE_HOLDER_FROM_SUPPLEMENTARY',
  REQUEST_CERTIFICATE_UNCLAIM_PROGRESS: 'REQUEST_CERTIFICATE_UNCLAIM_PROGRESS',
  REQUEST_CERTIFICATE_UNCLAIM_SUCCESS: 'REQUEST_CERTIFICATE_UNCLAIM_SUCCESS',
  REQUEST_CERTIFICATE_UNCLAIM_FAILURE: 'REQUEST_CERTIFICATE_UNCLAIM_FAILURE',
  FETCH_IPO_PROGRESS: 'FETCH_IPO_PROGRESS',
  FETCH_IPO_SUCCESS: 'FETCH_IPO_SUCCESS',
  FETCH_IPO_FAILURE: 'FETCH_IPO_FAILURE',
  FETCH_RIGHTS_ISSUE_PROGRESS: 'FETCH_RIGHTS_ISSUE_PROGRESS',
  FETCH_RIGHTS_ISSUE_SUCCESS: 'FETCH_RIGHTS_ISSUE_SUCCESS',
  FETCH_RIGHTS_ISSUE_FAILURE: 'FETCH_RIGHTS_ISSUE_FAILURE',
  FETCH_PRIVATE_PLACEMENT_PROGRESS: 'FETCH_PRIVATE_PLACEMENT_PROGRESS',
  FETCH_PRIVATE_PLACEMENT_SUCCESS: 'FETCH_PRIVATE_PLACEMENT_SUCCESS',
  FETCH_PRIVATE_PLACEMENT_FAILURE: 'FETCH_PRIVATE_PLACEMENT_FAILURE',
  FETCH_SHARE_BONUS_PROGRESS: 'FETCH_SHARE_BONUS_PROGRESS',
  FETCH_SHARE_BONUS_SUCCESS: 'FETCH_SHARE_BONUS_SUCCESS',
  FETCH_SHARE_BONUS_FAILURE: 'FETCH_SHARE_BONUS_FAILURE',
  FETCH_SIGNATURE_PROGRESS: 'FETCH_SIGNATURE_PROGRESS',
  FETCH_SIGNATURE_SUCCESS: 'FETCH_SIGNATURE_SUCCESS',
  FETCH_SIGNATURE_FAILURE: 'FETCH_SIGNATURE_FAILURE',
  CONVERT_ELECTRONIC_TO_PHYSICAL_PROGRESS:
    'CONVERT_ELECTRONIC_TO_PHYSICAL_PROGRESS',
  CONVERT_ELECTRONIC_TO_PHYSICAL_SUCCESS:
    'CONVERT_ELECTRONIC_TO_PHYSICAL_SUCCESS',
  CONVERT_ELECTRONIC_TO_PHYSICAL_FAILURE:
    'CONVERT_ELECTRONIC_TO_PHYSICAL_FAILURE',
  CONVERT_PHYSICAL_TO_ELECTRONIC_PROGRESS:
    'CONVERT_PHYSICAL_TO_ELECTRONIC_PROGRESS',
  CONVERT_PHYSICAL_TO_ELECTRONIC_SUCCESS:
    'CONVERT_PHYSICAL_TO_ELECTRONIC_SUCCESS',
  CONVERT_PHYSICAL_TO_ELECTRONIC_FAILURE:
    'CONVERT_PHYSICAL_TO_ELECTRONIC_FAILURE',
  LODGEMENT_CERTIFICATE_DETAILS: 'LODGEMENT_CERTIFICATE_DETAILS',
};
