import React from 'react';
import PropTypes from 'prop-types';

import './Table.scss';
/**
 * @param {array} data takes an array of items to the rendered, a list or data from an endpoint
 * @param {arry} headers takes an array of objects that includes an ID and a Label,
 * it can also take other props like className
 * @param {function} renderRow is a function that helps render table data, it can be access inside {row} object
 * @param {boolean} hasGrid is a boolean to toogle between the two types of table, ie grid cells and non grid celled
 */

const Table = ({ data = [], headers = [], renderRow, hasGrid }) => {
  return (
    <table
      cellSpacing={hasGrid ? 1 : 0}
      cellPadding={0}
      className={hasGrid ? 'GP-default-table tableGrid' : 'GP-default-table'}>
      <thead className={hasGrid ? 'tableHead' : ''}>
        <tr>
          {headers.map((header) => (
            <th key={header.id} className={header.className}>
              {header.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className={hasGrid ? 'tableBody' : ''}>
        {Array.isArray(data) &&
          data.map((rowItem, rowKey) => renderRow(rowItem, rowKey))}
      </tbody>
    </table>
  );
};

Table.propTypes = {
  data: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  renderRow: PropTypes.func.isRequired,
  hasGrid: PropTypes.bool.isRequired,
};

export default Table;
