import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import './Layout.scss';
import { Header } from './Header/Header';
import { SideBar } from './SideBar/SideBar';
import { RightSideBar } from './RightSideBar/RightSideBar';
import { Main } from './Main/Main';
import MobileSidebar from './MobileSidebar/MobileSidebar';
import { Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import dashboardConstants from 'lib/constants/dashboardConstants';
import { lastNotifications } from 'redux/actions/dashboardActions';
import { urlConstants } from 'lib/constants/index';
import { appNotification } from 'lib/components';

export const handleInAppNotifications = (message) => {
  appNotification(
    message?.processorRemark || 'Message',
    message?.description || 'Notification',
    'info',
  );
};

const {
  LAST_NOTIFICATIONS_FETCH_SUCCESS,
  ADD_SOCKET_NOTIFICATION,
  REMINDER_SOCKET_NOTIFICATION,
} = dashboardConstants;

const notifyData = {
  actionName: 'dividend.setup',
  createdBy: '936ef047-8b46-4717-9fa4-afc75513e639',
  dateCreated: '2021-10-11 14:07:49 ',
  dateReceived: '2021-10-11 14:07:49 ',
  id: '2fd7617e-5b74-42db-999e-8225c7e6f877',
  moduleId: 'bf7eedbb-eb8c-483f-b594-61ab6a5a8d5c',
  status: 'PENDING',
};
const reduxState = JSON.parse(localStorage.getItem('greenpole_redux_state'));

export const Layout = ({ children, withSearch, user, bgType }) => {
  const [mobileSideOpen, setMobileSideOpen] = useState(false);
  const dispatch = useDispatch();

  const isLoggedInSuccessfully = useSelector(
    (state) => state?.adminSettings?.isLoggedInSuccessfully,
  );

  const token = useSelector((state) => state?.auth?.token);

  useEffect(() => {
    dispatch(lastNotifications());
  }, []);

  const connectSocket = () => {
    if (isLoggedInSuccessfully) {
      const passkey = localStorage.getItem('passkey');
      const url = urlConstants.SOCKET_BASE_URL;
      var socket = io(url, {
        transports: ['websocket', 'polling', 'flashsocket'],
        query: {
          EIO: 4,
          userAuth: passkey,
        },
        withCredentials: true,
      });

      socket.on('connect', function () {
        console.log(socket.id);
      });

      socket.on('connect_error', function (error) {
        console.log(error);
      });

      socket.on('new_notification', function (data) {
        if (data?.data) {
          if (data.data?.notificationType === 'REMINDER') {
            dispatch({
              type: REMINDER_SOCKET_NOTIFICATION,
              payload: data.data,
              isShowPopUp: true,
            });
          } else {
            dispatch({
              type: ADD_SOCKET_NOTIFICATION,
              payload: data.data,
            });
            handleInAppNotifications(data.data);
          }
        }
      });
    }
  };

  // useEffect(() => {
  //   connectSocket();
  // }, [isLoggedInSuccessfully]);

  return (
    <div className="page-layout">
      <Header />
      <Row
        onClick={() => setMobileSideOpen(true)}
        className="mobile_sidebar_toggle">
        &#9776;
      </Row>
      {mobileSideOpen && (
        <MobileSidebar
          mobileSideOpen={mobileSideOpen}
          setMobileSideOpen={setMobileSideOpen}
          user={user}
        />
      )}
      <SideBar user={user} />
      <Main bgType={bgType}>{children}</Main>
      {/* <RightSideBar /> */}
    </div>
  );
};
