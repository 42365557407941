import axios from 'axios';
import { LS } from 'lib';

const settings = {
  baseURL: '',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json',
    'Content-Type': 'application/json;charset',
  },
};

const generateHeader = () => {
  let getToken = '';
  try {
    getToken = LS.get('greenpole_redux_state');
  } catch (error) {}
  return getToken && getToken.auth ? `Bearer ${getToken.auth.token}` : '';
};

export const axiosInstance = (baseUrl, contentType) => {
  if (contentType) settings.headers['Content-Type'] = contentType;
  settings.baseURL = baseUrl;
  settings.headers.Authorization = generateHeader();
  return axios.create(settings);
};
