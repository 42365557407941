import React from 'react';
import ContentLoader, { Code } from 'react-content-loader';

export const TableLoader = () => {
  return (
    <ContentLoader backgroundColor="#f1f1f1" width={1000} height={500}>
      {/* <rect x="0" y="0" rx="4" ry="4" width="500" height="40" />
      <rect x="780" y="0" rx="3" ry="3" width="220" height="40" /> */}

      <rect x="0" y="50" rx="4" ry="4" width="1000" height="40" />

      <rect x="0" y="140" rx="4" ry="4" width="150" height="40" />
      <rect x="170" y="140" rx="4" ry="4" width="150" height="40" />
      <rect x="340" y="140" rx="4" ry="4" width="150" height="40" />
      <rect x="510" y="140" rx="4" ry="4" width="150" height="40" />
      <rect x="680" y="140" rx="4" ry="4" width="150" height="40" />
      <rect x="860" y="140" rx="4" ry="4" width="150" height="40" />

      <rect x="0" y="190" rx="4" ry="4" width="150" height="40" />
      <rect x="170" y="190" rx="4" ry="4" width="150" height="40" />
      <rect x="340" y="190" rx="4" ry="4" width="150" height="40" />
      <rect x="510" y="190" rx="4" ry="4" width="150" height="40" />
      <rect x="680" y="190" rx="4" ry="4" width="150" height="40" />
      <rect x="860" y="190" rx="4" ry="4" width="150" height="40" />

      <rect x="0" y="240" rx="4" ry="4" width="150" height="40" />
      <rect x="170" y="240" rx="4" ry="4" width="150" height="40" />
      <rect x="340" y="240" rx="4" ry="4" width="150" height="40" />
      <rect x="510" y="240" rx="4" ry="4" width="150" height="40" />
      <rect x="680" y="240" rx="4" ry="4" width="150" height="40" />
      <rect x="860" y="240" rx="4" ry="4" width="150" height="40" />

      <rect x="0" y="290" rx="4" ry="4" width="150" height="40" />
      <rect x="170" y="290" rx="4" ry="4" width="150" height="40" />
      <rect x="340" y="290" rx="4" ry="4" width="150" height="40" />
      <rect x="510" y="290" rx="4" ry="4" width="150" height="40" />
      <rect x="680" y="290" rx="4" ry="4" width="150" height="40" />
      <rect x="860" y="290" rx="4" ry="4" width="150" height="40" />

      <rect x="0" y="340" rx="4" ry="4" width="150" height="40" />
      <rect x="170" y="340" rx="4" ry="4" width="150" height="40" />
      <rect x="340" y="340" rx="4" ry="4" width="150" height="40" />
      <rect x="510" y="340" rx="4" ry="4" width="150" height="40" />
      <rect x="680" y="340" rx="4" ry="4" width="150" height="40" />
      <rect x="860" y="340" rx="4" ry="4" width="150" height="40" />

      <rect x="0" y="390" rx="4" ry="4" width="150" height="40" />
      <rect x="170" y="390" rx="4" ry="4" width="150" height="40" />
      <rect x="340" y="390" rx="4" ry="4" width="150" height="40" />
      <rect x="510" y="390" rx="4" ry="4" width="150" height="40" />
      <rect x="680" y="390" rx="4" ry="4" width="150" height="40" />
      <rect x="860" y="390" rx="4" ry="4" width="150" height="40" />

      <rect x="0" y="440" rx="4" ry="4" width="150" height="40" />
      <rect x="170" y="440" rx="4" ry="4" width="150" height="40" />
      <rect x="340" y="440" rx="4" ry="4" width="150" height="40" />
      <rect x="510" y="440" rx="4" ry="4" width="150" height="40" />
      <rect x="680" y="440" rx="4" ry="4" width="150" height="40" />
      <rect x="860" y="440" rx="4" ry="4" width="150" height="40" />
    </ContentLoader>
  );
};
