import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './Header.scss';
import { GreenPoleLogo, Icon } from 'lib/components';
import { Badge, Empty, Row, Col } from 'antd';
import Notifications from 'react-notifications-menu';
import {
  NotificationCard,
  RecentNotifications,
} from 'modules/Dashboard/components';

const data = {
  image:
    'https://cutshort-data.s3.amazonaws.com/cloudfront/public/companies/5809d1d8af3059ed5b346ed1/logo-1615367026425-logo-v6.png',
  message: 'Notification one.',
  detailPage: '/events',
  receivedTime: '12h ago',
};

export const Header = () => {
  const scrollRef = useRef(null);
  const notificationRef = useRef(null);
  const containerRef = useRef(null);
  const [show, setShow] = useState(false);
  const [styles, setStyles] = useState({});
  const recentNotifications = useSelector(
    (state) => state?.dashboardState?.lastNotifications,
  );
  const notificationCount = (count) => {
    if (count == 20) return '20+';
    else return count;
  };

  const handleClickOutside = (event) => {
    if (containerRef && containerRef.current) {
      if (!containerRef.current.contains(event.target)) {
        setShow(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', (event) => {
      handleClickOutside(event);
    });

    // To make notification container to adjust based on window, if it is placed on right side
    setStyles({
      transform: `translateX(-${25}vw)`,
      height: '100vh',
      width: '25vw',
    });

    return () => {
      document.removeEventListener('mousedown', (event) => {
        handleClickOutside(event);
      });
    };
  }, []);

  return (
    <header className="header">
      <GreenPoleLogo />
      <div className="notifications" ref={containerRef}>
        <div className="icon" onClick={() => setShow(!show)}>
          <div style={{ cursor: 'pointer' }} className="btn-icon">
            <Badge count={notificationCount(recentNotifications?.length)}>
              <Icon name="Notification" />
            </Badge>
          </div>
        </div>

        <div
          className="container"
          ref={notificationRef}
          style={{
            ...styles,
            visibility: show ? 'visible' : 'hidden',
            opacity: show ? 1 : 0,
            overflow: 'scroll',
          }}>
          <div className="item" ref={scrollRef}>
            <div className="recent-notifications">
              <div className="notif-top">
                <span className="title">
                  <p>Most recent Notification</p>
                </span>
                {/* <span className="view-all-link">
            <Link to="/notifications">View all</Link>
          </span> */}
              </div>
              <div className="notif-lists">
                {recentNotifications?.length === 0 && (
                  <Empty description="No recent Notifications" />
                )}
                {recentNotifications?.length > 0 &&
                  recentNotifications.map((notification, index) => (
                    <NotificationCard key={index} notification={notification} />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
