const initialState = {
  rightsIssueTable: [],
  viewRightsIssueTable: [],
  rightsApplicationLoading: false,
  rightsApplicationResponse: '',
};

const rightsIssueReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RIGHTS_TABLE_SUCCESS':
      return {
        ...state,
        rightsIssueTable: action?.payload,
      };
    case 'VIEW_RIGHTS_TABLE_SUCCESS':
      return {
        ...state,
        viewRightsIssueTable: action?.payload,
      };
    default:
      return state;
  }
};

export default rightsIssueReducer;
