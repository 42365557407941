const Config = {
  baseURLWithPort: process.env.REACT_APP_BASE_URL,
  baseURLNoPort: process.env.REACT_APP_BASE_URL_NO_PORT,
  frontendUrl: process.env.REACT_APP_FRONTENDURL,
  clientBaseUrl: process.env.REACT_APP_CLIENT_BASE_URL,
  stockbrokerBaseUrl: process.env.REACT_APP_STOCKBROKER_BASE_URL,
  shareHolderBaseUrl: process.env.REACT_APP_SHAREHOLDER_BASE_URL,
  bondHolderBaseUrl: process.env.REACT_APP_BONDHOLDER_BASE_URL,
  holderBaseUrl: process.env.REACT_APP_HOLDER_BASE_URL,
  ipoBaseUrl: process.env.REACT_APP_IPO_BASE_URL,
  mutualFundBaseUrl: process.env.REACT_APP_MUTUALFUND_BASE_URL,
  mutualFundBaseURL: process.env.REACT_APP_MUTUAL_FUND_BASE_URL,
  stockBrokerReportUrl: process.env.REACT_APP_STOCKBROKER_REPORT_BASE_URL,
  transactionsBaseUrl: process.env.REACT_APP_TRANSACTIONS_BASE_URL,
  agentUrl: process.env.REACT_APP_AGENT_BASE_URL,
  certificateBaseUrl: process.env.REACT_APP_CERTIFICATE_BASE_URL,
  dashboardBaseUrl: process.env.REACT_APP_DASHBOARD_BASE_URL,
  departmentUrl: process.env.REACT_APP_DEPARTMENT_URL,
  bondBaseUrl: process.env.REACT_APP_BOND_BASE_URL,
  managementBaseUrl: process.env.REACT_APP_MANAGEMENT_BASE_URL,
  ReconciliationReportUrl: process.env.REACT_APP_RECONCILIATION_BASE_URL,
  transactionUrl: process.env.REACT_APP_TRANSACTION_URL,
  paymentAndDividendBaseUrl: process.env.REACT_APP_PAYMENT_AND_DIVIDEND,
  shareBonusBaseUrl: process.env.REACT_APP_SHARE_BONUS_BASE_URL,
  ipoBaseUrl: process.env.REACT_APP_IPO_BASE_URL,
  rightsIssueBaseUrl: process.env.REACT_APP_RIGHTS_ISSUE_BASE_URL,
  privatePlacementBaseUrl: process.env.REACT_APP_PRIVATE_PLACEMENT_URL,
  reportBaseUrl: process.env.REACT_APP_REPORT_URL,
  rightsIssueBaseURl: process.env.REACT_APP_RIGHTS_ISSUE_BASE_URL,
  userBaseUrl: process.env.REACT_APP_USER_DATA_URL,
  client_id: process.env.REACT_APP_CLIENT_ID,
  client_secret: process.env.REACT_APP_CLIENT_SECRET,
  passportUrl: process.env.PASSPORT_URL,
  imageBaseUrl: process.env.IMAGE_BASE_URL,
  equityBaseUrl: process.env.REACT_APP_EQUITY_BASE_URL,
  socketBaseUrl: process.env.REACT_APP_SOCKET_URL,
  storageUrl: process.env.REACT_APP_STORAGE_BASE_URL,
};

export default Config;
