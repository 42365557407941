import { StockBrokerConstant } from '../../lib/constants/stockbrokerConstants';

const {
  CREATE_STOCKBROKER,
  CREATE_STOCKBROKER_SUCCESS,
  CREATE_STOCKBROKER_FAILURE,
  // Fetch up stockbroker
  FETCH_STOCKBROKER,
  FETCH_STOCKBROKER_SUCCESS,
  FETCH_STOCKBROKER_FAILURE,
  // upload a stockbroker signature
  UPLOAD_STOCKBROKER_SIGNATURE,
  UPLOAD_STOCKBROKER_SIGNATURE_SUCCESS,
  UPLOAD_STOCKBROKER_SIGNATURE_FAILURE,
  // Fetch a stockbroker  BY ID
  FETCH_STOCKBROKER_BY_ID,
  FETCH_STOCKBROKER_BY_ID_SUCCESS,
  FETCH_STOCKBROKER_BY_ID_FAILURE,
} = StockBrokerConstant;

const initialState = {
  stockbrokerLoading: false,
  stockbrokerArray: [],
  fetchStockbroker: null,
  stockBrokerResponse: '',
};

function stockBrokerReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_STOCKBROKER:
      return { ...state, stockbrokerLoading: true };
    case CREATE_STOCKBROKER_SUCCESS:
      return {
        ...state,
        stockbrokerLoading: false,
        stockBrokerResponse: action.payload,
      };
    case CREATE_STOCKBROKER_FAILURE:
      return { ...state, stockbrokerLoading: false };

    case FETCH_STOCKBROKER:
      return { ...state, stockbrokerLoading: true };
    case FETCH_STOCKBROKER_SUCCESS:
      return {
        ...state,
        stockbrokerLoading: false,
        stockbroker: action.payload,
      };
    case FETCH_STOCKBROKER_FAILURE:
      return { ...state, stockbrokerLoading: false };

    case FETCH_STOCKBROKER_BY_ID:
      return { ...state, stockbrokerLoading: true };
    case FETCH_STOCKBROKER_BY_ID_SUCCESS:
      return {
        ...state,
        stockbrokerLoading: false,
        fetchStockbroker: action.payload,
      };
    case FETCH_STOCKBROKER_BY_ID_FAILURE:
      return { ...state, stockbrokerLoading: false };

    case UPLOAD_STOCKBROKER_SIGNATURE:
      return { ...state, stockbrokerLoading: true };
    case UPLOAD_STOCKBROKER_SIGNATURE_SUCCESS:
      return {
        ...state,
        stockbrokerLoading: false,
        stockbroker: action.payload,
      };
    case UPLOAD_STOCKBROKER_SIGNATURE_FAILURE:
      return { ...state, stockbrokerLoading: false };

    default:
      return state;
  }
}

export default stockBrokerReducer;
