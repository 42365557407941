import * as paymentDividend from 'modules/Equity/PaymentAndDividend/_redux';
import holders from 'modules/Equity/Holders/_redux';
import { combineReducers } from 'redux';
import auth from './authenticationReducer';
import bondHolder from './bondholderReducer';
import certificateReducer from './certificateReducer';
import changePassword from './changePasswordReducer';
import dashboard from './dashboardReducer';
import management from './managementReducer';
import mutualFundReducer from './mutualFundReducer';
import notificationReducer from './notificationReducers';
import resetPassword from './resetPasswordReducer';
import setNewPassword from './setNewPasswordReducer';
import shareHolder from './shareholderReducer';
import stockBroker from './stockbrokerReducer';
import updateProfile from './updateProfileReducer';
import bondReducer from './bondReducer';
import transactionReducer from './transactionReducers';
import rightsIssueReducer from './rightsIssueReducer';
import paymentAndDividendReducer from './paymentAndDividendReducer';
import userNameReducer from './userNameReducer';
import adminSettingsReducer from './adminSettingsReducer';
import equityReducer from './equityReducer';
import offerReducer from './offerReducer';
import clientCompanyReducer from './clientCompanyReducer';
import qualifiedHolderInfo from './offerMunualApplicationsReducer';

export default combineReducers({
  auth,
  stockBroker,
  shareHolder,
  bondHolder,
  holders,
  resetPassword,
  setNewPassword,
  setupDividend: paymentDividend.setupDividend,
  changePassword,
  notificationReducer,
  updateProfile,
  transactionReducer,
  rightsIssueReducer,
  certificateReducer,
  // blame simeon
  dashboardState: dashboard,
  managementState: management,
  mutualFundReducer,
  bondReducer,
  paymentAndDividendReducer,
  username: userNameReducer,
  adminSettings: adminSettingsReducer,
  equityReducer,
  clientCompanyReducer,
  offerReducer,
  qualifiedHolderInfo,
});
