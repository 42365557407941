import React from 'react';
import Steps, { Step } from 'rc-steps';
import 'rc-steps/assets/index.css';
import './Stepper.scss';

export const Stepper = ({
  steps = [],
  current = 0,
  onChange,
  isStepClickable = false,
}) => {
  return (
    <div>
      <div className="steps">
        <Steps size="small" current={current} onChange={onChange}>
          {steps.map((step) => (
            <Step
              key={step}
              title={step?.props?.title ?? step}
              onStepClick={isStepClickable && onChange}
            />
          ))}
        </Steps>
      </div>
    </div>
  );
};
