import React from 'react';
import { Row, Table, Pagination } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import GenericButton from 'shared_components/Button/Button.jsx';
import { TableLoader, Loader } from 'lib/components';
import InfiniteScroll from 'react-infinite-scroll-component';

const TableAndPagination = ({
  columns,
  dataSource,
  pageSize,
  onShowSizeChange,
  current,
  total,
  onChange,
  pageSizeOptions,
  showTotal,
  scrollValueX = 1000,
  scrollValueY,
  rowSelection,
  loading = false,
  paginationLoading = false,
  onBack,
  showPagination = true,
  rowKey = 'id',
  hasMore,
  showSn = true,
}) => {
  const generateTableColumn = () => {
    const columnsToRender = [];
    const snColumn = [
      {
        title: 'S N',
        key: 'sno',
        width: '60px',
        align: 'center',
        render: (text, object, index) => index + 1,
      },
    ];
    if (showSn) {
      columnsToRender.push(...[...snColumn, ...columns]);
    } else {
      columnsToRender.push(...columns);
    }

    return columnsToRender;
  };
  return (
    <div>
      {loading ? (
        <div>
          <TableLoader />
        </div>
      ) : (
        <InfiniteScroll
          dataLength={dataSource?.length}
          next={onChange}
          hasMore={hasMore}
          // loader={<Loader />}
        >
          <Table
            className="table"
            bordered
            columns={generateTableColumn()}
            dataSource={dataSource}
            rowKey={rowKey}
            size="middle"
            pagination={false}
            scroll={{ x: scrollValueX, y: 400 }}
            rowSelection={rowSelection}
            loading={loading}
          />
          {paginationLoading ? (
            <Loader />
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '50px',
              }}>
              {hasMore && (
                <button
                  // className="btn-primary"
                  style={{
                    marginTop: '1rem',
                    borderRadius: '3px',
                    padding: '0 2rem',
                    border: '1px solid #299f9e',
                  }}
                  onClick={onChange}>
                  Load More
                </button>
              )}
            </div>
          )}
        </InfiniteScroll>
      )}
      {/* <div
        style={{
          margin: '3rem 0',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}>
          {onBack && (
            <GenericButton
              text=""
              iconBeforeText={<ArrowLeftOutlined />}
              iconAfterText=""
              btnStyle={{
                minWidth: 'fit',
                marginRight: 10,
              }}
              onClick={onBack}
            />
          )}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          {showPagination && (
            <Pagination
              showSizeChanger
              pageSize={pageSize}
              onShowSizeChange={onShowSizeChange}
              current={current}
              total={total}
              showTotal={showTotal}
              onChange={onChange}
              pageSizeOptions={pageSizeOptions}
              responsive
            />
          )}
        </div>
      </div> */}
    </div>
  );
};

export default TableAndPagination;
