import React, { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import {
  Drawer,
  Row,
  Col,
  Input,
  Select,
  Form,
  DatePicker,
  Tag,
  Button,
} from 'antd';
import { DownOutlined, FlagFilled } from '@ant-design/icons';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { appNotification } from 'lib/components';
import managementActions from 'redux/actions/managementActions';

const { fetchEmployees } = managementActions;
const { Option } = Select;

function tagRender(props) {
  const { label, value, closable, onClose } = props;
  return (
    <Tag className="drawer-tags" closable={closable} onClose={onClose}>
      {label}
    </Tag>
  );
}

const validationSchema = Yup.object({
  title: Yup.string().required("'Task title'  is required"),
});

const TaskDrawer = (props) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.dashboardState?.loading);
  const userState = useSelector((state) => state?.auth?.user);

  const employeesList = useSelector(
    (state) => state?.managementState?.employees,
  );

  const employeesLoading = useSelector(
    (state) => state?.managementState?.employeesLoading,
  );

  /**
   LOGIC FOR DATE PICKER
   */
  const [endOpen, setEndOpen] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [priority, setPriority] = useState(null);
  const [taskParticipantIds, setTaskParticipantIds] = useState([]);

  const disabledStartDate = (startValue) => {
    // Can not select days before today and today
    return startValue && startValue < moment().endOf('day');
  };

  const disabledEndDate = (endValue) => {
    if (!endValue || !startDate) {
      return false;
    }
    return endValue.valueOf() <= startDate.valueOf();
  };

  const handleStartOpenChange = (open) => {
    if (!open) {
      setEndOpen(true);
    }
  };

  const handleEndOpenChange = (open) => {
    setEndOpen(open);
  };

  function handleStartDate(date, dateString) {
    setStartDate(date);
  }

  function handleEndDate(date, dateString) {
    setEndDate(date);
  }

  function handleSelectChange(value) {
    setTaskParticipantIds(value);
  }

  function handlePriorityChange(value) {
    setPriority(value);
  }

  /**
   END LOGIC FOR DATE PICKER
   */

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    resetForm,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      title: '',
    },
    isSubmitting: false,
    validationSchema,
    onSubmit(values) {
      const _unixStart = moment(startDate).unix();
      const _unixEnd = moment(endDate).unix();
      const data = {
        ...values,
        startDate: _unixStart,
        endDate: _unixEnd,
        priority: priority,
        taskParticipantIds: taskParticipantIds,
        createdBy: userState.id,
      };
      // dispatch(
      //   createTask(data, (e) => {
      //     setTaskParticipantIds([]);
      //     setStartDate(null);
      //     setEndDate(null);
      //     setPriority(null);
      //     setSubmitting(false);
      //   }),
      // );
      resetForm();
    },
  });

  useEffect(() => {
    dispatch(fetchEmployees());
  }, [dispatch]);

  const optionsList = employeesList
    ? employeesList.map((elem, index) => (
        <Option key={elem.id} value={elem.id}>
          {`${elem.firstName} ${elem.lastName}`}
        </Option>
      ))
    : [];

  return (
    <Drawer
      title={
        <Link to="/" className="task-header-link">
          Task Planner Full View
        </Link>
      }
      className="task-drawer"
      placement="right"
      closable
      onClose={props.onCloseTaskDrawer}
      visible={props.taskDrawerVisible}
      width={626}>
      <Form layout="vertical">
        <h3 className="calendar-title">Create Task</h3>
        <div className="title-warning">
          NOTE: You cannot create task from past dates or hours.
        </div>
        <Row gutter={16}>
          <Col className="task-drawer-column" span={24}>
            <Form.Item
              required
              label="Task To Do"
              htmlFor="title"
              colon={false}
              validateStatus={errors.title && touched.title ? 'error' : null}
              help={errors.title && touched.title ? errors.title : null}>
              <Input
                className="mag-form-input"
                name="title"
                placeholder="Task To Do"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
              />
            </Form.Item>
            <Row gutter={32}>
              <Col span={12}>
                <Form.Item
                  required
                  label="Start Task On"
                  htmlFor="startDate"
                  colon={false}
                  //   help={
                  //     <p className="form-message">Cannot pick from past dates</p>
                  //   }
                  // validateStatus={
                  //   errors.name && touched.name ? 'error' : null
                  // }
                >
                  <DatePicker
                    className="mag-form-input"
                    disabledDate={disabledStartDate}
                    format="D/MM/YYYY h:mm A"
                    onChange={handleStartDate}
                    onOpenChange={handleStartOpenChange}
                    placeholder="Start Task On"
                    showNow={false}
                    showTime={{ format: 'h:mm A' }}
                    suffixIcon={<DownOutlined />}
                    value={startDate}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  required
                  label="Finish Task On"
                  htmlFor="endDate"
                  // colon={false}
                  // validateStatus={
                  //   errors.name && touched.name ? 'error' : null
                  // }
                  // help={errors.name && touched.name ? errors.name : null}
                >
                  <DatePicker
                    open={endOpen}
                    className="mag-form-input"
                    disabledDate={disabledEndDate}
                    format="D/MM/YYYY h:mm A"
                    onChange={handleEndDate}
                    onOpenChange={handleEndOpenChange}
                    placeholder="Finish Task On"
                    showNow={false}
                    showTime={{ format: 'h:mm A' }}
                    suffixIcon={<DownOutlined />}
                    value={endDate}
                  />
                </Form.Item>
              </Col>
            </Row>
            {/* <Form.Item
              label="Add Responsible Person"
              htmlFor="title"
              colon={false}
              // validateStatus={
              //   errors.name && touched.name ? 'error' : null
              // }
              // help={errors.name && touched.name ? errors.name : null}
            >
              <Input
                className="mag-form-input"
                name="title"
                placeholder="Add Responsible Person"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
              />
            </Form.Item> */}
            <Form.Item
              required
              label="Add Participants"
              htmlFor="Participants"
              colon={false}
              // validateStatus={
              //   errors.name && touched.name ? 'error' : null
              // }
              // help={errors.name && touched.name ? errors.name : null}
            >
              <Select
                allowClear
                mode="multiple"
                tagRender={tagRender}
                value={taskParticipantIds}
                className="mag-form-select"
                onChange={handleSelectChange}
                style={{ width: '100%' }}
                placeholder="Add Participants"
                loading={employeesLoading}>
                {optionsList}
              </Select>
            </Form.Item>

            <Row gutter={32}>
              <Col span={12}>
                <Form.Item
                  required
                  label="Add Priority"
                  htmlFor="priority"
                  colon={false}
                  // validateStatus={
                  //   errors.name && touched.name ? 'error' : null
                  // }
                  // help={errors.name && touched.name ? errors.name : null}
                >
                  <Select
                    placeholder="Select priority"
                    className="mag-form-select"
                    value={priority}
                    onChange={handlePriorityChange}>
                    <Option value="0">
                      <FlagFilled
                        style={{ color: '#299f9e', margin: '10px' }}
                      />
                      Low Priority
                    </Option>
                    <Option value="1">
                      <FlagFilled
                        style={{ color: '#F07922', margin: '10px' }}
                      />
                      Medium
                    </Option>
                    <Option value="2">
                      <FlagFilled
                        style={{ color: '#D90B2C', margin: '10px' }}
                      />
                      High Priority
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Button
              block
              disabled={loading || isSubmitting}
              loading={loading}
              className="mag-submit-btn mt-4"
              onClick={handleSubmit}>
              Add Task
            </Button>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export default TaskDrawer;
