import adminSettingsConstants from 'lib/constants/adminSettingsConstants';
import adminSettingsAxiosInstance from 'modules/AdminSettings/Network/axios/adminSettings.axios';
import { ModuleApi } from 'modules/AdminSettings/Network/Api/Modules.api';
import { getTenantId } from 'modules/AdminSettings/util/util';
import { urlConstants } from '../../lib/constants/index';

const commonConstants = {
  GLOBAL_LOADING_START: 'GLOBAL_LOADING_START',
  GLOBAL_LOADING_ENDED: 'GLOBAL_LOADING_ENDED',
  FETCH_START: 'FETCH_START',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_ERROR: 'FETCH_ERROR',
  SUCCESSFUL_CODE: '00',
};

const {
  GET_ACCOUNT_POLICY,
  GET_ACCOUNT_POLICY_LOADER,
  GET_MODULES_LOADER,
  GET_MODULES_FAILURE,
  GET_MODULES_SUCCESS,
  GET_WORKFLOW_MODULE_DETAILS,
  SET_ACTIVE_SUB_MENU,
  SET_IS_LOGGED_IN,
  SET_OPEN_SUBMENU_KEYS,
} = adminSettingsConstants;

export const getAccountPolicySettings = () => {
  const reduxState = JSON.parse(localStorage.getItem('greenpole_redux_state'));
  const id = getTenantId();
  return async (dispatch) => {
    dispatch({ type: GET_ACCOUNT_POLICY_LOADER, payload: true });
    try {
      const response = await fetch(
        `${urlConstants.APPLICATIONMANAGEMENT}/tenants/${id}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${reduxState?.auth?.token}`,
            'Content-Type': 'application/json',
          },
        },
      );

      const result = await response.json();

      const accountPolicySettings = {
        accountLockoutDurationInMinutes:
          result?.data?.accountLockoutDurationInMinutes,
        accountLockoutThresholdCount:
          result?.data?.accountLockoutThresholdCount,
        inactivePeriodInMinutes: result?.data?.inactivePeriodInMinutes,
        passwordExpirationInDays: result?.data?.passwordExpirationInDays,
        twoFaEnabled: result?.data?.twoFaEnabled,
      };
      if (result.code === commonConstants.SUCCESSFUL_CODE) {
        dispatch({
          type: GET_ACCOUNT_POLICY,
          payload: accountPolicySettings,
        });
      }

      dispatch({ type: GET_ACCOUNT_POLICY_LOADER, payload: false });
    } catch (e) {
      dispatch({ type: GET_ACCOUNT_POLICY_LOADER, payload: false });
      throw e;
    }
  };
};

export const getApplicationModules = () => {
  return async (dispatch) => {
    dispatch({ type: GET_MODULES_LOADER, payload: true });
    try {
      const response = await adminSettingsAxiosInstance.get(
        `${ModuleApi.viewModules}`,
        {
          'content-type': 'application/json',
        },
      );
      const result = response.data;

      if (result.code === commonConstants.SUCCESSFUL_CODE) {
        dispatch({
          type: GET_MODULES_SUCCESS,
          payload: result?.data,
          message: result?.message,
          isModuleLoaded: true,
        });
      } else {
        dispatch({
          type: GET_MODULES_FAILURE,
          message: result?.message,
          isModuleLoaded: false,
        });
      }

      dispatch({ type: GET_MODULES_LOADER, payload: false });
    } catch (e) {
      dispatch({ type: GET_MODULES_LOADER, payload: false });
      dispatch({
        type: GET_MODULES_FAILURE,
        message: e.response.data.message,
        isModuleLoaded: false,
      });
    }
  };
};

export const getWorkflowModuleDetails = (payload) => {
  return async (dispatch) => {
    dispatch({ type: GET_WORKFLOW_MODULE_DETAILS, payload });
  };
};

export const setActiveSubMenuName = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SET_ACTIVE_SUB_MENU, payload });
  };
};

export const setAuthenticationStatus = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SET_IS_LOGGED_IN, payload });
  };
};

export const setOpenSubMenuKeys = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SET_OPEN_SUBMENU_KEYS, payload });
  };
};
