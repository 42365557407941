const mutualFundConstants = {
  // GET banks
  BANK_LIST_START: 'BANK_LIST_START',
  BANK_LIST_SUCCESS: 'BANK_LIST_SUCCESS',
  BANK_LIST_FAILED: 'BANK_LIST_FAILED',

  // GET MUTUAL FUND LIST
  MUTUAL_FUND_LIST_START: 'MUTUAL_FUND_LIST_START',
  MUTUAL_FUND_LIST_SUCCESS: 'MUTUAL_FUND_LIST_SUCCESS',
  MUTUAL_FUND_LIST_FAILED: 'MUTUAL_FUND_LIST_FAILED',
  CLEAR_MUTUAL_FUND_LIST: 'CLEAR_MUTUAL_FUND_LIST',

  QUERY_RESULT: 'QUERY_RESULT',
  UPDATE_MUTUAL_FUND_HOLDER: 'UPDATE_MUTUAL_FUND_HOLDER',
  CLEAR_MUTUAL_FUND_HOLDER: 'CLEAR_MUTUAL_FUND_HOLDER',

  LOAD_MUTUAL_FUND_TRANSACTIONS: 'LOAD_MUTUAL_FUND_TRANSACTIONS',

  // UPDATE COUNTRIES, STATES, LGA
  STATE_LIST_SUCCESS: 'STATE_LIST_SUCCESS',
  COUNTRY_LIST_SUCCESS: 'COUNTRY_LIST_SUCCESS',
  LGA_LIST_SUCCESS: 'LGA_LIST_SUCCESS',
};

export default mutualFundConstants;
