import React, { useState, useEffect } from 'react';

import moment from 'moment';
import { Row, Col, Divider } from 'antd';
import { getActivityLog } from '../Services/services';
import { appNotification } from 'lib/components';
import DashBoardActivityLog from './Tables/ActivityLogTable';

import './styles/ActivityLog.scss';

const ActivityLog = () => {
  const [limit] = useState(10);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [pageChangeLoader, setPageChangeLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userActivities, setUserActivities] = useState([]);

  const pageChangeHandler = async (_) => {
    setPageChangeLoader(true);
    try {
      const response = await getActivityLog(page, limit);
      if (response.data.code === '00') {
        const data = response?.data?.data?.content;
        const result = userActivities.concat(data);
        setUserActivities(result);
        setPage((prev) => prev + 1);
        result.length <= 1 ? setHasMore(false) : setHasMore(true);
      } else {
        appNotification(
          'An error occurred while fetching client',
          'Error',
          'error',
        );
      }
    } catch (error) {
      appNotification(
        'An error occurred while fetching client',
        'Error',
        'error',
      );
    }

    setPageChangeLoader(false);
  };

  const fetchUserActivities = async () => {
    setIsLoading(true);
    try {
      const response = await getActivityLog(page, limit);
      if (response?.data?.code === '00') {
        let result = response?.data?.data?.content;
        setUserActivities(result);
        result.length <= 1 ? setHasMore(false) : setHasMore(true);
        setPage((prev) => prev + 1);
      } else {
        appNotification(response?.data?.message, 'Error', 'error');
      }
    } catch (error) {
      appNotification(error?.response?.data?.error, 'Error', 'error');
    }
    setIsLoading(false);
  };

  const activityLogColumns = [
    {
      title: 'Description',
      dataIndex: 'activityDescription',
      key: 'activityDescription',
      render: (record, text) => <span>{text.activityDescription}</span>,
      align: 'center',
    },

    {
      title: 'Approval Date',
      dataIndex: 'approved_on',
      key: 'approved_on',
      render: (record, text) => (
        <span>{moment(text.dateCreated).format('lll')}</span>
      ),
      align: 'center',
    },
  ];

  useEffect(() => {
    fetchUserActivities();
  }, []);

  return (
    <Row className="activity-log-section">
      <Col span={24}>
        <h4 className="section-header">Activity Log</h4>
        <div></div>
        <Divider />
        <Row className="activity-log-lists">
          <Col span={24}>
            <DashBoardActivityLog
              columns={activityLogColumns}
              data={userActivities}
              limit={limit}
              hasMore={hasMore}
              pageChangeHandler={pageChangeHandler}
              pageChangeLoader={pageChangeLoader}
              isLoading={isLoading}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ActivityLog;
