const adminSettingsConstants = {
  GET_ACCOUNT_POLICY: 'GET_ACCOUNT_POLICY',
  GET_ACCOUNT_POLICY_LOADER: 'GET_ACCOUNT_POLICY_LOADER',
  GET_MODULES_LOADER: 'GET_MODULES_LOADER',
  GET_MODULES_FAILURE: 'GET_MODULES_FAILURE',
  GET_MODULES_SUCCESS: 'GET_MODULES_SUCCESS',
  GET_WORKFLOW_MODULE_DETAILS: 'GET_WORKFLOW_MODULE_DETAILS',
  SET_ACTIVE_SUB_MENU: 'SET_ACTIVE_SUB_MENU',
  SET_IS_LOGGED_IN: 'SET_IS_LOGGED_IN',
  SET_OPEN_SUBMENU_KEYS: 'SET_OPEN_SUBMENU_KEYS',
};

export default adminSettingsConstants;
