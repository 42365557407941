import React from 'react';
import './Main.scss';

export const Main = ({ bgType, children }) => {
  return (
    <main style={{ overflowY: 'hidden' }} className={`main ${bgType}`}>
      {children}
    </main>
  );
};
