import { axiosInstance } from 'lib/api/axiosInstance';
import { urlConstants } from 'lib/constants/index';
import { handleError } from 'utils/errorHandler';
import clientCompanyConstants from 'lib/constants/clientCompanyConstants';
import {
  getClientCompanyDashboardStats,
  getDepository,
  getExchange,
  getNseSector,
  getSecurityTypes,
  queryClientCompanies,
} from 'modules/Equity/ClientCompany/Network/services';
const reduxState = JSON.parse(localStorage.getItem('greenpole_redux_state'));

export const Types = {
  FETCHING_CLIENTS: 'FETCHING_CLIENTS',
  FETCH_CLIENT_SUCCESS: 'FETCH_CLIENT_SUCCESS',
  FETCH_CLIENT_ERROR: 'FETCH_CLIENT_ERROR',
};

const {
  GET_CLIENT_COMPANY_LOOKUP_LOADER,
  GET_CLIENT_COMPANY_LOOKUP_SUCCESS,
  GET_CLIENT_COMPANY_LOOKUP_FAILURE,
  GET_CLIENT_COMPANY_STATS_LOADER,
  GET_CLIENT_COMPANY_DASHBOARD_STATS_FAILURE,
  GET_CLIENT_COMPANY_DASHBOARD_STATS_SUCCESS,
  GET_CLIENT_COMPANIES_LOADER,
  GET_CLIENT_COMPANIES_SUCCESS,
  GET_CLIENT_COMPANIES_FAILURE,
  GET_DEPOSITORY_LOADER,
  GET_DEPOSITORY_SUCCESS,
  GET_DEPOSITORY_FAILURE,
  GET_SECURITY_TYPE_LOADER,
  GET_SECURITY_TYPE_SUCCESS,
  GET_SECURITY_TYPE_FAILURE,
  GET_EXCHANGE_LOADER,
  GET_EXCHANGE_SUCCESS,
  GET_EXCHANGE_FAILURE,
  GET_NSE_LOADER,
  GET_NSE_SUCCESS,
  GET_NSE_FAILURE,
} = clientCompanyConstants;

export const allClientCompany = () => async () => {
  try {
    const response = await fetch(
      `${urlConstants.CLIENT_BASE_URL}api/v1/client/all`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${reduxState?.auth?.token}`,
        },
      },
    );
    const payload = await response.json();
    return payload || [];
  } catch (e) {
    console.log(e);
    handleError(e);
  }
};

export const allClientCompanies = async () => {
  return await axiosInstance(urlConstants.CLIENT_BASE_URL).get(
    'api/v1/client/all',
  );
};

export const clientCompanyDetails = (id) => async () => {
  try {
    const reduxState = JSON.parse(
      localStorage.getItem('greenpole_redux_state'),
    );
    const response = await fetch(
      `${urlConstants.CLIENT_BASE_URL}api/v1/client/${id}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${reduxState?.auth?.token}`,
        },
      },
    );
    const payload = await response.json();
    return payload;
  } catch (e) {
    throw e;
  }
};

export const fetchDepository = () => {
  return async (dispatch) => {
    dispatch({ type: GET_DEPOSITORY_LOADER, payload: true });
    try {
      const response = await getDepository();
      const newResponse = response?.data?.data?.content?.map((item) => {
        return {
          name: item?.name,
          value: item?.id,
        };
      });
      if (response.data?.status === '00') {
        dispatch({
          type: GET_DEPOSITORY_SUCCESS,
          payload: newResponse,
          isDepositoryLoaded: true,
        });
      } else {
        dispatch({
          type: GET_DEPOSITORY_FAILURE,
          isDepositoryLoaded: false,
        });
      }
    } catch (e) {
      dispatch({
        type: GET_DEPOSITORY_FAILURE,
        isDepositoryLoaded: false,
      });
    }
    dispatch({ type: GET_DEPOSITORY_LOADER, payload: false });
  };
};

export const fetchSecurityType = () => {
  return async (dispatch) => {
    dispatch({ type: GET_SECURITY_TYPE_LOADER, payload: true });
    try {
      const response = await getSecurityTypes();
      const newResponse = response?.data?.content.map((item) => {
        return {
          name: item?.name,
          value: item?.id,
        };
      });
      if (response.status === 200) {
        dispatch({
          type: GET_SECURITY_TYPE_SUCCESS,
          payload: newResponse,
          isSecurityTypeLoaded: true,
        });
      } else {
        dispatch({
          type: GET_SECURITY_TYPE_FAILURE,
          isSecurityTypeLoaded: false,
        });
      }
    } catch (e) {
      dispatch({
        type: GET_SECURITY_TYPE_FAILURE,
        isSecurityTypeLoaded: false,
      });
    }
    dispatch({ type: GET_SECURITY_TYPE_LOADER, payload: false });
  };
};

export const fetchExchange = () => {
  return async (dispatch) => {
    dispatch({ type: GET_EXCHANGE_LOADER, payload: true });
    try {
      const response = await getExchange();
      const newReponse = response?.data?.data?.map((item) => {
        return {
          name: item?.name,
          value: item?.id,
        };
      });
      if (response.status === 200) {
        dispatch({
          type: GET_EXCHANGE_SUCCESS,
          payload: newReponse,
          isExchangeLoaded: true,
        });
      } else {
        dispatch({
          type: GET_EXCHANGE_FAILURE,
          isExchangeLoaded: false,
        });
      }
    } catch (e) {
      dispatch({
        type: GET_EXCHANGE_FAILURE,
        isExchangeLoaded: false,
      });
    }
    dispatch({ type: GET_EXCHANGE_LOADER, payload: false });
  };
};

export const fetchNseSector = () => {
  return async (dispatch) => {
    dispatch({ type: GET_NSE_LOADER, payload: true });
    try {
      const response = await getNseSector();
      const newResponse = response?.data?.data?.content.map((item) => {
        return {
          name: item?.name,
          value: item?.id,
        };
      });
      if (response?.data?.status === '00') {
        dispatch({
          type: GET_NSE_SUCCESS,
          payload: newResponse,
          isNseLoaded: true,
        });
      } else {
        dispatch({
          type: GET_NSE_FAILURE,
          isNseLoaded: false,
        });
      }
    } catch (e) {
      dispatch({
        type: GET_NSE_FAILURE,
        isNseLoaded: false,
      });
    }
    dispatch({ type: GET_NSE_LOADER, payload: false });
  };
};

export const fetchClientCompanyDashboardStats = () => {
  return async (dispatch, getState) => {
    if (
      getState().clientCompanyReducer.stats === null &&
      !getState().clientCompanyReducer.isStatsLoaded
    ) {
      dispatch({ type: GET_CLIENT_COMPANY_STATS_LOADER, payload: true });
    }

    try {
      const response = await getClientCompanyDashboardStats();
      if (response.data?.status === '00') {
        dispatch({
          type: GET_CLIENT_COMPANY_DASHBOARD_STATS_SUCCESS,
          payload: response?.data?.data,
          isStatsLoaded: true,
        });
      } else {
        dispatch({
          type: GET_CLIENT_COMPANY_DASHBOARD_STATS_FAILURE,
          isStatsLoaded: false,
        });
      }
    } catch (e) {
      dispatch({
        type: GET_CLIENT_COMPANY_DASHBOARD_STATS_FAILURE,
        isStatsLoaded: false,
      });
    }
    dispatch({ type: GET_CLIENT_COMPANY_STATS_LOADER, payload: false });
  };
};

export const fetchClientCompanies = () => {
  return async (dispatch, getState) => {
    if (
      getState().clientCompanyReducer.clientCompanies.length < 1 &&
      !getState().clientCompanyReducer.isClientCompaniesLoaded
    ) {
      dispatch({ type: GET_CLIENT_COMPANIES_LOADER, payload: true });
    }

    try {
      const response = await queryClientCompanies();
      if (response.data?.status === '00') {
        dispatch({
          type: GET_CLIENT_COMPANIES_SUCCESS,
          payload: response?.data?.data?.content,
          isClientCompaniesLoaded: true,
        });
      } else {
        dispatch({
          type: GET_CLIENT_COMPANIES_FAILURE,
          isClientCompaniesLoaded: false,
        });
      }
    } catch (e) {
      dispatch({
        type: GET_CLIENT_COMPANIES_FAILURE,
        isClientCompaniesLoaded: false,
      });
    }
    dispatch({ type: GET_CLIENT_COMPANIES_LOADER, payload: false });
  };
};
