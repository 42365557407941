import React from 'react';
import logo from '../../assets/images/greenPole.png';

import './Logo.scss';

export const GreenPoleLogo = () => {
  return (
    <span className="logo">
      {/* <span className="brand-name">GreenPole</span> */}
      <img src={logo} width={150} height={50} />
      <span className="divider" />
      <span className="ap-brand" />
    </span>
  );
};
