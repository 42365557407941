import React from 'react';
import { Modal, Result, Button } from 'antd';
import './SuccessModal.scss';
import successIcon from 'lib/assets/images/success.svg';
import failedIcon from 'lib/assets/images/failed.svg';

export const SuccessModal = ({
  modalVisible,
  modalStatus,
  title,
  subTitle,
  onClick,
  buttonText,
}) => {
  return (
    <Modal
      id="success_modal"
      visible={modalVisible}
      centered
      width={600}
      footer={null}
      cancelButtonProps={{ style: { display: 'none' } }}>
      <Result
        status={modalStatus}
        className="account_creation_modal"
        icon={
          // <div className="create_account_modal_icon">
          <img
            alt="create account success icon"
            src={modalStatus === 'failed' ? failedIcon : successIcon}
          />
          // </div>
        }
        title={<span style={{ fontWeight: 'bolder' }}>{title}</span>}
        subTitle={subTitle}
        extra={
          <Button onClick={onClick} className="create_account_modal_btn">
            {buttonText}
          </Button>
        }
      />
    </Modal>
  );
};

export default SuccessModal;
