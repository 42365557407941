import { userConstants } from '../../lib/constants/userConstants';

const setNewPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case userConstants.SET_NEW_PASSWORD_FAILURE:
      return {
        status: action.status,
        message: action.message,
      };

    case userConstants.SET_NEW_PASSWORD_SUCCESS:
      return {
        status: action.status,
        message: action.message,
      };

    case userConstants.RESET_TO_INITIAL_STATE:
      return {
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

export default setNewPasswordReducer;
