import axios from 'axios';
import { LS } from 'lib';
import { urlConstants } from 'lib/constants';

const adminSettingsAxiosInstance = axios.create({
  baseURL: `${urlConstants.APPLICATIONMANAGEMENT}`,
  /* other custom settings */
});

adminSettingsAxiosInstance.interceptors.request.use(
  function (config) {
    const getToken = LS.get('greenpole_redux_state');
    config.headers.Authorization =
      getToken && getToken.auth ? `Bearer ${getToken.auth.token}` : '';
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export default adminSettingsAxiosInstance;
