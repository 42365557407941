import { StockBrokerConstant } from '../../lib/constants/stockbrokerConstants';

const {
  // FETCH BONDHOLDER
  FETCH_BONDHOLDER,
  FETCH_BONDHOLDER_SUCCESS,
  FETCH_BONDHOLDER_FAILURE,
} = StockBrokerConstant;

const initialState = {
  bondholderLoading: false,
  bondholder: [],
};

function bondholderReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BONDHOLDER:
      return { ...state, bondholderLoading: true };
    case FETCH_BONDHOLDER_SUCCESS:
      return {
        ...state,
        bondholderLoading: false,
        bondholder: action.payload,
      };
    case FETCH_BONDHOLDER_FAILURE:
      return { ...state, bondholderLoading: false };

    default:
      return state;
  }
}

export default bondholderReducer;
