import React from 'react';
import './style.scss';

export const GreenPoleAvatarXL = (props) => {
  const src = props.src;
  const alt = props.alt;

  return <img src={src} alt={alt} className="avatar-xl" />;
};

export const GreenPoleAvatarSM = (props) => {
  const src = props.src;
  const alt = props.alt;

  return <img src={src} alt={alt} className="avatar-sm" />;
};
