import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

export const GreenPoleTabs = (props) => {
  const TabItems = props.TabItems;
  const [activeTab, setActiveTab] = useState('');

  useEffect(() => {
    setActiveTab(props.Active);
  }, [props.Active]);

  return (
    <div className="greenpole-tabs">
      {TabItems.map((item, index) => (
        <div
          key={index}
          className={`tab-item  ${activeTab === item ? 'active-tab' : ''}`}
          onClick={() => {
            setActiveTab(item);
          }}>
          {item}
        </div>
      ))}
    </div>
  );
};

export const GreenPoleCategoryTabs = ({
  children,
  tabItems,
  active,
  getActive,
}) => {
  return (
    <div className="greenpole-category-tabs">
      <ul className="tab-headings">
        {tabItems.map((tab, index) => (
          <li
            className={`tab-item ${tab.title === active ? 'active' : ''}`}
            key={index}
            onClick={() => getActive(tab.title)}>
            {tab?.title}
            {tab?.tag && <span>{tab?.tag}</span>}
          </li>
        ))}
      </ul>
      <div className="tab-body">{children}</div>
    </div>
  );
};

GreenPoleCategoryTabs.propTypes = {
  tabItems: PropTypes.array.isRequired,
  active: PropTypes.string.isRequired,
  getActive: PropTypes.func.isRequired,
};
