import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLoggedIn, isUserRole } from 'utils/token';

export const AdminSettingsRoute = ({
  component: PrivateComponent,
  bgType,
  ...otherProps
}) => {
  return (
    <Route
      {...otherProps}
      render={(props) => {
        return isLoggedIn() && isUserRole() ? (
          <PrivateComponent {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        );
      }}
    />
  );
};
