import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Drawer,
  Row,
  Col,
  Input,
  Select,
  Form,
  DatePicker,
  TimePicker,
  Button,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar } from 'react-modern-calendar-datepicker';
import { useDispatch, useSelector } from 'react-redux';

import { appNotification } from 'lib/components';

const { Option } = Select;

const EventDrawer = (props) => {
  const dispatch = useDispatch();
  const [selectedDay, setSelectedDay] = useState(null);
  const [title, setTitle] = useState('');
  const [isRecurring, setIsRecurring] = useState(null);
  const [recurringInterval, setRecurringInterval] = useState(null);

  const [endTime, setEndTime] = useState(null);
  const [startTime, setStartTime] = useState(null);

  const loading = useSelector((state) => state?.dashboardState?.loading);
  const userState = useSelector((state) => state?.auth?.user);

  function onEndChange(value) {
    const _data = {
      hour: moment(value).hour(),
      minute: moment(value).minute(),
    };
    setEndTime(value);
  }

  function onStartChange(value) {
    const _data = {
      hour: moment(value).hour(),
      minute: moment(value).minute(),
    };
    setStartTime(value);
  }

  function handleSelectChange(value) {
    if (value) {
      setIsRecurring(true);
    }
    setRecurringInterval(value);
  }

  function handleTitleChange(event) {
    setTitle(event.target.value);
  }

  function handleSubmit() {
    if (selectedDay === null || startTime === null || endTime === null) {
      appNotification(
        'One or more fields are empty. Cannot submit',
        'Empty Fields!',
        'error',
      );
      return;
    }

    const _endTime = {
      hour: moment(endTime).hour(),
      minute: moment(endTime).minute(),
    };

    const _startTime = {
      hour: moment(startTime).hour(),
      minute: moment(startTime).minute(),
    };

    const _data = {
      title,
      startDateTime: moment({
        year: selectedDay.year,
        // react-modern-calendar-datepicker months starts at 1 while moment starts at 0
        month: selectedDay.month - 1,
        day: selectedDay.day,
        hour: _startTime.hour,
        minute: _startTime.minute,
      }).unix(),

      endDateTime: moment({
        year: selectedDay.year,
        // react-modern-calendar-datepicker months starts at 1 while moment starts at 0
        month: selectedDay.month - 1,
        day: selectedDay.day,
        hour: _endTime.hour,
        minute: _endTime.minute,
      }).unix(),

      isRecurring: isRecurring,
      recurringInterval: recurringInterval,
      createdBy: userState?.id,
    };

    // dispatch(
    //   createEvent(_data, (e) => {
    //     setSelectedDay(null);
    //     setRecurringInterval(null);
    //     setStartTime(null);
    //     setEndTime(null);
    //     setTitle('');
    //   }),
    // );
  }

  useEffect(() => {}, [isRecurring, recurringInterval, dispatch]);

  return (
    <Drawer
      title={
        null
        // <Link to="/" className="task-header-link">
        //   Event Full View
        // </Link>
      }
      className="task-drawer"
      placement="right"
      closable
      onClose={props.onCloseEventDrawer}
      visible={props.eventDrawerVisible}
      width={626}>
      <Form layout="vertical">
        <h3 className="calendar-title">Create Event</h3>
        <div className="title-warning">
          NOTE: You cannot create events from past dates or hours.
        </div>

        <Row gutter={16}>
          <Col className="task-drawer-column" span={24}>
            <Form.Item>
              <Calendar
                value={selectedDay}
                onChange={setSelectedDay}
                colorPrimary="#299f9e"
                calendarClassName="custom-calendar"
                calendarTodayClassName="custom-today-day"
              />
            </Form.Item>

            <Form.Item required label="Title" htmlFor="title" colon={false}>
              <Input
                className="mag-form-input"
                name="title"
                type="text"
                placeholder="Event Title"
                onChange={handleTitleChange}
                value={title}
              />
            </Form.Item>

            <Row gutter={32}>
              <Col span={12}>
                <Form.Item
                  required
                  label="Start Time"
                  htmlFor="startTime"
                  colon={false}>
                  <TimePicker
                    suffixIcon={<DownOutlined />}
                    placeholder="Start"
                    className="mag-form-input"
                    showTime={{ format: 'h:mm A' }}
                    format="h:mm A"
                    onChange={onStartChange}
                    value={startTime}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item required label="Finish Task On" htmlFor="endDate">
                  <TimePicker
                    suffixIcon={<DownOutlined />}
                    placeholder="Finish"
                    className="mag-form-input"
                    showTime={{ format: 'h:mm A' }}
                    format="h:mm A"
                    onChange={onEndChange}
                    value={endTime}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col span={6}>
                <p className="mag-form-extra-text">Add Repeat:</p>
              </Col>
              <Col span={10}>
                <Form.Item label="&nbsp;" htmlFor="recurringInterval">
                  <Select
                    placeholder="Recurring Interval"
                    className="mag-form-select"
                    value={recurringInterval}
                    onChange={handleSelectChange}>
                    <Option value={0}>Daily</Option>
                    <Option value={1}>Weekly</Option>
                    <Option value={2}>Monthly</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Button
              block
              disabled={loading}
              loading={loading}
              className="mag-submit-btn mt-4"
              onClick={handleSubmit}>
              Add Event
            </Button>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export default EventDrawer;
