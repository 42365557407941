const shareholderConstants = {
  // GET CLIENT COMPANY
  CLIENT_COMPANY_LIST_START: 'CLIENT_COMPANY_LIST_START',
  CLIENT_COMPANY_LIST_SUCCESS: 'CLIENT_COMPANY_LIST_SUCCESS',
  CLIENT_COMPANY_LIST_FAILED: 'CLIENT_COMPANY_LIST_FAILED',

  // GET SHAREHOLDER TYPE LIST
  SHAREHOLDER_TYPE_LIST_START: 'SHAREHOLDER_TYPE_LIST_START',
  SHAREHOLDER_TYPE_LIST_SUCCESS: 'SHAREHOLDER_TYPE_LIST_SUCCESS',
  SHAREHOLDER_TYPE_LIST_FAILED: 'SHAREHOLDER_TYPE_LIST_FAILED',

  // GET BANKS
  BANK_LIST_START: 'BANK_LIST_START',
  BANK_LIST_SUCCESS: 'BANK_LIST_SUCCESS',
  BANK_LIST_FAILED: 'BANK_LIST_FAILED',

  //  FETCH SHAREHOLDER
  FETCH_SHAREHOLDER: 'FETCH_SHAREHOLDER',
  FETCH_SHAREHOLDER_SUCCESS: 'FETCH_SHAREHOLDER_SUCCESS',
  FETCH_SHAREHOLDER_FAILURE: ' FETCH_SHAREHOLDER_FAILURE',
  GET_HOLDER_RECORD: 'GET_HOLDER_RECORD',
  GET_MERGE_REQUESTS_RECORD: 'GET_MERGE_REQUESTS_RECORD',
};

export default shareholderConstants;
