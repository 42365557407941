import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { GreenPoleAvatarXL, Icon } from 'lib/components';
import { userConstants } from 'lib/constants';
import offerUrl from 'modules/Equity/Offer/utils/urlConsts';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { setAuthenticationStatus } from 'redux/actions/adminSettingsActions';
import { isUserRole } from 'utils/token';
import userRoles from 'utils/userRoles';
import './MobileSidebar.scss';

const MobileSidebar = ({ user, setMobileSideOpen }) => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [modulesMenuActive, toggleModulesMenuActive] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const currentRoute = () => {
    // setActiveMenu(location.pathname.substring(1));
    setActiveMenu(location.pathname.split('/')[1]);
  };

  const toggleModulesMenu = (e) => {
    e.preventDefault();
    toggleModulesMenuActive(!modulesMenuActive);
  };

  const hideModulesMenu = () => {
    toggleModulesMenuActive(false);
  };

  const logout = () => {
    localStorage.removeItem('greenpole_redux_state');
    dispatch(setAuthenticationStatus(false));
    history.push('/');
  };

  useEffect(() => {
    currentRoute();
  }, []);

  return (
    <aside className="mobileSidebar">
      <div onClick={() => setMobileSideOpen(false)} className="close_icon">
        &times;
      </div>
      <ul className="sidebar-nav">
        <li className="nav-item">
          <Link
            to="/home"
            className={`${activeMenu === 'home' ? 'active' : ''}`}>
            <span className="nav-body">
              <span className="link-icon">
                <Icon name="Dashboard" />
              </span>
              <span className="link-text">Dashboard</span>
            </span>
          </Link>
        </li>
        <li className="nav-item">
          <a
            href=""
            className={`${
              activeMenu !== 'dashboard' &&
              activeMenu !== 'admin-settings' &&
              activeMenu !== 'management'
                ? 'active'
                : ''
            }`}
            onClick={(e) => toggleModulesMenu(e)}>
            <span className="nav-body">
              <span className="link-icon">
                <Icon name="List" />
              </span>
              <span className="link-text">Modules</span>
              <span className="dropdown-icon">
                {modulesMenuActive ? <UpOutlined /> : <DownOutlined />}
              </span>
            </span>
          </a>

          {modulesMenuActive && (
            <div className="modules-menu" onBlur={() => hideModulesMenu()}>
              {(isUserRole(userRoles.MUTUAL_FUND_ADMIN) ||
                isUserRole(userRoles.SUPER_ADMIN)) && (
                <Link to="/modules/mutualfund/view-mutual-fund">
                  Mutual Fund
                </Link>
              )}
              {(isUserRole(userRoles.DIVIDEND_ADMIN) ||
                isUserRole(userRoles.CLIENT_COMPANY_ADMIN) ||
                isUserRole(userRoles.HOLDER_ADMIN) ||
                isUserRole(userRoles.CERTIFICATE_ADMIN) ||
                isUserRole(userRoles.TRANSACTION_ADMIN) ||
                isUserRole(userRoles.SUPER_ADMIN)) && (
                <Link to="/modules/Equity">Equity</Link>
              )}
              {(isUserRole(userRoles.OFFER_ADMIN) ||
                isUserRole(userRoles.SUPER_ADMIN)) &&
                process.env.REACT_APP_OFFER_MODULE_DISPLAY && (
                  <Link to={`/modules/Equity/${offerUrl.OFFER_TYPE}`}>
                    Offer
                  </Link>
                )}
              <Link to="/modules/management">Management</Link>
              {isUserRole(userRoles.SUPER_ADMIN) && (
                <Link to="/modules/admin-settings/home">Admin Settings</Link>
              )}
            </div>
          )}
        </li>
        {/* <li className="nav-item">
          <Link
            to="/management"
            className={`${activeMenu === 'management' ? 'active' : ''}`}>
            <span className="nav-body">
              <span className="link-icon">
                <Icon name="User" />
              </span>
              <span className="link-text">Management</span>
            </span>
          </Link>
        </li>
        {isUserRole() && (
          <li className="nav-item">
            <Link
              to="/modules/admin-settings/home"
              className={`${activeMenu === 'admin-settings' ? 'active' : ''}`}>
              <span className="nav-body">
                <span className="link-icon">
                  <Icon name="User" />
                </span>
                <span className="link-text">Admin Settings</span>
              </span>
            </Link>
          </li>
        )} */}

        <li className="nav-item">
          <Link to="/">
            <GreenPoleAvatarXL
              src={user && user.pic}
              size="sm"
              alt={user && user.name}
            />
            <span className="link-text" onClick={() => logout()}>
              Logout
            </span>
          </Link>
        </li>
      </ul>
    </aside>
  );
};

export default MobileSidebar;
