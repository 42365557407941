import { Types } from '../actions/setup-dividend';

const INITIAL_STATE = {
  loading: false,
  errorMsg: null,
};

export default function setupDividendReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    default:
      return state;
  }
}
