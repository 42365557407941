import equityConstants from 'lib/constants/equityConstants';

const { SET_OPEN_SUBMENU_KEYS } = equityConstants;

const initialState = {
  openSubmenuKeys: [],
};

function equityReducer(state = initialState, action) {
  switch (action.type) {
    case SET_OPEN_SUBMENU_KEYS:
      return {
        ...state,
        openSubmenuKeys: action.payload,
      };
    default:
      return state;
  }
}

export default equityReducer;
