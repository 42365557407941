import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'antd';
import SummaryCard from 'lib/components/SummaryCard/SummaryCard';
import BuildingIcon from 'lib/assets/images/build.svg';
import UserIcon from 'lib/assets/images/user.svg';
import TrendIcon from 'lib/assets/images/trending.svg';
import { fetchClientCompanyDashboardStats } from 'redux/actions/clientCompanyActions';

const ClientCompanySummary = () => {
  const dispatch = useDispatch();

  const isStatsLoading = useSelector(
    (state) => state.clientCompanyReducer?.dashboardStatsLoader,
  );
  const stateObject = useSelector((state) => state.clientCompanyReducer?.stats);

  useEffect(() => {
    dispatch(fetchClientCompanyDashboardStats());
  }, []);

  return (
    <Row gutter={[16, 16]} style={{ marginTop: '1rem' }}>
      <Col span={8}>
        <SummaryCard
          units={stateObject?.totalClientCompanies}
          description="Total client companies"
          imgSrc={BuildingIcon}
          imgAlt="total client companies"
          loader={isStatsLoading}
        />
      </Col>
      <Col span={8}>
        <SummaryCard
          units={stateObject?.totalShareHolders}
          description="Total holders"
          imgSrc={UserIcon}
          imgAlt="total holders"
          loader={isStatsLoading}
        />
      </Col>
      <Col span={8}>
        <SummaryCard
          units={stateObject?.totalPaidUpShareCapital}
          description="Total Combined Share Capital"
          imgSrc={TrendIcon}
          imgAlt="total combined share capital"
          loader={isStatsLoading}
        />
      </Col>
    </Row>
  );
};

export default ClientCompanySummary;
