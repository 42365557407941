export const ModuleApi = {
  viewModules: '/modules',
  viewActions: '/actions',
  deleteActions: '/actions',
  createAction: '/actions',
  createModule: '/modules',
  getUsersWithAction: '/actions',
  createUserAction: '/actions/users',
  deleteActionUser: '/actions/users',
};
