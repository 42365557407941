import Config from 'config/Config';
const {
  baseURLWithPort,
  baseURLNoPort,
  frontendUrl,
  clientBaseUrl,
  stockbrokerBaseUrl,
  shareHolderBaseUrl,
  bondHolderBaseUrl,
  holderBaseUrl,
  ipoBaseUrl,
  mutualFundBaseUrl,
  mutualFundBaseURL,
  stockBrokerReportUrl,
  transactionsBaseUrl,
  agentUrl,
  ReconciliationReportUrl,
  certificateBaseUrl,
  dashboardBaseUrl,
  departmentUrl,
  bondBaseUrl,
  shareBonusBaseUrl,
  transactionUrl,
  paymentAndDividendBaseUrl,
  rightsIssueBaseUrl,
  privatePlacementBaseUrl,
  reportBaseUrl,
  rightsIssueBaseURl,
  managementBaseUrl,
  userBaseUrl,
  passportUrl,
  client_id,
  client_secret,
  imageBaseUrl,
  equityBaseUrl,
  socketBaseUrl,
  storageUrl,
} = Config;

export const urlConstants = {
  DEFAULT_BASE_URL: baseURLWithPort,
  BOND_BASE_URL: `${bondBaseUrl}`,
  IPOURL: `${ipoBaseUrl}`,
  USER_MGT_BASE_URL: `${baseURLWithPort}/user/login`,
  STOCKBROKER_REPORTS_BASE_URL: `${baseURLNoPort}:30021`,
  // DASHBOARD_BASE_URL: `${baseURLNoPort}:30024`,
  DASHBOARD_BASE_URL: `${dashboardBaseUrl}/api/v2/`,
  REMINDER_BASE_URL: `${dashboardBaseUrl}/api/v2/`,
  // DASHBOARD_BASE_URL: `${BASE_URL}30024`,
  MANAGEMENT_BASE_URL: `${managementBaseUrl}/api/v1/`,
  USER_MANAGEMENT_URL: `${managementBaseUrl}`,
  MANAGEMENT_URL: `${managementBaseUrl}`,
  // MANAGEMENT_BASE_URL: `${BASE_URL}30025`,
  VIEW_PROFILE: `${baseURLWithPort}/user/profile`,
  UPDATE_PROFILE: `${baseURLWithPort}/user/profile`,
  BASE_URL_WITH_NO_PORT: baseURLNoPort,
  BASEURL: `${baseURLWithPort}/`,
  APPLICATIONMANAGEMENT: `${baseURLWithPort}/api/v2`,
  DEPARTMENTURL: `${departmentUrl}/api/v2`,
  FRONTENDURL: `${frontendUrl}/`,
  BONDHOLDER_BASE_URL: `${bondHolderBaseUrl}/`,
  STOCKBROKER_REPORT_BASE_URL: `${stockBrokerReportUrl}/`,
  RECONCILIATION_BASE_URL: `${ReconciliationReportUrl}/`,
  CLIENT_BASE_URL: `${clientBaseUrl}/`,
  STOCKBROKER_BASE_URL: `${stockbrokerBaseUrl}/`,
  SHAREHOLDER_BASE_URL: `${shareHolderBaseUrl}/`,
  TRANSACTIONS_BASE_URL: `${transactionsBaseUrl}/`,
  CERTIFICATE_BASE_URL: `${certificateBaseUrl}/`,
  TRANSACTION_URL: `${transactionUrl}/`,
  LOGIN: `${baseURLWithPort}/user/login`,
  HOLDER_URL: `${holderBaseUrl}/api/v1/`,
  LOGIN_FIRST_TIME_REDIRECT: '/password-reset',
  LOGIN_SUBSEQUENT_REDIRECTS: '/modules/home',
  // SET_NEW_PASSWORD: `${baseURLWithPort}user/change_password`,
  CHANGE_PASSWORD: `${baseURLWithPort}/api/v2/users/password/reset`,
  MUTUALFUND_BASE_URL: `${mutualFundBaseUrl}/`,
  MUTUAL_FUND_BASE_URL: `${mutualFundBaseURL}/`,
  SET_NEW_PASSWORD: `${baseURLWithPort}/api/v2/users/password/change`,
  RENEW_PASSWORD: `${baseURLWithPort}/api/v2/users/password/renew`,
  AGENT_BASE_URL: `${equityBaseUrl}/api/v2`,
  FETCH_CLIENT_COMPANIES: `${clientBaseUrl}/api/v1/client/all`,
  PAYMENT_AND_DIVIDEND_BASE_URL: `${equityBaseUrl}/`,
  IPO_BASE_URL: `${ipoBaseUrl}/`,
  RIGHTS_ISSUE_BASE_URL: `${rightsIssueBaseUrl}/`,
  SHAREBONUS_BASE_URL: `${shareBonusBaseUrl}/`,
  PRIVATE_PLACEMENT_BASE_URL: `${privatePlacementBaseUrl}/`,
  REPORTS_BASE_URL: `${reportBaseUrl}/`,
  SHARE_BONUS_BASE_URL: `${shareBonusBaseUrl}/api/v1/sharebonus/`,
  EQUITY_BASE_URL: `${equityBaseUrl}/api/v2`,
  RIGHT_ISSUE: `${rightsIssueBaseURl}/`,
  USER_DATA_BASE_URL: `${userBaseUrl}`,
  PASSPORT_BASE_URL: `${passportUrl}`,
  CLIENT_ID: `${client_id}`,
  CLIENT_SECRET: `${client_secret}`,
  IMAGE_BASE_URL: `${storageUrl}`,
  SOCKET_BASE_URL: `${socketBaseUrl}`,
};
