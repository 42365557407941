import React from 'react';

import { Tooltip } from 'antd';

import HighPriority from '../../../../lib/assets/icons/highPriorityFlag.svg';
import MediumPriority from '../../../../lib/assets/icons/mediumPriorityFlag.svg';
import LowPriority from '../../../../lib/assets/icons/lowPriorityFlag.svg';

import classes from './Legend.module.css';

const LegendItem = ({ item, value }) => {
  const countObject = {
    notificationCount: 0,
    highPriorityCount: 0,
    mediumPriorityCount: 0,
    lowPriorityCount: 0,
  };

  const checkIfValueExist = (arrayInQuestion) => {
    if (
      arrayInQuestion === null ||
      arrayInQuestion?.length === 0 ||
      arrayInQuestion === undefined
    ) {
      return countObject;
    }

    return arrayInQuestion[0];
  };

  return (
    <div className={classes.LegendItemContainer}>
      <Tooltip
        placement="bottom"
        // title={<LegendItemToolTip notification={checkIfValueExist(value)} />}
        color="#ffffff">
        <span className={classes.LegendDot} />
        <span className={classes.LegendItem}>
          {item} ({value})
        </span>
      </Tooltip>
    </div>
  );
};

const LegendItemToolTip = ({ notification }) => {
  const {
    highPriorityCount,
    mediumPriorityCount,
    lowPriorityCount,
  } = notification;
  return (
    <div>
      <div className={classes.PriorityRow}>
        <span>
          <img src={HighPriority} height={10} width={10} />
        </span>
        <span className={classes.Priority}>
          {highPriorityCount} High Priority
        </span>
      </div>

      <div className={classes.PriorityRow}>
        <span>
          <img src={MediumPriority} height={10} width={10} />
        </span>
        <span className={classes.Priority}>
          {mediumPriorityCount} Medium Priority
        </span>
      </div>

      <div className={classes.PriorityRow}>
        <span>
          <img src={LowPriority} height={10} width={10} />
        </span>
        <span className={classes.Priority}>
          {lowPriorityCount} Low Priority
        </span>
      </div>
    </div>
  );
};

export default LegendItem;
