import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { Row, Col, Divider, Menu, Dropdown, Button, message, Spin } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import Chart from 'react-apexcharts';
import './styles/Notifications.scss';

const NotificationAreaGraph = ({
  graphData,
  handleMenuClick,
  selectedRangeName,
}) => {
  const [chartOptions, setChartOptions] = useState({
    options: {
      chart: {
        type: 'areaspline',
      },
      xaxis: {
        categories: [],
        lines: {
          show: false,
        },
        title: {
          text: 'Date',
        },
      },
      yaxis: {
        title: {
          text: 'Notifications',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 90, 100],
        },
        colors: ['#4DAFAE'],
      },
      colors: ['#4DAFAE'],
      legend: {
        show: false,
      },
    },
    series: [
      {
        name: 'series-1',
        data: [],
      },
    ],
  });

  const loader = useSelector((state) => state?.dashboardState?.graphLoader);

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="14">Last 2 Weeks</Menu.Item>
      <Menu.Item key="30">Past Month</Menu.Item>
      <Menu.Item key="7">This Week</Menu.Item>
    </Menu>
  );

  const extractGraphData = (data) => {
    const dates = [];
    const notifications = [];
    if (data.length > 0) {
      for (let index = 0; index < data.length; index++) {
        dates.push(data[index].dateCreated);
        notifications.push(data[index].count);
      }
      return { dates, notifications };
    } else {
      return { dates, notifications };
    }
  };

  useEffect(() => {
    const { dates, notifications } = extractGraphData(graphData);
    setChartOptions((prevState) => {
      return {
        ...prevState,
        options: {
          ...prevState.options,
          xaxis: {
            categories: dates,
            lines: {
              show: false,
            },
          },
        },
        series: [{ data: notifications }],
      };
    });
  }, [graphData]);

  return (
    <Row className="notification-chart-section">
      <Col span={24}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingRight: '2em',
          }}>
          <h4 className="section-header">Notifications</h4>
          <Dropdown overlay={menu}>
            <Button>
              {selectedRangeName} <DownOutlined />
            </Button>
          </Dropdown>
        </div>

        <Divider />
        <div>
          {loader ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Spin />
            </div>
          ) : (
            <Chart
              type="area"
              options={chartOptions.options}
              series={chartOptions.series}
              height="300px"
            />
          )}
        </div>
      </Col>
    </Row>
  );
};

export default NotificationAreaGraph;
