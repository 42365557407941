import { userConstants } from '../../lib/constants/userConstants';

const authenticationReducer = (state = {}, action) => {
  switch (action.type) {
    case userConstants.LOGIN_FAILURE:
      return {
        status: action.status,
        message: action.message,
      };

    case userConstants.LOGIN_SUCCESS:
      return {
        status: action.status,
        firstTimeLogin: action.firstTimeLogin,
        token: action.token,
        user: action.user,
        message: action.message,
      };

    case userConstants.TWO_FA_AUTHENTICATION:
      return {
        status: action.status,
        message: action.message,
        is2Fa: action.is2Fa,
        pinMessage: action.pinMesage,
      };
    case userConstants.LOGOUT:
      return {};

    default:
      return state;
  }
};

export default authenticationReducer;
