import jwtDecode from 'jwt-decode';

export const decodeToken = () => {
  const token = JSON.parse(localStorage.getItem('greenpole_redux_state'))?.auth
    ?.token;

  if (token) {
    const user = jwtDecode(token);

    return user;
  }
};

export const isUserRole = (roleInQuestion = 'super_admin') => {
  const user = decodeToken();

  if (user?.roles) {
    return user?.roles.includes(roleInQuestion);
  } else {
    return false;
  }
};

export const isLoggedIn = () => {
  let loggedIn = false;
  const reduxState = JSON.parse(localStorage.getItem('greenpole_redux_state'));
  if (reduxState?.auth?.token) {
    const { exp } = decodeToken();
    const currentTime = Date.now().valueOf() / 1000;
    if (currentTime > exp) {
      return loggedIn;
    }

    loggedIn = true;
    return loggedIn;
  }

  return loggedIn;
};
