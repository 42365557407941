import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { Row, Col, Card, Empty } from 'antd';
import { Loader } from 'lib/components';
import { NotificationCard } from './index';
import NotificationBanner from 'lib/assets/images/notificationbanner.svg';
import './styles/RecentNotifications.scss';

const recentNotifs = [
  {
    action_required: 'Declare Dividend for UBA',
    created_on: new Date(),
    first_name: 'Sunny',
    last_name: 'Pepple',
    module: 'dividend',
  },
  {
    action_required: 'Declare Dividend for UBA',
    created_on: new Date(),
    first_name: 'Sunny',
    last_name: 'Pepple',
    module: 'dividend',
  },
];

export const RecentNotifications = () => {
  const recentNotifications = useSelector(
    (state) => state?.dashboardState?.lastNotifications,
  );
  return (
    <Row className="recent-notifications">
      <Col span={24}>
        <Card className="notification-banner" bordered={false}>
          <img
            className="notif-image"
            src={NotificationBanner}
            alt="notification banner"
          />
        </Card>
      </Col>
      <Col span={24}>
        <div className="notif-top">
          <span className="title">
            <p>Most recent Notification</p>
          </span>
          <span className="view-all-link">
            <Link to="/notifications">View all</Link>
          </span>
        </div>
        <div className="notif-lists">
          {recentNotifications?.length === 0 && (
            <Empty description="No recent Notifications" />
          )}
          {recentNotifications?.length > 0 &&
            recentNotifications.map((notification, index) => (
              <NotificationCard key={index} notification={notification} />
            ))}
        </div>
      </Col>
    </Row>
  );
};

export default RecentNotifications;
