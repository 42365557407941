export const STORE_QUALIFIED_HOLDER = 'STORE_QUALIFIED_HOLDER';
export const STORE_SHARES_INFORMATION = 'STORE_SHARES_INFORMATION';

export const storeQualifiedHolder = (holder) => ({
  type: STORE_QUALIFIED_HOLDER,
  holder,
});

export const storeSharesInfo = (shares) => ({
  type: STORE_SHARES_INFORMATION,
  shares,
});
