import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { Row, Col, Divider, Empty } from 'antd';
import Chart from 'react-apexcharts';
import LegendItem from './Legend/LegendItems';
import DashboardUtils from '../Utils';
import { Link } from 'react-router-dom';

import './styles/NotificationsSummary.scss';

const NotificatioSummaryDonutChart = () => {
  const notificationsByModule = useSelector(
    (state) => state?.dashboardState?.notificationsByModule,
  );

  const notificationsCountLoader = useSelector(
    (state) => state?.dashboardState?.notificationsCountLoading,
  );

  const [chartOptions, setChartOptions] = useState({
    options: {
      chart: {
        type: 'donut',
        width: 100,
        height: 100,
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          donut: {
            size: '60%',
            labels: {
              show: true,
              name: {
                show: true,
              },
              value: {
                show: true,
                fontSize: '20px',
              },
              total: {
                show: true,
                // label: 'Notifications',
                fontSize: '10px',
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0);
                },
              },
            },
          },
        },
      },
      legend: {
        show: false,
      },
    },
    series: [],
  });

  const extractGraphData = (data) => {
    const seriesData = [];
    const seriesLabel = [];
    for (let index = 0; index < data?.length; index++) {
      seriesData.push(notificationsByModule[index].count);
      seriesLabel.push(notificationsByModule[index].name);
    }
    return { seriesData, seriesLabel };
  };

  useEffect(() => {
    const { seriesData, seriesLabel } = extractGraphData(notificationsByModule);
    setChartOptions((prevState) => {
      return {
        ...prevState,
        series: seriesData,
      };
    });
  }, [notificationsByModule]);

  return (
    <Row className="notification-chart-section">
      <Col span={24}>
        <h4 className="section-header">Notifications Summary</h4>
        <Divider />

        {notificationsByModule.length < 1 && (
          <div className="notification-empty-container">
            <Empty />
          </div>
        )}

        <Row gutter={[16, 16]}>
          {notificationsByModule.length > 0 && (
            <Col span={6}>
              <Chart
                type="donut"
                options={chartOptions.options}
                series={chartOptions.series}
                labels={chartOptions.labels}
              />
            </Col>
          )}

          {notificationsByModule.length > 0 && (
            <Col span={18}>
              <Row style={{ marginTop: '0.5em' }}>
                {DashboardUtils.formatNotificationByModuleItems(
                  notificationsByModule,
                ).map((item, index) => (
                  <Col key={index} span={6}>
                    <Link to={item?.link}>
                      <LegendItem item={item.name} value={item.count} />
                    </Link>
                  </Col>
                ))}
              </Row>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default NotificatioSummaryDonutChart;
