import { StockBrokerConstant } from '../../lib/constants/stockbrokerConstants';
import shareholderConstants from 'lib/constants/shareholderConstants';

const {
  //  FETCH SHAREHOLDER
  FETCH_SHAREHOLDER,
  FETCH_SHAREHOLDER_SUCCESS,
  FETCH_SHAREHOLDER_FAILURE,
} = StockBrokerConstant;

const { GET_HOLDER_RECORD, GET_MERGE_REQUESTS_RECORD } = shareholderConstants;

const initialState = {
  shareholderLoading: false,
  shareholder: [],
  clientCompanyList: [],
  shareholderType: [],
  singleShareHolder: null,
  singleMergeRequest: null,
};

function shareholderReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SHAREHOLDER:
      return { ...state, shareholderLoading: true };
    case FETCH_SHAREHOLDER_SUCCESS:
      return {
        ...state,
        shareholderLoading: false,
        shareholder: action.payload,
      };
    case FETCH_SHAREHOLDER_FAILURE:
      return { ...state, shareholderLoading: false };
    case 'CLIENT_COMPANY_LIST_SUCCESS':
      return {
        ...state,
        clientCompanyList: action?.payload,
      };
    case 'HOLDER_ADMIN_CLIENT_COMPANY':
      return {
        ...state,
        registrarAccounts: action?.payload,
      };
    case 'SHAREHOLDER_TYPE_LIST_SUCCESS':
      return {
        ...state,
        shareholderType: action?.payload,
      };
    case GET_HOLDER_RECORD:
      return {
        ...state,
        singleShareHolder: action.payload,
      };
    case GET_MERGE_REQUESTS_RECORD:
      return {
        ...state,
        singleMergeRequest: action.payload,
      };
    default:
      return state;
  }
}

export default shareholderReducer;
