import clientCompanyConstants from 'lib/constants/clientCompanyConstants';

const {
  GET_CLIENT_COMPANY_DASHBOARD_STATS_SUCCESS,
  GET_CLIENT_COMPANY_STATS_LOADER,
  GET_CLIENT_COMPANY_DASHBOARD_STATS_FAILURE,
  GET_CLIENT_COMPANIES_LOADER,
  GET_CLIENT_COMPANIES_SUCCESS,
  GET_CLIENT_COMPANIES_FAILURE,
  GET_DEPOSITORY_LOADER,
  GET_DEPOSITORY_SUCCESS,
  GET_DEPOSITORY_FAILURE,
  GET_SECURITY_TYPE_LOADER,
  GET_SECURITY_TYPE_SUCCESS,
  GET_NSE_LOADER,
  GET_NSE_SUCCESS,
  GET_NSE_FAILURE,
  GET_EXCHANGE_SUCCESS,
  GET_EXCHANGE_LOADER,
  GET_EXCHANGE_FAILURE,
} = clientCompanyConstants;

const INITIAL_STATE = {
  uploading: false,
  stats: null,
  isStatsLoaded: false,
  dashboardStatsLoader: false,
  clientCompanies: [],
  isClientCompaniesLoaded: false,
  isClientCompaniesLoading: false,
  isDepositoryLoading: false,
  isSecurityTypeLoading: false,
  isNseLoading: false,
  isExchangeLoading: false,
  securityTypeList: [],
  depositoryList: [],
  nseList: [],
  exchangeList: [],
};

const clientCompanyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //depostory
    case GET_DEPOSITORY_LOADER:
      return {
        ...state,
        isDepositoryLoading: action.payload,
      };
    case GET_DEPOSITORY_SUCCESS:
      return {
        ...state,
        depositoryList: action.payload,
        isDepositoryLoaded: action.isDepositoryLoaded,
      };
    case GET_DEPOSITORY_FAILURE:
      return {
        ...state,
        isDepositoryLoaded: action.isDepositoryLoaded,
      };

    //securityTypes
    case GET_SECURITY_TYPE_LOADER:
      return {
        ...state,
        isSecurityTypeLoading: action.payload,
      };
    case GET_SECURITY_TYPE_SUCCESS:
      return {
        ...state,
        securityTypeList: action.payload,
        isSecurityTypeLoaded: action.isSecurityTypeLoaded,
      };
    case GET_DEPOSITORY_FAILURE:
      return {
        ...state,
        isSecurityTypeLoaded: action.isSecurityTypeLoaded,
      };

    //nseSector
    case GET_NSE_LOADER:
      return {
        ...state,
        isNseLoading: action.payload,
      };
    case GET_NSE_SUCCESS:
      return {
        ...state,
        nseList: action.payload,
        isNseLoaded: action.isNseLoaded,
      };
    case GET_NSE_FAILURE:
      return {
        ...state,
        isNseLoaded: action.isNseLoaded,
      };

    //Exchange
    case GET_EXCHANGE_LOADER:
      return {
        ...state,
        isExchangeLoading: action.payload,
      };
    case GET_EXCHANGE_SUCCESS:
      return {
        ...state,
        exchangeList: action.payload,
        isExchangeLoaded: action.isExchangeLoaded,
      };
    case GET_EXCHANGE_FAILURE:
      return {
        ...state,
        isExchangeLoaded: action.isExchangeLoaded,
      };

    case GET_CLIENT_COMPANY_DASHBOARD_STATS_SUCCESS:
      return {
        ...state,
        stats: action.payload,
        isStatsLoaded: action.isStatsLoaded,
      };
    case GET_CLIENT_COMPANY_STATS_LOADER:
      return {
        ...state,
        dashboardStatsLoader: action.payload,
      };
    case GET_CLIENT_COMPANY_DASHBOARD_STATS_FAILURE:
      return {
        ...state,
        isStatsLoaded: action.isStatsLoaded,
      };
    case GET_CLIENT_COMPANIES_LOADER:
      return {
        ...state,
        isClientCompaniesLoading: action.payload,
      };
    case GET_CLIENT_COMPANIES_FAILURE:
      return {
        ...state,
        isClientCompaniesLoaded: action.isClientCompaniesLoaded,
      };
    case GET_CLIENT_COMPANIES_SUCCESS:
      return {
        ...state,
        isClientCompaniesLoaded: action.isClientCompaniesLoaded,
        clientCompanies: action.payload,
      };
    default:
      return state;
  }
};

export default clientCompanyReducer;
