const initialState = {
  loading: false,
  tableData: [],
  bondtableData: [],
  bondRegisterTableData: [],
  setupBondTrancheTableData: [],
  bondTypeTableData: [],
  error: '',
  amortizationSchedule: '',
  applyForBondTranche: '',
  bondHolderId: '',
};

const bondReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING':
      return {
        ...state,
        loading: true,
      };

    case 'STOPLOADING':
      return {
        ...state,
        loading: false,
      };

    case 'QUERY_BOND_TRANCHE':
      return {
        ...state,
        loading: false,
        tableData: action.payload,
      };

    case 'QUERY_BOND_REGISTER':
      return {
        ...state,
        loading: false,
        bondRegisterTableData: action.payload,
      };

    case 'SETUP_BOND_TRANCHE':
      return {
        ...state,
        loading: false,
        setupBondTrancheTableData: action.payload,
      };

    case 'VIEW_BOND_TYPE':
      return {
        ...state,
        loading: false,
        bondTypeTableData: action.payload,
      };

    case 'ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case 'VIEW_AMORTIZATION':
      return {
        ...state,
        amortizationSchedule: action.payload,
      };

    case 'QUERY_BOND_HOLDERLIST':
      return {
        ...state,
        loading: false,
        bondTableData: action.payload,
      };

    case 'APPLY_FOR_BOND_TRANCHE':
      return {
        ...state,
        applyForBondTranche: action.payload,
      };

    case 'BOND_HOLDER_ID':
      return {
        ...state,
        bondHolderId: action.payload,
      };

    default:
      return state;
  }
};

export default bondReducer;
