export const formatter = new Intl.NumberFormat('en-US', {
  // style: 'currency',
  // currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const readableAmount = (num) => {
  if (num > 9_999_999 && num < 1_000_000_000) {
    return (num / 1_000_000).toFixed(1) + 'M';
  } else if (num > 999_999_999 && num < 1_000_000_000_000) {
    return (num / 1_000_000_000).toFixed(1) + 'B';
  } else if (num > 999_999_999_999 && num < 1_000_000_000_000_000) {
    return (num / 1_000_000_000_000).toFixed(1) + 'T';
  } else {
    return num;
  }
};

export const formatUnit = (unit = 0) => unit?.toLocaleString('en-US');
