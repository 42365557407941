const offerUrl = {
  MANAGE_OFFER: 'manage-offer',
  CREATE_OFFER: 'create-offer',
  CREATE_RIGHT_OFFER: 'create-right-issue',
  VIEW_IPO_SUBSCRIBERS: 'view-ipo-subscribers',
  VIEW_RIGHT_SUBSCRIBERS: 'view-right-subscribers',
  OFFER_TYPE: 'offer-type',
  EDIT_OFFER: 'edit-offer',
  EDIT_RIGHTS_OFFER: 'edit-rights-offer',
  RETURN_MONEY: 'return-money',
  MANUAL_APPLICATIONS: 'manual-applications',
  SINGLE_MANUAL_APPLICATION: 'single-manual-application',
  EXISTING_MANUAL_APPLICATION: 'existing-manual-application',
  NOTIFICATIONS: 'offer-notifications',
  NOTIFICATION_DETAILS: 'notification-details',
  OFFER_DETAIL: 'offer-details',
  OFFER_AGENTS: 'offer-agents',
  IPO_ALLOTMENT: 'ipo-allotment',
  RIGHT_ALLOTMENT: 'right-allotment',
  VIEW_APPLICANTS: 'view-applicants',
  SINGLE_RIGHT_MANUAL_APPLICATION: 'right-manual-application',
  VIEW_SINGLE_SUBSCRIBER: 'view-single-subscriber',
  OFFER_RETURN_MONEY: 'view-offer-return-money',
  CREATE_INTEREST_RATE: 'create-interest-rate',
  VIEW_TRADED_RIGHTS: 'view-traded-rights',
  UPLOAD_TRADED_RIGHTS: 'upload-traded-rights',
  VIEW_BUYERS: 'view-buyers',
  EMAIL_ADVICE: 'email-advice',
};

export default offerUrl;
