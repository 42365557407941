import React from 'react';
import moment from 'moment';
import { Row, Col, Card } from 'antd';
import './styles/MeetingReminder.scss';

export const MeetingReminder = () => {
  return (
    <Row className="pad-0 meeting-reminder">
      <Col span={24} className="pad-0">
        <Card className="wrapper">
          <h3 className="africa-jumbotron">Africa Prudential AGM Meeting</h3>
          <div className="africa-wrapper">
            <h4 className="africa-meeting-header">AP AGM Meeting</h4>
            <p className="meeting-time">
              {moment().format('h:mm a | DD MMMM, YYYY')}
            </p>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default MeetingReminder;
