import React from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import BoxesIcon from 'lib/assets/icons/boxes.svg';
import { CaretLeftOutlined } from '@ant-design/icons';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';

import './ModuleNavHeader.scss';

const ModuleNavHeader = (props) => {
  const renderSeperator = (list, index) => {
    if (list.length - 1 !== index) {
      return <span className="caret">&gt;</span>;
    }
  };

  const getTitleCase = (stringInQuestion) => {
    const result =
      stringInQuestion.charAt(0).toUpperCase() + stringInQuestion.slice(1);

    return result;
  };

  const formatBreadCrumb = (breadcrumb) => {
    const result = breadcrumb
      .split('-')
      .map((element) => getTitleCase(element))
      .join(' ');

    return result;
  };

  let history = useHistory();
  return (
    <div className="nav-header-wrapper">
      <div>
        <header className="module-header">
          <div className="breadcrumb">
            <button onClick={() => history.go(-1)}>
              <CaretLeftOutlined
                style={{ fontSize: '26px', color: '#299F9E' }}
              />
              Back
            </button>
            <span className="divider"></span>

            <span className="module">
              {props.breadcrumbs.map(
                ({ match, breadcrumb, location }, index) => (
                  <React.Fragment key={index}>
                    <NavLink to={`${match.url}${location?.search}`}>
                      {formatBreadCrumb(breadcrumb?.props?.children)}
                    </NavLink>
                    {renderSeperator(props.breadcrumbs, index)}
                  </React.Fragment>
                ),
              )}
            </span>
          </div>

          <h2 className="title">{props.title}</h2>
        </header>
      </div>

      <div className="hamburger" onClick={props.toggleSidebar}>
        &#9776;
      </div>
    </div>
  );
};

export default withBreadcrumbs()(ModuleNavHeader);
