const moduleLinksArray = [
  {
    name: 'Dividend',
    link: '/modules/Equity/notifications?module=dividend',
  },
  {
    name: 'Shareholder',
    link: '/modules/Equity/holder-notification?module=shareholder',
  },
  {
    name: 'Client Company',
    link: '/modules/Equity/client-company-notifications?module=clientcompany',
  },
  {
    name: 'Mutual Fund',
    link: '/modules/mutualfund/notifications?module=mutualfund',
  },
  {
    name: 'Offer Module',
    link: '/modules/Equity/offer-notifications?module=offer',
  },
  {
    name: 'Certificate',
    link: '/modules/Equity/certificate-notifications?module=certificate',
  },
];

const DashboardUtils = {
  appendZero: function (value) {
    value = value.toString().split('');
    value.unshift('0');
    const newValue = value.join('');

    return newValue;
  },

  isSingleDigit: function (value) {
    value = value.toString().split('');
    if (value.length === 1) {
      return true;
    }

    return false;
  },

  reminderPopUp: function (arrayInQuestion) {
    if (arrayInQuestion.length !== 0) {
      const isRead = arrayInQuestion[0]?.is_read;
      if (isRead !== 0) {
        return false;
      }

      return true;
    } else {
      return false;
    }
  },

  formatUserDate: function (dateInQuestion) {
    const result = dateInQuestion.split('T');

    return result[0];
  },

  formatNotificationByModuleItems: function (listInQuestion) {
    if (listInQuestion.length > 0) {
      const newList = listInQuestion.map((listItem) => {
        const result = moduleLinksArray.find(
          (moduleItem) => moduleItem.name === listItem.name,
        );
        return {
          name: result?.name,
          link: result?.link,
          count: listItem?.count,
        };
      });

      return newList;
    }

    return listInQuestion;
  },
};

export default DashboardUtils;
